import { toMoney } from '@/general/src/other/money.js'
import { getTextPreview } from '@/general/src/other/textPreview.js'
import { UnreachableCodeError } from '@/general/src/other/type-helpers.js'
import type { ClientTxForAdmin, ClientTxForMerchantMember, ClientTxForTrader } from '@/general/src/tx/utils.server.js'
import {
  toHumantTxParticipantBalanceField,
  toHumanTxInitiatorType,
  toHumanTxReason,
  toHumanTxSubjectType,
  toHumanTxType,
} from '@/general/src/tx/utils.shared.js'
import { getRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Link, Table } from '@/webapp/src/lib/uninty.components.js'
import { formatDate } from 'date-fns/format'
import { pick } from 'svag-utils'

export const TxsTable = ({
  txs,
  loadMore,
  hasMore,
  viewerType,
}: {
  txs: Array<ClientTxForAdmin | ClientTxForTrader | ClientTxForMerchantMember>
  loadMore?: () => any
  hasMore?: boolean
  viewerType: 'trader' | 'merchantMember' | 'admin'
}) => {
  return (
    <Table
      className="unintyTable"
      records={txs}
      columns={[
        { heading: 'SN', body: (record) => record.serialNumber, width: 100 },
        {
          heading: 'Дата',
          body: (record) => formatDate(record.createdAt, 'dd.MM.yyyy HH:mm'),
          width: 150,
        },
        {
          heading: 'Сумма, ₮',
          body: (record) => toMoney({ amount: record.amountUsdt, currency: 'usdt' }),
          width: 100,
        },
        {
          heading: 'Сделка',
          body: (record) => {
            const deal = pick(record, [
              'customerWithdraw',
              'customerDeposit',
              'merchantWithdraw',
              'merchantDeposit',
              // 'traderWithdraw',
              'traderDeposit',
              'traderInsuranceWithdraw',
              'traderInsuranceDeposit',
            ])
            if (record.subjectType === 'bonus') {
              return getTextPreview(deal)
            } else {
              return <Link href={getRoute({ viewerType, ...deal })}>{getTextPreview(deal)}</Link>
            }
          },
          width: 200,
        },
        {
          heading: 'Тип транзакции',
          body: (record) => toHumanTxType(record.type),
          width: 500,
        },
        {
          heading: 'Предмет',
          body: (record) => toHumanTxSubjectType(record.subjectType),
          width: 150,
        },
        {
          heading: 'Причина',
          body: (record) => toHumanTxReason(record.reasonType),
          width: 200,
        },
        {
          heading: 'Откуда',
          body: (record) =>
            toHumantTxParticipantBalanceField(record.fromParticipantType, record.fromParticipantBalanceField),
          width: 200,
        },
        {
          heading: 'Куда',
          body: (record) =>
            toHumantTxParticipantBalanceField(record.toParticipantType, record.toParticipantBalanceField),
          width: 200,
        },
        {
          heading: 'Инициатор',
          body: (record) => toHumanTxInitiatorType(record.initiatorType),
          width: 200,
        },
      ]}
      loadMore={loadMore}
      hasMore={hasMore}
    />
  )
}

const useGetTxsInfiniteQuery = ({
  viewerType,
  dealId,
}: {
  viewerType: 'trader' | 'merchantMember' | 'admin'
  dealId?: string
}) => {
  const queryArgs = [
    { dealId },
    {
      getNextPageParam: (lastPage: { nextCursor: number | undefined }) => {
        return lastPage.nextCursor
      },
      refetchOnMount: true,
    },
  ] as const

  // return trpc.getTxsForTrader.useInfiniteQuery({ dealId }, {
  //     getNextPageParam: (lastPage) => {
  //       return lastPage.nextCursor
  //     },
  // })

  if (viewerType === 'trader') {
    return trpc.getTxsForTrader.useInfiniteQuery(...queryArgs)
  }

  if (viewerType === 'merchantMember') {
    return trpc.getTxsForMerchantMember.useInfiniteQuery(...queryArgs)
  }
  if (viewerType === 'admin') {
    return trpc.getTxsForAdmin.useInfiniteQuery(...queryArgs)
  }

  throw new UnreachableCodeError(viewerType)
}

export const Txs = (opts: { viewerType: 'trader' | 'merchantMember' | 'admin'; dealId?: string }) => {
  const queryResult = useGetTxsInfiniteQuery(opts)
  const txs = queryResult.data?.pages.flatMap((page) => page.txs) || []
  return (
    <>
      {queryResult.isLoading ? (
        <p>Загрузка...</p>
      ) : !txs.length ? (
        <p>Здесь пусто</p>
      ) : (
        <TxsTable
          viewerType={opts.viewerType}
          txs={txs}
          loadMore={() => {
            if (!queryResult.isFetchingNextPage && queryResult.hasNextPage) {
              void queryResult.fetchNextPage()
            }
          }}
          hasMore={queryResult.hasNextPage}
        />
      )}
    </>
  )
}
