import type { FindTraderCondition } from './utils.server.js'

export const toHumanFindTraderConditionInverted = (condition: FindTraderCondition) => {
  return {
    traderOnline: 'Трейдер оффлайн',
    traderNotBanned: 'Трейдер забанен',
    traderHasFreeBalance: 'У трейдера нет достаточно свободного баланса',
    traderAutomatorOnline: 'Автоматор оффлайн последние 15 секунд',
    paymentMethodGroupAllowed: 'Платёжный метод не используется для депозитов',
    paymentMethodNotDeleted: 'Платёжный метод удалён',
    paymentMethodAvailableForInput: 'Платёжный метод отключён',
    paymentMethodGroupMatch: 'Группа платёжного метода не совпадает с группой платёжного метода сделки',
    paymentMethodTypeMatch: 'Платёжный метод не совпадает с платёжным методом сделки',
    paymentMethodLimitMinTransactionAmount: 'Сумма транзакции меньше установленного лимита',
    paymentMethodLimitMaxTransactionsAmountRubPerDayNotReached: 'Достигнут лимит на сумму транзакций',
    paymentMethodLimitMaxTransactionsPerDayNotReached: 'Достигнут лимит на количество транзакций',
    traderDoesNotHaveDealWithTheSameAmount: 'У трейдера уже есть активная сделка на ту же сумму',
  }[condition]
}
