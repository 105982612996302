import { pageData } from './pageData.js'
import { zCreateTraderInsuranceWithdrawForTraderEndpointInput } from '@/general/src/traderInsuranceWithdraw/routes/createTraderInsuranceWithdrawForTrader/input.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { traderTraderInsuranceWithdrawViewRoute } from '@/webapp/src/lib/routes.js'
import { traderTraderInsuranceWithdrawListRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useNavigate } from 'react-router-dom'

export const TraderTraderInsuranceWithdrawNewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedTradersOnly: true,
})(() => {
  const navigate = useNavigate()
  const createTraderInsuranceWithdrawForTrader = trpc.createTraderInsuranceWithdrawForTrader.useMutation()
  const formy = useFormy({
    initialValues: {
      amountUsdt: '',
    },
    validationSchema: zCreateTraderInsuranceWithdrawForTraderEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { traderInsuranceWithdraw } = await createTraderInsuranceWithdrawForTrader.mutateAsync(valuesInput)
      navigate(
        traderTraderInsuranceWithdrawViewRoute.get({
          traderInsuranceWithdrawSerialNumber: traderInsuranceWithdraw.serialNumber,
        })
      )
    },
    successMessage: 'Трейдерский страховой виздрав создан',
    resetOnSuccess: false,
  })

  const breadcrumbs = [
    { title: 'Трейдерские страховые виздравы', href: traderTraderInsuranceWithdrawListRoute.get() },
    { title: 'Новый виздрав' },
  ]

  return (
    <Block>
      <Breadcrumbs items={breadcrumbs} rootRole="trader" />
      <Segment title="Создание трейдерского страхового виздрава" size="m">
        <FormItems as="form" {...formy.formProps}>
          <Textfieldy label="Сумма в USDT" {...formy.getFieldProps('amountUsdt')} />
          <Buttons>
            <Button {...formy.buttonProps} type="submit">
              Создать трейдерский страховой втздрав
            </Button>
          </Buttons>
        </FormItems>
      </Segment>
    </Block>
  )
})
