import { zAmountIntegerWithDecimalsLimited } from '@/general/src/other/money.js'
import { zStringRequired } from '@/general/src/other/validation.js'
import { z } from 'zod'

export const zCreateMerchantForAdminEndpointInput = z.object({
  name: zStringRequired,
  customerWithdrawFeeShare: zAmountIntegerWithDecimalsLimited(100, 10_000),
  customerDepositFeeShare: zAmountIntegerWithDecimalsLimited(100, 10_000),
  exchangeSource: z.enum(['garantex', 'bybit']),
})
