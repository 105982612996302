import { pageData } from './pageData.js'
import { zUpdateMyPasswordForMerchantMemberEndpointInput } from '@/general/src/merchant/routes/updateMyPasswordForMerchantMember/input.js'
import { zPasswordsMustBeTheSame, zStringRequired } from '@/general/src/other/validation.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, Segment, Segments } from '@/webapp/src/lib/uninty.components.js'

const Password = () => {
  const updatePassword = trpc.updateMyPasswordForMerchantMember.useMutation()
  const formy = useFormy({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordAgain: '',
    },
    validationSchema: zUpdateMyPasswordForMerchantMemberEndpointInput
      .extend({
        newPasswordAgain: zStringRequired,
      })
      .superRefine(zPasswordsMustBeTheSame('newPassword', 'newPasswordAgain')),
    onSubmit: async ({ valuesInput: { newPassword, oldPassword } }) => {
      await updatePassword.mutateAsync({ newPassword, oldPassword })
    },
    successMessage: 'Пароль изменён',
    resetOnSuccess: true,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Textfieldy label="Старый пароль" type="password" {...formy.getFieldProps('oldPassword')} />
      <Textfieldy label="Новый пароль" type="password" {...formy.getFieldProps('newPassword')} />
      <Textfieldy label="Новый пароль ещё раз" type="password" {...formy.getFieldProps('newPasswordAgain')} />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Сменить пароль
        </Button>
      </Buttons>
    </FormItems>
  )
}

// const Email = () => {
//   const formy = useFormy({
//     successMessage: 'Почта успешно изменена',
//     onSubmit: async () => {},
//   })
//   return (
//     <FormItems as="form" {...formy.formProps}>
//       <Textfieldy label="Новая почта" {...formy.getFieldProps('email')} />
//       <Buttons><Button {...formy.buttonProps} type="submit">Изменить почту</Button></Buttons>
//     </FormItems>
//   )
// }

// const Phone = () => {
//   const formy = useFormy({
//     successMessage: 'Телефон успешно изменён',
//     onSubmit: async () => {},
//   })
//   return (
//     <FormItems as="form" {...formy.formProps}>
//       <Textfieldy label="Новый телефон" {...formy.getFieldProps('email')} />
//       <Buttons><Button {...formy.buttonProps} type="submit">Изменить телефон</Button></Buttons>
//     </FormItems>
//   )
// }

export const MerchantMerchatMemberSettingsPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedMerchantMembersOnly: true,
})(() => {
  const breadcrumbs = [{ title: 'Настройки' }]

  return (
    <Block fcnw>
      <Breadcrumbs items={breadcrumbs} rootRole="merchant" />
      <Segment title="Мои мерчантские настройки" size="m">
        <Segments>
          <Segment title="Управление паролем" size="s">
            <Password />
          </Segment>
          {/* <Segment title="Управление почтой" size="s">
            <Email />
          </Segment>
          <Segment title="Управление телефоном" size="s">
            <Phone />
          </Segment> */}
        </Segments>
      </Segment>
    </Block>
  )
})
