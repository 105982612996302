/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import css from './PaymentMethod.module.scss'
import { zUpdateCompanyPaymentMethodsForAdminEndpointInput } from '@/general/src/paymentMethod/routes/updateCompanyPaymentMethodsForAdmin/input.js'
import { zUpdateMerchantPaymentMethodsForMerchantMemberEndpointInput } from '@/general/src/paymentMethod/routes/updateMerchantPaymentMethodsForMerchantMember/input.js'
import { zUpdateMyPaymentMethodsForTraderEndpointInput } from '@/general/src/paymentMethod/routes/updateMyPaymentMethodsForTrader/input.js'
import type {
  ClientPaymentMethodForOwner,
  ClientPaymentMethodGeneral,
} from '@/general/src/paymentMethod/utils.server.js'
import type { PaymentMethodRecordUpsertDataInput } from '@/general/src/paymentMethod/utils.shared.js'
import {
  paymentMethodsGroupsExternal,
  paymentMethodsTypesByGroups,
  toHumanPaymentMethodGroup,
  toHumanPaymentMethodType,
} from '@/general/src/paymentMethod/utils.shared.js'
import { Selecty, Switchy, Textfieldy, useFormy, useFormyField } from '@/webapp/src/lib/formy.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, FormItem, FormItems, LabeledValues } from '@/webapp/src/lib/uninty.components.js'
import { Button, Card, Flex, Group, SimpleGrid, TextInput, Title } from '@mantine/core'
import cn from 'classnames'
import { useEffect, useRef } from 'react'
import type { Formy } from 'svag-formy'
import type { FormyInputPropsGeneral } from 'svag-formy/dist/utils.js'
import { prettifyBankCard, prettifyPhone } from 'svag-utils'

export const PaymentMethodCardsPicker = ({
  label,
  formy,
  name,
  hint,
  paymentMethods,
}: FormyInputPropsGeneral &
  React.ComponentProps<typeof FormItem> & {
    paymentMethods: ClientPaymentMethodForOwner[]
  }) => {
  const { error, value } = useFormyField({ formy, name })
  return (
    <FormItem label={label} hint={hint} error={error}>
      <Block fcnw g={10}>
        {paymentMethods.map((paymentMethod, i) => (
          <PaymentMethodCard
            key={i}
            checked={value === paymentMethod.id}
            onClick={() => {
              void formy.setFieldValue(name, paymentMethod.id)
            }}
            paymentMethod={paymentMethod}
          />
        ))}
      </Block>
    </FormItem>
  )
}

export const PaymentMethodCard = ({
  paymentMethod,
  checked,
  onClick,
  garantexCode,
}: {
  paymentMethod: ClientPaymentMethodGeneral
  checked?: boolean
  onClick?: () => any
  garantexCode?: string | null
}) => {
  return (
    <div
      className={cn({ [css.paymentMethodCard]: true, [css.checked]: checked, [css.clickable]: !!onClick })}
      onClick={onClick}
    >
      <LabeledValues
        valuesEmptyPolicy="hide"
        items={[
          ['Метод', toHumanPaymentMethodType(paymentMethod.type)],
          [
            'Номер карты',
            paymentMethod.group === 'card' && paymentMethod.cardNumber
              ? prettifyBankCard(paymentMethod.cardNumber)
              : null,
          ],
          ['Имя владельца', paymentMethod.group === 'card' ? paymentMethod.ownerName : null],
          [
            'Номер телефона',
            paymentMethod.group === 'sbp' && paymentMethod.ownerPhone ? prettifyPhone(paymentMethod.ownerPhone) : null,
          ],
          ['Имя владельца', paymentMethod.group === 'sbp' ? paymentMethod.ownerName : null],
          ['Номер кошелька', paymentMethod.group === 'crypto' ? paymentMethod.wallet : null],
          ['Код гаратекс', garantexCode],
        ]}
        $style={{
          labelColor: 'var(--mantine-color-text)',
          hintColor: 'var(--mantine-color-text)',
        }}
      />
    </div>
  )
}

const Limits = ({ formy, index }: { formy: Formy; index: number }) => {
  const { value: maxTransactionsPerDay } = useFormyField({
    formy,
    name: `paymentMethods.${index}.maxTransactionsPerDay`,
  })

  const { value: maxTransactionsAmountRubPerDay } = useFormyField({
    formy,
    name: `paymentMethods.${index}.maxTransactionsAmountRubPerDay`,
  })

  const { value: minTransactionAmount } = useFormyField({
    formy,
    name: `paymentMethods.${index}.minTransactionAmount`,
  })

  return (
    <>
      <TextInput
        label="Лимит количества сделок в день"
        value={String(maxTransactionsPerDay ?? '')}
        onChange={(event) => {
          void formy.setFieldValue(
            `paymentMethods.${index}.maxTransactionsPerDay`,
            Number(event.currentTarget.value) || null
          )
        }}
      />
      <TextInput
        label="Лимит на сумму сделок в день, ₽"
        value={String(Math.floor(Number(maxTransactionsAmountRubPerDay) / 100) || '')}
        onChange={(event) => {
          void formy.setFieldValue(
            `paymentMethods.${index}.maxTransactionsAmountRubPerDay`,
            (Number(event.currentTarget.value) && BigInt(Number(event.currentTarget.value) * 100)) || null
          )
        }}
      />
      <TextInput
        label="Лимит на минимальную сумму сделки, ₽"
        value={String(Math.floor(Number(minTransactionAmount) / 100) || '')}
        onChange={(event) => {
          void formy.setFieldValue(
            `paymentMethods.${index}.minTransactionAmount`,
            (Number(event.currentTarget.value) && BigInt(Number(event.currentTarget.value) * 100)) || null
          )
        }}
      />
    </>
  )
}

const PaymentMethodEditorItem = ({
  formy,
  index,
  paymentMethod,
  routeName,
}: {
  formy: Formy
  index: number
  paymentMethod: PaymentMethodRecordUpsertDataInput
  routeName: string
}) => {
  ;(paymentMethod as any).cardNumber = (paymentMethod as any).cardNumber || ''
  ;(paymentMethod as any).ownerName = (paymentMethod as any).ownerName || ''
  ;(paymentMethod as any).ownerPhone = (paymentMethod as any).ownerPhone || ''
  ;(paymentMethod as any).wallet = (paymentMethod as any).wallet || ''
  const paymentMethodGroupRef = useRef(paymentMethod.group)

  useEffect(() => {
    if (paymentMethod.group !== paymentMethodGroupRef.current) {
      void formy.setFieldValue(`paymentMethods.${index}.type`, paymentMethodsTypesByGroups[paymentMethod.group][0])
      paymentMethodGroupRef.current = paymentMethod.group
    }
  }, [paymentMethod.group])

  const isTraderPage = routeName === 'updateMyPaymentMethodsForTrader'
  const isGroupWithLimits = ['card', 'sbp'].includes(paymentMethod.group)

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder className={css.card}>
      <FormItems>
        {paymentMethod.group !== 'garantex' && (
          <Selecty
            formy={formy}
            name={`paymentMethods.${index}.group`}
            label="Тип"
            disabled={paymentMethod.saved}
            options={paymentMethodsGroupsExternal.map((group) => ({
              value: group,
              label: toHumanPaymentMethodGroup(group),
            }))}
          />
        )}
        <Selecty
          formy={formy}
          name={`paymentMethods.${index}.type`}
          label="Метод"
          disabled={paymentMethod.saved}
          options={paymentMethodsTypesByGroups[paymentMethod.group].map((method) => ({
            value: method,
            label: toHumanPaymentMethodType(method),
          }))}
        />
        {paymentMethod.group === 'card' && paymentMethod.type && (
          <>
            <Textfieldy
              disabled={paymentMethod.saved}
              key="card-cardNumber"
              label="Номер карты"
              formy={formy}
              name={`paymentMethods.${index}.cardNumber`}
            />
            <Textfieldy
              disabled={paymentMethod.saved}
              key="card-ownerName"
              label="Имя владельца"
              formy={formy}
              name={`paymentMethods.${index}.ownerName`}
            />
          </>
        )}
        {paymentMethod.group === 'sbp' && paymentMethod.type && (
          <>
            <Textfieldy
              disabled={paymentMethod.saved}
              key="sbp-phone"
              label="Номер телефона"
              formy={formy}
              name={`paymentMethods.${index}.ownerPhone`}
            />
            <Textfieldy
              disabled={paymentMethod.saved}
              key="sbp-ownerName"
              label="Имя владельца"
              formy={formy}
              name={`paymentMethods.${index}.ownerName`}
            />
          </>
        )}
        {paymentMethod.group === 'crypto' && paymentMethod.type && (
          <Textfieldy
            disabled={paymentMethod.saved}
            key="crypto-wallet"
            label="Номер кошелька"
            formy={formy}
            name={`paymentMethods.${index}.wallet`}
          />
        )}
        {isTraderPage && isGroupWithLimits && <Limits formy={formy} index={index} />}
        <Switchy
          formy={formy}
          name={`paymentMethods.${index}.availableForInput`}
          label="Доступно для получения денег"
          optionLabel="Да"
        />
        <Switchy
          formy={formy}
          name={`paymentMethods.${index}.availableForOutput`}
          label="Доступно для вывода денег"
          optionLabel="Да"
        />

        <Group grow>
          <Button type="submit">Сохранить</Button>
          <Button
            type="submit"
            variant="filled"
            color="red"
            onClick={() => {
              formy.removeArrayItem('paymentMethods', index)
              setTimeout(() => {
                formy.handleSubmit()
              })
            }}
          >
            Удалить
          </Button>
        </Group>
      </FormItems>
    </Card>
  )
}

export const PaymentMethodsEditor = ({
  updateMyPaymentMethodsTrpcRouteName,
  paymentMethods,
}: {
  updateMyPaymentMethodsTrpcRouteName:
    | 'updateMyPaymentMethodsForTrader'
    | 'updateCompanyPaymentMethodsForAdmin'
    | 'updateMerchantPaymentMethodsForMerchantMember'
  paymentMethods: ClientPaymentMethodForOwner[]
}) => {
  const updateMyPaymentMethods = trpc[updateMyPaymentMethodsTrpcRouteName].useMutation()
  const zSchema =
    updateMyPaymentMethodsTrpcRouteName === 'updateMyPaymentMethodsForTrader'
      ? zUpdateMyPaymentMethodsForTraderEndpointInput
      : updateMyPaymentMethodsTrpcRouteName === 'updateCompanyPaymentMethodsForAdmin'
        ? zUpdateCompanyPaymentMethodsForAdminEndpointInput
        : zUpdateMerchantPaymentMethodsForMerchantMemberEndpointInput

  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      paymentMethods: paymentMethods.map((pm) => ({
        ...pm,
        saved: true,
      })) as never,
    },
    validationSchema: zSchema,
    onSubmit: async ({ valuesOutput }) => {
      await updateMyPaymentMethods.mutateAsync({
        paymentMethods: valuesOutput.paymentMethods,
      })

      if (updateMyPaymentMethodsTrpcRouteName === 'updateCompanyPaymentMethodsForAdmin') {
        await trpcUtils.getCompanyForAdmin.refetch()
      } else {
        await trpcUtils.getMyMerchant.refetch()
      }

      await trpcUtils.getMe.refetch()
    },
    successMessage: 'Платёжные методы изменены',
    resetOnSuccess: false,
    showValidationErrorMessage: true,
  })

  const gridSettings = { base: 1, '580px': 2, '900px': 3, '1200px': 4 }

  return (
    <>
      <Title order={2} size="h2">
        Платёжные методы
      </Title>
      <Flex className={css.pageContainer}>
        <FormItems as="form" {...formy.formProps} className={css.formWrapper}>
          <SimpleGrid type="container" cols={gridSettings} spacing={{ base: 20 }} className={css.gridCards}>
            {formy.values.paymentMethods.map((paymentMethod, index) => (
              <PaymentMethodEditorItem
                key={paymentMethod.id}
                formy={formy}
                index={index}
                paymentMethod={paymentMethod}
                routeName={updateMyPaymentMethodsTrpcRouteName}
              />
            ))}
          </SimpleGrid>
          <SimpleGrid type="container" cols={gridSettings} spacing={{ base: 20 }}>
            <Button
              className={css.addNewMethodButton}
              type="button"
              onClick={() => {
                formy.addArrayItem('paymentMethods', {
                  id: crypto.randomUUID(),
                  group: 'crypto',
                  type: 'usdtTrc20',
                  wallet: '',
                  cardNumber: '',
                  ownerName: '',
                  availableForInput: true,
                  availableForOutput: true,
                  maxTransactionsPerDay: undefined,
                  maxTransactionsAmountRubPerDay: undefined,
                  saved: false,
                } as PaymentMethodRecordUpsertDataInput)
              }}
            >
              Добавить новый метод
            </Button>
          </SimpleGrid>
        </FormItems>
      </Flex>
    </>
  )
}
