import { InterestSharesFieldset } from './InterestSharesFieldset.js'
import { pageData } from './pageData.js'
import { integerWithDecimalsToAmountString } from '@/general/src/other/money.js'
import { zUpdateTraderForAdminEndpointInput } from '@/general/src/trader/routes/updateTraderForAdmin/input.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { Switchy, Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminTraderEditRoute } from '@/webapp/src/lib/routes.js'
import * as routes from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'

export const AdminTraderEditPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(adminTraderEditRoute)
    return trpc.getTraderForAdmin.useQuery({
      traderSerialNumber: +routeParams.traderSerialNumber,
    })
  },
  setProps: ({ queryResult }) => ({
    trader: queryResult.data.trader,
  }),
})(({ trader }) => {
  const updateTraderForAdmin = trpc.updateTraderForAdmin.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      traderId: trader.id,
      customerDepositInterestShare: integerWithDecimalsToAmountString({ amount: trader.customerDepositInterestShare }),
      customerWithdrawInterestShare: integerWithDecimalsToAmountString({
        amount: trader.customerWithdrawInterestShare,
      }),
      customerDepositCompanyInterestShare: String(Number(trader.customerDepositCompanyInterestShare) / 100),
      customerWithdrawCompanyInterestShare: String(Number(trader.customerWithdrawCompanyInterestShare) / 100),
      name: trader.name,
      phone: trader.phone,
      email: trader.email,
      telegram: trader.telegram,
      referrerSerialNumber: (trader.referrer?.serialNumber.toString() || '') as never as number,
      online: trader.online,
      banned: !!trader.bannedAt,
      externalChatLink: trader.externalChatLink ?? '',
    },
    validationSchema: zUpdateTraderForAdminEndpointInput(trader),
    onSubmit: async ({ valuesInput }) => {
      const res = await updateTraderForAdmin.mutateAsync({
        ...valuesInput,
        customerDepositCompanyInterestShare: valuesInput.referrerSerialNumber
          ? valuesInput.customerDepositCompanyInterestShare
          : '0',
        customerWithdrawCompanyInterestShare: valuesInput.referrerSerialNumber
          ? valuesInput.customerWithdrawCompanyInterestShare
          : '0',
      })
      trpcUtils.getTraderForAdmin.setData({ traderSerialNumber: res.trader.serialNumber }, res)
    },
    successMessage: 'Трейдер обновлён',
  })
  const referrerSerialNumber = Number(formy.values.referrerSerialNumber)
  const referrerQueryResult = trpc.getTraderForAdmin.useQuery(
    { traderSerialNumber: referrerSerialNumber },
    { enabled: Boolean(referrerSerialNumber) }
  )

  const breadcrumbs = [
    { title: 'Трейдеры', href: routes.adminTraderListRoute.get() },
    { title: `Трейдер #${trader.serialNumber}` },
  ]

  return (
    <Block fcnw>
      <Breadcrumbs items={breadcrumbs} />
      <Segment title={`Трейдер #${trader.serialNumber}`} size="m">
        <FormItems as="form" {...formy.formProps}>
          <Textfieldy label="Имя" {...formy.getFieldProps('name')} />
          <Textfieldy label="Почта" {...formy.getFieldProps('email')} />
          <Textfieldy label="Телефон" {...formy.getFieldProps('phone')} />
          <Textfieldy label="Телеграм" {...formy.getFieldProps('telegram')} />
          <InterestSharesFieldset
            formy={formy}
            trader={trader}
            referrer={referrerQueryResult.data?.trader}
            referrerError={referrerQueryResult.error}
          />
          <Textfieldy label="Ссылка на чат с трейдером" {...formy.getFieldProps('externalChatLink')} />
          <Switchy {...formy.getFieldProps('online')} label="Онлайн" optionLabel="Да" />
          <Switchy {...formy.getFieldProps('banned')} label="Забанен" optionLabel="Да" />
          <Buttons>
            <Button {...formy.buttonProps} type="submit">
              Сохранить
            </Button>
          </Buttons>
        </FormItems>
      </Segment>
    </Block>
  )
})
