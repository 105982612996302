import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { ApiDocsSection } from '@/general/src/api/docs/ApiDocsSection.js'
import { getRandomUuid } from 'svag-utils'

export const ApiDocsH2HPostCustomerDeposit = ({
  merchant,
}: {
  merchant?: TrpcRouterOutput['getMyMerchant']['merchant'] | null
}) => {
  return (
    <ApiDocsSection
      merchant={merchant}
      title="Создание сделки (депозита)"
      desc="В отличии от Merchant API не содержит параметров success_url и fail_url. И возвращает больше данных для создания собственной страницы оплаты."
      method="POST"
      path="/api/h2h/customer-deposit/v1"
      hashParams={['body:externalId', 'body:amount', 'body:currency', 'body:paymentMethodType']}
      bodyParams={[
        {
          key: 'externalId',
          title: 'externalId*',
          desc: 'Обязательное поле, если не задан idempotencyKey – id сделки на стороне внешнего сервиса. Должен быть уникальным для мерчанта.',
          defaultValue: getRandomUuid(),
        },
        {
          key: 'idempotencyKey',
          title: 'idempotencyKey*',
          desc: 'Обязательное поле, если не задан extarnalId - ключ для дедубликации одинаковых запросов (если пользователь дважды нажмет Отправить). Как правило, ID платежа в вашей системе.',
        },
        {
          key: 'amount',
          title: 'amount',
          desc: 'Сумма в указанной валюте с указанием копеек (центов) без знака запятой. То есть 1000 рублей — 100000. 1000 рублей 50 копеек — 100050',
          defaultValue: '100000',
        },
        {
          key: 'paymentMethodType',
          title: 'paymentMethodType',
          desc: 'Способ оплаты, например "sberbankCard". Если не указать, будет выбран случайный доступный метод, принадлежащий группе paymentMethodGroup. См. метод /api/get-payment-methods/v1',
          defaultValue: 'sberbankCard',
          dropEmptyString: true,
        },
        {
          key: 'paymentMethodGroup',
          title: 'paymentMethodGroup',
          desc: 'Группа способов оплаты, например "card". Можно не указывать, если указан paymentMethodType. См. метод /api/get-payment-methods/v1',
          defaultValue: 'card',
        },
        {
          key: 'currency',
          title: 'currency',
          desc: 'Валюта, в которой мерчант хочет зачислить деньги в своей системе своему клиенту. Сейчас поддерживается только "rub"',
          defaultValue: 'rub',
        },
      ]}
      responseExample={{
        customerDeposit: {
          id: '01914b92-40fe-7990-956c-2dab326c7f6d',
          externalId: '01914b92-40fe-7990-956c-2dab326c7sdf',
          status: 'processing',
          cancellationCode: null,
          cancellationMessage: null,
          disputeStartMessage: null,
          dealAmount: 100_000,
          dealCurrency: 'rub',
          amountUsdt: 1_079,
          dealCurrencyInOneUsdt: 9_262,
          merchantCurrency: 'rub',
          merchantCurrencyInOneUsdt: 9_262,
          merchantAmount: 100_000,
          merchantFeeShare: 400,
          merchantFeeUsdt: 43,
          trader: {
            id: 'ba77faf6-56f2-4592-9a8d-118684c99ee6',
            serialNumber: 1,
          },
          traderPaymentMethodData: {
            type: 'sberbankCard',
            group: 'card',
            provider: 'sberbank',
            ownerName: 'qweqwe qweqwe',
            cardNumber: '1234123412341238',
          },
          createdAt: '2024-08-13T11:49:21.788Z',
          pickedAt: '2024-08-13T11:49:21.788Z',
          cancelledAt: null,
          declaredAt: null,
          completedAt: null,
          completedBy: null,
          disputeStartedAt: null,
          disputeCompletedAt: null,
          disputeCancelledAt: null,
          willBeCancelledBecauseNotPickedAt: '2024-08-13T12:09:21.790Z',
          willBeCancelledBecauseNotDeclaredAt: null,
          finishedAt: null,
          demo: false,
        },
      }}
    />
  )
}
