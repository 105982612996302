import type {
  PaymentMethodGroup as PrismaPaymentMethodGroup,
  PaymentMethodProvider as PrismaPaymentMethodProvider,
  PaymentMethodType as PrismaPaymentMethodType,
} from '@/backend/src/services/other/prisma.js'
import { zBankCardNumberRequired, zPhoneRequired, zStringRequired } from '@/general/src/other/validation.js'
import type {
  ClientPaymentMethodForOwner,
  ClientPaymentMethodGeneral,
} from '@/general/src/paymentMethod/utils.server.js'
import type { IsEqual } from 'type-fest'
import type { Simplify } from 'type-fest'
import { z } from 'zod'

// General

export const zPaymentMethodRecordAvailability = z.object({
  availableForInput: z.boolean(),
  availableForOutput: z.boolean(),
})
export const zPaymentMethodLimits = z.object({
  minTransactionAmount: z.bigint().optional().nullable(),
  maxTransactionsPerDay: z.number().int().min(0).optional().nullable(),
  maxTransactionsAmountRubPerDay: z.bigint().optional().nullable(),
})
export const zPaymentMethodRecordRest = z.object({
  id: z.string(),
  serialNumber: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
})
export const zPaymentMethodRecordPreviewRest = zPaymentMethodRecordRest
export const zPaymentMethodRecordFullRest = zPaymentMethodRecordRest.merge(zPaymentMethodRecordAvailability)

export const paymentMethodsFamilies = ['internal', 'external', 'externalCrypto', 'externalFiat'] as const
export const zPaymentMethodsFamily = z.enum(paymentMethodsFamilies)
export type PaymentMethodFamily = (typeof paymentMethodsFamilies)[number]

export const paymentMethodsGroups = ['crypto', 'card', 'sbp', 'garantex'] as const
export const zPaymentMethodGroup = z.enum(paymentMethodsGroups)
export type PaymentMethodGroup = (typeof paymentMethodsGroups)[number]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const check1: IsEqual<PaymentMethodGroup, PrismaPaymentMethodGroup> = true

export const paymentMethodsTypesCrypto = ['usdtTrc20'] as const
export const zPaymentMethodTypeCrypto = z.enum(paymentMethodsTypesCrypto)
export type PaymentMethodTypeCrypto = (typeof paymentMethodsTypesCrypto)[number]
export const paymentMethodsProvidersCrypto = ['usdtTrc20'] as const
export const zPaymentMethodProviderCrypto = z.enum(paymentMethodsProvidersCrypto)
export type PaymentMethodProviderCrypto = (typeof paymentMethodsProvidersCrypto)[number]

export const paymentMethodsTypesCard = [
  'sberbankCard',
  'tinkoffCard',
  'alfabankCard',
  'raiffaizenbankCard',
  'uralsibbankCard',
  'ozonbankCard',
  'tsifrabankCard',
  'akbarsCard',
  'gazprombankCard',
  'otpbankCard',
  'vtbCard',
  'bcsbankCard',
  'rosselkhozbankCard',
  'promsvyazbankCard',
  'psbCard',
  'yandeksbankCard',
] as const
export const zPaymentMethodTypeCard = z.enum(paymentMethodsTypesCard)
export type PaymentMethodTypeCard = (typeof paymentMethodsTypesCard)[number]
export const paymentMethodsProvidersCard = [
  'sberbank',
  'tinkoff',
  'alfabank',
  'raiffaizenbank',
  'uralsibbank',
  'ozonbank',
  'tsifrabank',
  'akbars',
  'gazprombank',
  'otpbank',
  'vtb',
  'bcsbank',
  'rosselkhozbank',
  'promsvyazbank',
  'psb',
  'yandeksbank',
] as const
export const zPaymentMethodProviderCard = z.enum(paymentMethodsProvidersCard)
export type PaymentMethodProviderCard = (typeof paymentMethodsProvidersCard)[number]

export const paymentMethodsTypesSbp = [
  'yunikreditbankSbp',
  'yuginvestbankSbp',
  'esbiaibankSbp',
  'ekspobankSbp',
  'chelyabinvestbankSbp',
  'chelindbankSbp',
  'tsmrbankSbp',
  'tsentrokreditSbp',
  'khakasskiimunitsipalniibankSbp',
  'khaisbankSbp',
  'finstarbankSbp',
  'uralfinansSbp',
  'uralprombankSbp',
  'ukbnovobankSbp',
  'ukbbelgorodsotsbankSbp',
  'uglemetbankSbp',
  'tomskpromstroibankSbp',
  'tolyattikhimbankSbp',
  'toiotabankSbp',
  'timerbankSbp',
  'tatsotsbankSbp',
  'tavricheskiibankSbp',
  'sotsiumbankSbp',
  'snezhinskiiSbp',
  'skbprimoryaprimsotsbankSbp',
  'sibsotsbankSbp',
  'severniinarodniibankSbp',
  'severgazbankSbp',
  'sdmbankSbp',
  'rusuniversalbankSbp',
  'rosdorbankSbp',
  'rnkoplatezhniikonstruktorSbp',
  'realistbankSbp',
  'promsvyazbankSbp',
  'probankSbp',
  'priovneshtorgbankSbp',
  'pnkoelplatSbp',
  'plaitSbp',
  'perviiinvestitsionniibankSbp',
  'perviidortransbankSbp',
  'ozonbankSbp',
  'nsbankSbp',
  'norvikbankSbp',
  'nokssbankSbp',
  'nkoyumaniSbp',
  'nkbankSbp',
  'nikobankSbp',
  'nbdbankSbp',
  'natsinvestprombankSbp',
  'msbankrusSbp',
  'mpbankSbp',
  'kuznetskbiznesbankSbp',
  'kubantorgbankSbp',
  'kredituralbankSbp',
  'kredityevropabankSbp',
  'koshelevbankSbp',
  'kberbankkazaniSbp',
  'kbenergotransbankSbp',
  'kbtsentrinvestSbp',
  'kbkhlinovSbp',
  'kburalfdSbp',
  'kbstroilesbankSbp',
  'kbsolidarnostSbp',
  'kbsitibankSbp',
  'kbsinkobankSbp',
  'kbrusnarbankSbp',
  'kbrostfinansSbp',
  'kbpoidyomSbp',
  'kbnoviivekSbp',
  'kbmoskommertsbankSbp',
  'kbmodulbankSbp',
  'kblokobankSbp',
  'kbkubankreditSbp',
  'kbkrokusbankSbp',
  'kbdolinskSbp',
  'kbgarantinvestSbp',
  'kbvneshfinbankSbp',
  'kbaresbankSbp',
  'kbagropromkreditSbp',
  'ishbankSbp',
  'ingosstrakhbankSbp',
  'inbankSbp',
  'ikbryarinterbankSbp',
  'ikbankSbp',
  'zemskiibankSbp',
  'draivklikbankSbp',
  'dbofakturaSbp',
  'databankSbp',
  'dalnevostochniibankSbp',
  'gorbankSbp',
  'genbankSbp',
  'gazanergobankSbp',
  'gaztransbankSbp',
  'vuzbankSbp',
  'vladbiznesbankSbp',
  'bistrobankSbp',
  'blankbankSbp',
  'bksbankSbp',
  'bbrbankSbp',
  'bankelitaSbp',
  'bankfinservisSbp',
  'bankfinamSbp',
  'banktochkaSbp',
  'banksngbSbp',
  'banksinaraSbp',
  'banksaratovSbp',
  'banksanktpeterburgSbp',
  'bankresodkoediteshnogoSbp',
  'bankraundSbp',
  'bankrazvitiestolitsaSbp',
  'bankpskbSbp',
  'bankotkritieSbp',
  'bankorenburgSbp',
  'bankoranzheviiSbp',
  'bankobedinenniikapitalSbp',
  'banknovikomnovikombankSbp',
  'banknatsionalniistandartSbp',
  'bankmoskvasitiSbp',
  'bankmbamoskvaSbp',
  'banklevoberezhniiSbp',
  'bankkremlevskiiSbp',
  'bankiturupSbp',
  'bankipbSbp',
  'bankintezaSbp',
  'bankzenitSbp',
  'bankzarecheSbp',
  'bankyekaterinburgSbp',
  'bankdomrfSbp',
  'bankvenetsSbp',
  'bankvbrrSbp',
  'bankbkfSbp',
  'bankbzhfSbp',
  'bankaleksandrovskiiSbp',
  'bankaktseptSbp',
  'bankaversSbp',
  'akibankSbp',
  'akbenergobankSbp',
  'akbforshtadtSbp',
  'akbtransstroibankSbp',
  'akbtenderbankSbp',
  'akbsolidSbp',
  'akbslaviyaSbp',
  'akbprimoreSbp',
  'akbnrbankSbp',
  'akbmezhdunarodniifinansoviiklubSbp',
  'yunistrimSbp',
  'akblantabankSbp',
  'akbyevrofinansmosnarbankSbp',
  'akbderzhavaSbp',
  'akbalmazergienbankSbp',
  'akbalefbankSbp',
  'absolyutbankSbp',
  'morskoibankSbp',
  'agrorosSbp',
  'yandeksbankSbp',
  'pochtabankSbp',
  'renessansbankSbp',
  'otpbankSbp',
  'khoumbankSbp',
  'sovkombankSbp',
  'svoibankSbp',
  'rnkbSbp',
  'tsifrabankSbp',
  'transkapitalbanktkbSbp',
  'alfabankSbp',
  'moskovskiikreditniibankSbp',
  'rosselkhozbankSbp',
  'rosbankSbp',
  'akbarsSbp',
  'forabankSbp',
  'bankrusskiistandartSbp',
  'ubrirSbp',
  'uralsibbankSbp',
  'gazprombankSbp',
  'psbSbp',
  'mtsbankSbp',
  'mbankSbp',
  'vtbSbp',
  'raiffaizenbankSbp',
  'tinkoffSbp',
  'sberbankSbp',
  'bcsbankSbp',
] as const
export const zPaymentMethodTypeSbp = z.enum(paymentMethodsTypesSbp)
export type PaymentMethodTypeSbp = (typeof paymentMethodsTypesSbp)[number]
export const paymentMethodsProvidersSbp = [
  'yunikreditbank',
  'yuginvestbank',
  'esbiaibank',
  'ekspobank',
  'chelyabinvestbank',
  'chelindbank',
  'tsmrbank',
  'tsentrokredit',
  'khakasskiimunitsipalniibank',
  'khaisbank',
  'finstarbank',
  'uralfinans',
  'uralprombank',
  'ukbnovobank',
  'ukbbelgorodsotsbank',
  'uglemetbank',
  'tomskpromstroibank',
  'tolyattikhimbank',
  'toiotabank',
  'timerbank',
  'tatsotsbank',
  'tavricheskiibank',
  'sotsiumbank',
  'snezhinskii',
  'skbprimoryaprimsotsbank',
  'sibsotsbank',
  'severniinarodniibank',
  'severgazbank',
  'sdmbank',
  'rusuniversalbank',
  'rosdorbank',
  'rnkoplatezhniikonstruktor',
  'realistbank',
  'promsvyazbank',
  'probank',
  'priovneshtorgbank',
  'pnkoelplat',
  'plait',
  'perviiinvestitsionniibank',
  'perviidortransbank',
  'ozonbank',
  'nsbank',
  'norvikbank',
  'nokssbank',
  'nkoyumani',
  'nkbank',
  'nikobank',
  'nbdbank',
  'natsinvestprombank',
  'msbankrus',
  'mpbank',
  'kuznetskbiznesbank',
  'kubantorgbank',
  'kredituralbank',
  'kredityevropabank',
  'koshelevbank',
  'kberbankkazani',
  'kbenergotransbank',
  'kbtsentrinvest',
  'kbkhlinov',
  'kburalfd',
  'kbstroilesbank',
  'kbsolidarnost',
  'kbsitibank',
  'kbsinkobank',
  'kbrusnarbank',
  'kbrostfinans',
  'kbpoidyom',
  'kbnoviivek',
  'kbmoskommertsbank',
  'kbmodulbank',
  'kblokobank',
  'kbkubankredit',
  'kbkrokusbank',
  'kbdolinsk',
  'kbgarantinvest',
  'kbvneshfinbank',
  'kbaresbank',
  'kbagropromkredit',
  'ishbank',
  'ingosstrakhbank',
  'inbank',
  'ikbryarinterbank',
  'ikbank',
  'zemskiibank',
  'draivklikbank',
  'dbofaktura',
  'databank',
  'dalnevostochniibank',
  'gorbank',
  'genbank',
  'gazanergobank',
  'gaztransbank',
  'vuzbank',
  'vladbiznesbank',
  'bistrobank',
  'blankbank',
  'bksbank',
  'bbrbank',
  'bankelita',
  'bankfinservis',
  'bankfinam',
  'banktochka',
  'banksngb',
  'banksinara',
  'banksaratov',
  'banksanktpeterburg',
  'bankresodkoediteshnogo',
  'bankraund',
  'bankrazvitiestolitsa',
  'bankpskb',
  'bankotkritie',
  'bankorenburg',
  'bankoranzhevii',
  'bankobedinenniikapital',
  'banknovikomnovikombank',
  'banknatsionalniistandart',
  'bankmoskvasiti',
  'bankmbamoskva',
  'banklevoberezhnii',
  'bankkremlevskii',
  'bankiturup',
  'bankipb',
  'bankinteza',
  'bankzenit',
  'bankzareche',
  'bankyekaterinburg',
  'bankdomrf',
  'bankvenets',
  'bankvbrr',
  'bankbkf',
  'bankbzhf',
  'bankaleksandrovskii',
  'bankaktsept',
  'bankavers',
  'akibank',
  'akbenergobank',
  'akbforshtadt',
  'akbtransstroibank',
  'akbtenderbank',
  'akbsolid',
  'akbslaviya',
  'akbprimore',
  'akbnrbank',
  'akbmezhdunarodniifinansoviiklub',
  'yunistrim',
  'akblantabank',
  'akbyevrofinansmosnarbank',
  'akbderzhava',
  'akbalmazergienbank',
  'akbalefbank',
  'absolyutbank',
  'morskoibank',
  'agroros',
  'yandeksbank',
  'pochtabank',
  'renessansbank',
  'otpbank',
  'khoumbank',
  'sovkombank',
  'svoibank',
  'rnkb',
  'tsifrabank',
  'transkapitalbanktkb',
  'alfabank',
  'moskovskiikreditniibank',
  'rosselkhozbank',
  'rosbank',
  'akbars',
  'forabank',
  'bankrusskiistandart',
  'ubrir',
  'uralsibbank',
  'gazprombank',
  'psb',
  'mtsbank',
  'mbank',
  'vtb',
  'raiffaizenbank',
  'tinkoff',
  'sberbank',
  'bcsbank',
] as const
export const zPaymentMethodProviderSbp = z.enum(paymentMethodsProvidersSbp)
export type PaymentMethodProviderSbp = (typeof paymentMethodsProvidersSbp)[number]

export const paymentMethodsTypesGarantex = ['garantex'] as const
export const zPaymentMethodTypeGarantex = z.enum(paymentMethodsTypesGarantex)
export type PaymentMethodTypeGarantex = (typeof paymentMethodsTypesGarantex)[number]
export const paymentMethodsProvidersGarantex = ['garantex'] as const
export const zPaymentMethodProviderGarantex = z.enum(paymentMethodsProvidersGarantex)
export type PaymentMethodProviderGarantex = (typeof paymentMethodsProvidersGarantex)[number]

export const paymentMethodsProviders = [
  ...paymentMethodsProvidersCrypto,
  ...paymentMethodsProvidersCard,
  ...paymentMethodsProvidersSbp,
  ...paymentMethodsProvidersGarantex,
] as const
export const zPaymentMethodProvider = z.enum(paymentMethodsProviders)
export type PaymentMethodProvider = z.output<typeof zPaymentMethodProvider>

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const check0: IsEqual<PaymentMethodProvider, PrismaPaymentMethodProvider> = true

export const paymentMethodsTypesByGroups = {
  crypto: paymentMethodsTypesCrypto,
  card: paymentMethodsTypesCard,
  sbp: paymentMethodsTypesSbp,
  garantex: paymentMethodsTypesGarantex,
} as const

export const paymentMethodsTypes = [
  ...paymentMethodsTypesCrypto,
  ...paymentMethodsTypesCard,
  ...paymentMethodsTypesSbp,
  ...paymentMethodsTypesGarantex,
] as const
export const zPaymentMethodType = z.enum(paymentMethodsTypes)
export type PaymentMethodType = (typeof paymentMethodsTypes)[number]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const check2: IsEqual<PaymentMethodType, PrismaPaymentMethodType> = true

export const toHumanPaymentMethodType = (paymentMethodType: PaymentMethodType | null | undefined) => {
  if (!paymentMethodType) {
    return '—'
  }
  return {
    garantex: 'Гарантекс',
    usdtTrc20: 'USDT TRC20',
    yunikreditbankSbp: 'ЮниКредит Банк СБП',
    yuginvestbankSbp: 'ЮГ-Инвестбанк СБП',
    esbiaibankSbp: 'Эс-Би-Ай Банк СБП',
    ekspobankSbp: 'Экспобанк СБП',
    chelyabinvestbankSbp: 'ЧЕЛЯБИНВЕСТБАНК СБП',
    chelindbankSbp: 'ЧЕЛИНДБАНК СБП',
    tsmrbankSbp: 'ЦМРБанк СБП',
    tsentrokreditSbp: 'ЦентроКредит СБП',
    khakasskiimunitsipalniibankSbp: 'Хакасский муниципальный банк СБП',
    khaisbankSbp: 'Хайс Банк СБП',
    finstarbankSbp: 'ФИНСТАР БАНК СБП',
    uralfinansSbp: 'Уралфинанс СБП',
    uralprombankSbp: 'УРАЛПРОМБАНК СБП',
    ukbnovobankSbp: 'УКБ Новобанк СБП',
    ukbbelgorodsotsbankSbp: 'УКБ Белгородсоцбанк СБП',
    uglemetbankSbp: 'Углеметбанк СБП',
    tomskpromstroibankSbp: 'Томскпромстройбанк СБП',
    tolyattikhimbankSbp: 'Тольяттихимбанк СБП',
    toiotabankSbp: 'Тойота Банк СБП',
    timerbankSbp: 'Тимер Банк СБП',
    tatsotsbankSbp: 'ТАТСОЦБАНК СБП',
    tavricheskiibankSbp: 'Таврический Банк СБП',
    sotsiumbankSbp: 'СОЦИУМ-БАНК СБП',
    snezhinskiiSbp: 'Снежинский СБП',
    skbprimoryaprimsotsbankSbp: 'СКБ Приморья Примсоцбанк СБП',
    sibsotsbankSbp: 'СИБСОЦБАНК СБП',
    severniinarodniibankSbp: 'Северный Народный Банк СБП',
    severgazbankSbp: 'Севергазбанк СБП',
    sdmbankSbp: 'СДМ-Банк СБП',
    rusuniversalbankSbp: 'Русьуниверсалбанк СБП',
    rosdorbankSbp: 'РосДорБанк СБП',
    rnkoplatezhniikonstruktorSbp: 'РНКО Платежный конструктор СБП',
    realistbankSbp: 'РЕАЛИСТ БАНК СБП',
    promsvyazbankSbp: 'Промсвязьбанк СБП',
    probankSbp: 'ПроБанк СБП',
    priovneshtorgbankSbp: 'Прио-Внешторгбанк СБП',
    pnkoelplatSbp: 'ПНКО ЭЛПЛАТ СБП',
    plaitSbp: 'Плайт СБП',
    perviiinvestitsionniibankSbp: 'Первый Инвестиционный Банк СБП',
    perviidortransbankSbp: 'Первый Дортрансбанк СБП',
    ozonbankSbp: 'Озон Банк СБП',
    nsbankSbp: 'НС Банк СБП',
    norvikbankSbp: 'Норвик Банк СБП',
    nokssbankSbp: 'НОКССБАНК СБП',
    nkoyumaniSbp: 'НКО ЮМани СБП',
    nkbankSbp: 'НК Банк СБП',
    nikobankSbp: 'НИКО-БАНК СБП',
    nbdbankSbp: 'НБД-Банк СБП',
    natsinvestprombankSbp: 'Нацинвестпромбанк СБП',
    msbankrusSbp: 'МС Банк Рус СБП',
    mpbankSbp: 'МП Банк СБП',
    kuznetskbiznesbankSbp: 'Кузнецкбизнесбанк СБП',
    kubantorgbankSbp: 'Кубаньторгбанк СБП',
    kredituralbankSbp: 'Кредит Урал Банк СБП',
    kredityevropabankSbp: 'Кредит Европа Банк СБП',
    koshelevbankSbp: 'КОШЕЛЕВ-БАНК СБП',
    kberbankkazaniSbp: 'КБЭР Банк Казани СБП',
    kbenergotransbankSbp: 'КБ ЭНЕРГОТРАНСБАНК СБП',
    kbtsentrinvestSbp: 'КБ Центр-инвест СБП',
    kbkhlinovSbp: 'КБ Хлынов СБП',
    kburalfdSbp: 'КБ Урал ФД СБП',
    kbstroilesbankSbp: 'КБ СТРОЙЛЕСБАНК СБП',
    kbsolidarnostSbp: 'КБ Солидарность СБП',
    kbsitibankSbp: 'КБ Ситибанк СБП',
    kbsinkobankSbp: 'КБ СИНКО-БАНК СБП',
    kbrusnarbankSbp: 'КБ РУСНАРБАНК СБП',
    kbrostfinansSbp: 'КБ РостФинанс СБП',
    kbpoidyomSbp: 'КБ Пойдём! СБП',
    kbnoviivekSbp: 'КБ Новый век СБП',
    kbmoskommertsbankSbp: 'КБ Москоммерцбанк СБП',
    kbmodulbankSbp: 'КБ Модульбанк СБП',
    kblokobankSbp: 'КБ ЛОКО-Банк СБП',
    kbkubankreditSbp: 'КБ Кубань Кредит СБП',
    kbkrokusbankSbp: 'КБ Крокус-Банк СБП',
    kbdolinskSbp: 'КБ Долинск СБП',
    kbgarantinvestSbp: 'КБ Гарант-Инвест СБП',
    kbvneshfinbankSbp: 'КБ ВНЕШФИНБАНК СБП',
    kbaresbankSbp: 'КБ АРЕСБАНК СБП',
    kbagropromkreditSbp: 'КБ АГРОПРОМКРЕДИТ СБП',
    ishbankSbp: 'ИШБАНК СБП',
    ingosstrakhbankSbp: 'Ингосстрах Банк СБП',
    inbankSbp: 'Инбанк СБП',
    ikbryarinterbankSbp: 'ИКБР ЯРИНТЕРБАНК СБП',
    ikbankSbp: 'ИК Банк СБП',
    zemskiibankSbp: 'Земский банк СБП',
    draivklikbankSbp: 'Драйв Клик Банк СБП',
    dbofakturaSbp: 'ДБО Фактура СБП',
    databankSbp: 'Датабанк СБП',
    dalnevostochniibankSbp: 'Дальневосточный банк СБП',
    gorbankSbp: 'ГОРБАНК СБП',
    genbankSbp: 'ГЕНБАНК СБП',
    gazanergobankSbp: 'Газанергобанк СБП',
    gaztransbankSbp: 'Газтрансбанк СБП',
    vuzbankSbp: 'ВУЗ-банк СБП',
    vladbiznesbankSbp: 'ВЛАДБИЗНЕСБАНК СБП',
    bistrobankSbp: 'БыстроБанк СБП',
    blankbankSbp: 'Бланк банк СБП',
    bksbankSbp: 'БКС Банк СБП',
    bbrbankSbp: 'ББР Банк СБП',
    bankelitaSbp: 'банк Элита СБП',
    bankfinservisSbp: 'Банк Финсервис СБП',
    bankfinamSbp: 'Банк ФИНАМ СБП',
    banktochkaSbp: 'Банк Точка СБП',
    banksngbSbp: 'БАНК СНГБ СБП',
    banksinaraSbp: 'Банк Синара СБП',
    banksaratovSbp: 'Банк Саратов СБП',
    banksanktpeterburgSbp: 'Банк Санкт-Петербург СБП',
    bankresodkoediteshnogoSbp: 'Банк РЕСОдКоедиТешного СБП',
    bankraundSbp: 'банк Раунд СБП',
    bankrazvitiestolitsaSbp: 'Банк Развитие-Столица СБП',
    bankpskbSbp: 'Банк ПСКБ СБП',
    bankotkritieSbp: 'Банк ОТКРЫТИЕ СБП',
    bankorenburgSbp: 'БАНК ОРЕНБУРГ СБП',
    bankoranzheviiSbp: 'Банк Оранжевый СБП',
    bankobedinenniikapitalSbp: 'Банк Объединенный капитал СБП',
    banknovikomnovikombankSbp: 'Банк НОВИКОМ (НОВИКОМБАНК) СБП',
    banknatsionalniistandartSbp: 'Банк Национальный стандарт СБП',
    bankmoskvasitiSbp: 'БАНК МОСКВА-СИТИ СБП',
    bankmbamoskvaSbp: 'Банк МБА-МОСКВА СБП',
    banklevoberezhniiSbp: 'Банк Левобережный СБП',
    bankkremlevskiiSbp: 'Банк Кремлевский СБП',
    bankiturupSbp: 'Банк ИТУРУП СБП',
    bankipbSbp: 'Банк ИПБ СБП',
    bankintezaSbp: 'Банк Интеза СБП',
    bankzenitSbp: 'Банк ЗЕНИТ СБП',
    bankzarecheSbp: 'Банк Заречье СБП',
    bankyekaterinburgSbp: 'Банк Екатеринбург СБП',
    bankdomrfSbp: 'Банк ДОМ.РФ СБП',
    bankvenetsSbp: 'Банк Венец СБП',
    bankvbrrSbp: 'Банк ВБРР СБП',
    bankbkfSbp: 'Банк БКФ СБП',
    bankbzhfSbp: 'Банк БЖФ СБП',
    bankaleksandrovskiiSbp: 'Банк АЛЕКСАНДРОВСКИЙ СБП',
    bankaktseptSbp: 'Банк Акцепт СБП',
    bankaversSbp: 'Банк Аверс СБП',
    akibankSbp: 'АКИБАНК СБП',
    akbenergobankSbp: 'АКБ Энергобанк СБП',
    akbforshtadtSbp: 'АКБ Форштадт СБП',
    akbtransstroibankSbp: 'АКБ Трансстройбанк СБП',
    akbtenderbankSbp: 'АКБ ТЕНДЕР-БАНК СБП',
    akbsolidSbp: 'АКБ Солид СБП',
    akbslaviyaSbp: 'АКБ СЛАВИЯ СБП',
    akbprimoreSbp: 'АКБ Приморье СБП',
    akbnrbankSbp: 'АКБ НРБанк СБП',
    akbmezhdunarodniifinansoviiklubSbp: 'АКБ МЕЖДУНАРОДНЫЙ ФИНАНСОВЫЙ КЛУБ СБП',
    yunistrimSbp: 'Юнистрим СБП',
    akblantabankSbp: 'АКБ Ланта-Банк СБП',
    akbyevrofinansmosnarbankSbp: 'АКБ ЕВРОФИНАНС МОСНАРБАНК СБП',
    akbderzhavaSbp: 'АКБ Держава СБП',
    akbalmazergienbankSbp: 'АКБ Алмазергиэнбанк СБП',
    akbalefbankSbp: 'АКБ Алеф-Банк СБП',
    absolyutbankSbp: 'Абсолют банк СБП',
    morskoibankSbp: 'Морской банк СБП',
    agrorosSbp: 'Агророс СБП',
    yandeksbankSbp: 'Яндекс банк СБП',
    yandeksbankCard: 'Яндекс банк карта',
    pochtabankSbp: 'Почта банк СБП',
    renessansbankSbp: 'Ренессанс Банк СБП',
    otpbankSbp: 'ОТП Банк СБП',
    khoumbankSbp: 'Хоум Банк СБП',
    sovkombankSbp: 'Совкомбанк СБП',
    svoibankSbp: 'Свой Банк СБП',
    rnkbSbp: 'РНКБ СБП',
    tsifrabankSbp: 'Цифра банк СБП',
    transkapitalbanktkbSbp: 'Транскапиталбанк (ТКБ) СБП',
    alfabankSbp: 'Альфа-Банк СБП',
    moskovskiikreditniibankSbp: 'Московский кредитный банк СБП',
    rosselkhozbankSbp: 'Россельхозбанк СБП',
    rosbankSbp: 'Росбанк СБП',
    akbarsSbp: 'Ак Барс СБП',
    forabankSbp: 'Фора Банк СБП',
    bankrusskiistandartSbp: 'Банк Русский Стандарт СБП',
    ubrirSbp: 'УБРИР СБП',
    uralsibbankSbp: 'Уралсиб Банк СБП',
    gazprombankSbp: 'Газпромбанк СБП',
    psbSbp: 'ПСБ СБП',
    mtsbankSbp: 'МТС Банк СБП',
    mbankSbp: 'M Bank СБП',
    vtbSbp: 'ВТБ СБП',
    raiffaizenbankSbp: 'Райффайзенбанк СБП',
    tinkoffSbp: 'Тинькофф СБП',
    sberbankSbp: 'Сбербанк СБП',
    bcsbankSbp: 'БКС СБП',
    sberbankCard: 'Сбербанк карта',
    tinkoffCard: 'Тинькофф карта',
    alfabankCard: 'Альфа-Банк карта',
    raiffaizenbankCard: 'Райффайзенбанк карта',
    uralsibbankCard: 'Уралсиб Банк карта',
    ozonbankCard: 'Озон Банк карта',
    tsifrabankCard: 'Цифра банк карта',
    akbarsCard: 'Ак Барс карта',
    gazprombankCard: 'Газпромбанк карта',
    otpbankCard: 'ОТП-банк карта',
    vtbCard: 'ВТБ карта',
    bcsbankCard: 'БКС карта',
    rosselkhozbankCard: 'Россельхозбанк карта',
    promsvyazbankCard: 'Промсвязьбанк карта',
    psbCard: 'ПСБ карта',
  }[paymentMethodType]
}

export const toHumanPaymentMethodGroup = (paymentMethodGroup: PaymentMethodGroup | null | undefined) => {
  if (!paymentMethodGroup) {
    return '—'
  }
  return {
    crypto: 'Криптовалюта',
    card: 'Банковская карта',
    sbp: 'СБП',
    garantex: 'Гарантекс',
  }[paymentMethodGroup]
}

export const getPaymentMethodGroupByType = (paymentMethodType: PaymentMethodType) => {
  if ((paymentMethodsTypesCrypto as never as string[]).includes(paymentMethodType)) {
    return 'crypto'
  }
  if ((paymentMethodsTypesCard as never as string[]).includes(paymentMethodType)) {
    return 'card'
  }
  if ((paymentMethodsTypesSbp as never as string[]).includes(paymentMethodType)) {
    return 'sbp'
  }
  if ((paymentMethodsTypesGarantex as never as string[]).includes(paymentMethodType)) {
    return 'garantex'
  }
  return null
}

export const getPaymentMethodProviderByType = (paymentMethodType: PaymentMethodType): PaymentMethodProvider => {
  return (
    {
      usdtTrc20: 'usdtTrc20',
      garantex: 'garantex',
      yunikreditbankSbp: 'yunikreditbank',
      yuginvestbankSbp: 'yuginvestbank',
      esbiaibankSbp: 'esbiaibank',
      ekspobankSbp: 'ekspobank',
      chelyabinvestbankSbp: 'chelyabinvestbank',
      chelindbankSbp: 'chelindbank',
      tsmrbankSbp: 'tsmrbank',
      tsentrokreditSbp: 'tsentrokredit',
      khakasskiimunitsipalniibankSbp: 'khakasskiimunitsipalniibank',
      khaisbankSbp: 'khaisbank',
      finstarbankSbp: 'finstarbank',
      uralfinansSbp: 'uralfinans',
      uralprombankSbp: 'uralprombank',
      ukbnovobankSbp: 'ukbnovobank',
      ukbbelgorodsotsbankSbp: 'ukbbelgorodsotsbank',
      uglemetbankSbp: 'uglemetbank',
      tomskpromstroibankSbp: 'tomskpromstroibank',
      tolyattikhimbankSbp: 'tolyattikhimbank',
      toiotabankSbp: 'toiotabank',
      timerbankSbp: 'timerbank',
      tatsotsbankSbp: 'tatsotsbank',
      tavricheskiibankSbp: 'tavricheskiibank',
      sotsiumbankSbp: 'sotsiumbank',
      snezhinskiiSbp: 'snezhinskii',
      skbprimoryaprimsotsbankSbp: 'skbprimoryaprimsotsbank',
      sibsotsbankSbp: 'sibsotsbank',
      severniinarodniibankSbp: 'severniinarodniibank',
      severgazbankSbp: 'severgazbank',
      sdmbankSbp: 'sdmbank',
      rusuniversalbankSbp: 'rusuniversalbank',
      rosdorbankSbp: 'rosdorbank',
      rnkoplatezhniikonstruktorSbp: 'rnkoplatezhniikonstruktor',
      realistbankSbp: 'realistbank',
      promsvyazbankSbp: 'promsvyazbank',
      probankSbp: 'probank',
      priovneshtorgbankSbp: 'priovneshtorgbank',
      pnkoelplatSbp: 'pnkoelplat',
      plaitSbp: 'plait',
      perviiinvestitsionniibankSbp: 'perviiinvestitsionniibank',
      perviidortransbankSbp: 'perviidortransbank',
      ozonbankSbp: 'ozonbank',
      nsbankSbp: 'nsbank',
      norvikbankSbp: 'norvikbank',
      nokssbankSbp: 'nokssbank',
      nkoyumaniSbp: 'nkoyumani',
      nkbankSbp: 'nkbank',
      nikobankSbp: 'nikobank',
      nbdbankSbp: 'nbdbank',
      natsinvestprombankSbp: 'natsinvestprombank',
      msbankrusSbp: 'msbankrus',
      mpbankSbp: 'mpbank',
      kuznetskbiznesbankSbp: 'kuznetskbiznesbank',
      kubantorgbankSbp: 'kubantorgbank',
      kredituralbankSbp: 'kredituralbank',
      kredityevropabankSbp: 'kredityevropabank',
      koshelevbankSbp: 'koshelevbank',
      kberbankkazaniSbp: 'kberbankkazani',
      kbenergotransbankSbp: 'kbenergotransbank',
      kbtsentrinvestSbp: 'kbtsentrinvest',
      kbkhlinovSbp: 'kbkhlinov',
      kburalfdSbp: 'kburalfd',
      kbstroilesbankSbp: 'kbstroilesbank',
      kbsolidarnostSbp: 'kbsolidarnost',
      kbsitibankSbp: 'kbsitibank',
      kbsinkobankSbp: 'kbsinkobank',
      kbrusnarbankSbp: 'kbrusnarbank',
      kbrostfinansSbp: 'kbrostfinans',
      kbpoidyomSbp: 'kbpoidyom',
      kbnoviivekSbp: 'kbnoviivek',
      kbmoskommertsbankSbp: 'kbmoskommertsbank',
      kbmodulbankSbp: 'kbmodulbank',
      kblokobankSbp: 'kblokobank',
      kbkubankreditSbp: 'kbkubankredit',
      kbkrokusbankSbp: 'kbkrokusbank',
      kbdolinskSbp: 'kbdolinsk',
      kbgarantinvestSbp: 'kbgarantinvest',
      kbvneshfinbankSbp: 'kbvneshfinbank',
      kbaresbankSbp: 'kbaresbank',
      kbagropromkreditSbp: 'kbagropromkredit',
      ishbankSbp: 'ishbank',
      ingosstrakhbankSbp: 'ingosstrakhbank',
      inbankSbp: 'inbank',
      ikbryarinterbankSbp: 'ikbryarinterbank',
      ikbankSbp: 'ikbank',
      zemskiibankSbp: 'zemskiibank',
      draivklikbankSbp: 'draivklikbank',
      dbofakturaSbp: 'dbofaktura',
      databankSbp: 'databank',
      dalnevostochniibankSbp: 'dalnevostochniibank',
      gorbankSbp: 'gorbank',
      genbankSbp: 'genbank',
      gazanergobankSbp: 'gazanergobank',
      gaztransbankSbp: 'gaztransbank',
      vuzbankSbp: 'vuzbank',
      vladbiznesbankSbp: 'vladbiznesbank',
      bistrobankSbp: 'bistrobank',
      blankbankSbp: 'blankbank',
      bksbankSbp: 'bksbank',
      bbrbankSbp: 'bbrbank',
      bankelitaSbp: 'bankelita',
      bankfinservisSbp: 'bankfinservis',
      bankfinamSbp: 'bankfinam',
      banktochkaSbp: 'banktochka',
      banksngbSbp: 'banksngb',
      banksinaraSbp: 'banksinara',
      banksaratovSbp: 'banksaratov',
      banksanktpeterburgSbp: 'banksanktpeterburg',
      bankresodkoediteshnogoSbp: 'bankresodkoediteshnogo',
      bankraundSbp: 'bankraund',
      bankrazvitiestolitsaSbp: 'bankrazvitiestolitsa',
      bankpskbSbp: 'bankpskb',
      bankotkritieSbp: 'bankotkritie',
      bankorenburgSbp: 'bankorenburg',
      bankoranzheviiSbp: 'bankoranzhevii',
      bankobedinenniikapitalSbp: 'bankobedinenniikapital',
      banknovikomnovikombankSbp: 'banknovikomnovikombank',
      banknatsionalniistandartSbp: 'banknatsionalniistandart',
      bankmoskvasitiSbp: 'bankmoskvasiti',
      bankmbamoskvaSbp: 'bankmbamoskva',
      banklevoberezhniiSbp: 'banklevoberezhnii',
      bankkremlevskiiSbp: 'bankkremlevskii',
      bankiturupSbp: 'bankiturup',
      bankipbSbp: 'bankipb',
      bankintezaSbp: 'bankinteza',
      bankzenitSbp: 'bankzenit',
      bankzarecheSbp: 'bankzareche',
      bankyekaterinburgSbp: 'bankyekaterinburg',
      bankdomrfSbp: 'bankdomrf',
      bankvenetsSbp: 'bankvenets',
      bankvbrrSbp: 'bankvbrr',
      bankbkfSbp: 'bankbkf',
      bankbzhfSbp: 'bankbzhf',
      bankaleksandrovskiiSbp: 'bankaleksandrovskii',
      bankaktseptSbp: 'bankaktsept',
      bankaversSbp: 'bankavers',
      akibankSbp: 'akibank',
      akbenergobankSbp: 'akbenergobank',
      akbforshtadtSbp: 'akbforshtadt',
      akbtransstroibankSbp: 'akbtransstroibank',
      akbtenderbankSbp: 'akbtenderbank',
      akbsolidSbp: 'akbsolid',
      akbslaviyaSbp: 'akbslaviya',
      akbprimoreSbp: 'akbprimore',
      akbnrbankSbp: 'akbnrbank',
      akbmezhdunarodniifinansoviiklubSbp: 'akbmezhdunarodniifinansoviiklub',
      yunistrimSbp: 'yunistrim',
      akblantabankSbp: 'akblantabank',
      akbyevrofinansmosnarbankSbp: 'akbyevrofinansmosnarbank',
      akbderzhavaSbp: 'akbderzhava',
      akbalmazergienbankSbp: 'akbalmazergienbank',
      akbalefbankSbp: 'akbalefbank',
      absolyutbankSbp: 'absolyutbank',
      morskoibankSbp: 'morskoibank',
      agrorosSbp: 'agroros',
      yandeksbankSbp: 'yandeksbank',
      yandeksbankCard: 'yandeksbank',
      pochtabankSbp: 'pochtabank',
      renessansbankSbp: 'renessansbank',
      otpbankSbp: 'otpbank',
      khoumbankSbp: 'khoumbank',
      sovkombankSbp: 'sovkombank',
      svoibankSbp: 'svoibank',
      rnkbSbp: 'rnkb',
      tsifrabankSbp: 'tsifrabank',
      transkapitalbanktkbSbp: 'transkapitalbanktkb',
      alfabankSbp: 'alfabank',
      moskovskiikreditniibankSbp: 'moskovskiikreditniibank',
      rosselkhozbankSbp: 'rosselkhozbank',
      rosbankSbp: 'rosbank',
      akbarsSbp: 'akbars',
      forabankSbp: 'forabank',
      bankrusskiistandartSbp: 'bankrusskiistandart',
      ubrirSbp: 'ubrir',
      uralsibbankSbp: 'uralsibbank',
      gazprombankSbp: 'gazprombank',
      psbSbp: 'psb',
      mtsbankSbp: 'mtsbank',
      mbankSbp: 'mbank',
      vtbSbp: 'vtb',
      raiffaizenbankSbp: 'raiffaizenbank',
      tinkoffSbp: 'tinkoff',
      sberbankSbp: 'sberbank',
      bcsbankSbp: 'bcsbank',
      sberbankCard: 'sberbank',
      tinkoffCard: 'tinkoff',
      alfabankCard: 'alfabank',
      raiffaizenbankCard: 'raiffaizenbank',
      uralsibbankCard: 'uralsibbank',
      ozonbankCard: 'ozonbank',
      tsifrabankCard: 'tsifrabank',
      akbarsCard: 'akbars',
      gazprombankCard: 'gazprombank',
      otpbankCard: 'otpbank',
      vtbCard: 'vtb',
      bcsbankCard: 'bcsbank',
      rosselkhozbankCard: 'rosselkhozbank',
      promsvyazbankCard: 'promsvyazbank',
      psbCard: 'psb',
    } satisfies Record<PaymentMethodType, PaymentMethodProvider>
  )[paymentMethodType]
}

export const zPaymentMethodGeneralCrypto = z.object({
  group: z.literal('crypto'),
  type: zPaymentMethodTypeCrypto,
  wallet: zStringRequired,
  provider: zPaymentMethodProviderCrypto,
})
export const zPaymentMethodFullCrypto = zPaymentMethodRecordFullRest.merge(zPaymentMethodGeneralCrypto)
export const zPaymentMethodPreviewCrypto = zPaymentMethodRecordPreviewRest.merge(zPaymentMethodGeneralCrypto)
export type PaymentMethodGeneralCrypto = Simplify<z.output<typeof zPaymentMethodGeneralCrypto>>
export type PaymentMethodFullCrypto = Simplify<z.output<typeof zPaymentMethodFullCrypto>>
export type PaymentMethodPreviewCrypto = Simplify<z.output<typeof zPaymentMethodPreviewCrypto>>

export const zPaymentMethodGeneralCard = z.object({
  group: z.literal('card'),
  type: zPaymentMethodTypeCard,
  ownerName: zStringRequired,
  cardNumber: zBankCardNumberRequired,
  provider: zPaymentMethodProviderCard,
})
export const zPaymentMethodFullCard = zPaymentMethodRecordFullRest.merge(zPaymentMethodGeneralCard)
export const zPaymentMethodPreviewCard = zPaymentMethodRecordPreviewRest.merge(zPaymentMethodGeneralCard)
export type PaymentMethodGeneralCard = Simplify<z.output<typeof zPaymentMethodGeneralCard>>
export type PaymentMethodFullCard = Simplify<z.output<typeof zPaymentMethodFullCard>>
export type PaymentMethodPreviewCard = Simplify<z.output<typeof zPaymentMethodPreviewCard>>

export const zPaymentMethodGeneralSbp = z.object({
  group: z.literal('sbp'),
  type: zPaymentMethodTypeSbp,
  ownerName: zStringRequired,
  ownerPhone: zPhoneRequired,
  provider: zPaymentMethodProviderSbp,
})
export const zPaymentMethodFullSbp = zPaymentMethodRecordFullRest.merge(zPaymentMethodGeneralSbp)
export const zPaymentMethodPreviewSbp = zPaymentMethodRecordPreviewRest.merge(zPaymentMethodGeneralSbp)
export type PaymentMethodGeneralSbp = Simplify<z.output<typeof zPaymentMethodGeneralSbp>>
export type PaymentMethodFullSbp = Simplify<z.output<typeof zPaymentMethodFullSbp>>
export type PaymentMethodPreviewSbp = Simplify<z.output<typeof zPaymentMethodPreviewSbp>>

export const zPaymentMethodGeneralGarantex = z.object({
  group: z.literal('garantex'),
  type: zPaymentMethodTypeGarantex,
  provider: zPaymentMethodProviderGarantex,
})
export const zPaymentMethodFullGarantex = zPaymentMethodRecordFullRest.merge(zPaymentMethodGeneralGarantex)
export const zPaymentMethodPreviewGarantex = zPaymentMethodRecordPreviewRest.merge(zPaymentMethodGeneralGarantex)
export type PaymentMethodGeneralGarantex = Simplify<z.output<typeof zPaymentMethodGeneralGarantex>>
export type PaymentMethodFullGarantex = Simplify<z.output<typeof zPaymentMethodFullGarantex>>
export type PaymentMethodPreviewGarantex = Simplify<z.output<typeof zPaymentMethodPreviewGarantex>>

export const zPaymentMethodGeneral = z.union([
  zPaymentMethodGeneralCrypto,
  zPaymentMethodGeneralCard,
  zPaymentMethodGeneralSbp,
  zPaymentMethodGeneralGarantex,
])
export const zPaymentMethodFull = z.union([
  zPaymentMethodFullCrypto,
  zPaymentMethodFullCard,
  zPaymentMethodFullSbp,
  zPaymentMethodFullGarantex,
])
export const zPaymentMethodPreview = z.union([
  zPaymentMethodPreviewCrypto,
  zPaymentMethodPreviewCard,
  zPaymentMethodPreviewSbp,
  zPaymentMethodPreviewGarantex,
])
export type PaymentMethodGeneral = Simplify<z.output<typeof zPaymentMethodGeneral>>
export type PaymentMethodFull = Simplify<z.output<typeof zPaymentMethodFull>>
export type PaymentMethodPreview = Simplify<z.output<typeof zPaymentMethodPreview>>

export const zPaymentMethodsGenerals = z.array(zPaymentMethodGeneral)
export const zPaymentMethodsFull = z.array(zPaymentMethodFull)
export const zPaymentMethodsPreviews = z.array(zPaymentMethodPreview)
export type PaymentMethodsGenerals = Simplify<z.output<typeof zPaymentMethodsGenerals>>
export type PaymentMethodsFull = Simplify<z.output<typeof zPaymentMethodsFull>>
export type PaymentMethodsPreviews = Simplify<z.output<typeof zPaymentMethodsPreviews>>

// export const zPaymentMethodRecordUpsertInput = zPaymentMethodGeneral.merge({
//   id: z.string().optional(),
// }).merge(zPaymentMethodRecordAvailability).merge()

export const zPaymentMethodRecordUpsertInput = z
  .union([
    zPaymentMethodGeneralCrypto
      .omit({ provider: true })
      .merge(zPaymentMethodRecordAvailability)
      .merge(zPaymentMethodLimits)
      .extend({ id: z.string().optional(), saved: z.boolean().optional() }),
    zPaymentMethodGeneralCard
      .omit({ provider: true })
      .merge(zPaymentMethodRecordAvailability)
      .merge(zPaymentMethodLimits)
      .extend({ id: z.string().optional(), saved: z.boolean().optional() }),
    zPaymentMethodGeneralSbp
      .omit({ provider: true })
      .merge(zPaymentMethodRecordAvailability)
      .merge(zPaymentMethodLimits)
      .extend({ id: z.string().optional(), saved: z.boolean().optional() }),
    zPaymentMethodGeneralGarantex
      .omit({ provider: true })
      .merge(zPaymentMethodRecordAvailability)
      .merge(zPaymentMethodLimits)
      .extend({ id: z.string().optional(), saved: z.boolean().optional() }),
  ])
  .transform((data) => {
    return {
      ...data,
      provider: getPaymentMethodProviderByType(data.type),
    }
  })
export type PaymentMethodRecordUpsertDataInput = Simplify<z.input<typeof zPaymentMethodRecordUpsertInput>>
export type PaymentMethodRecordUpsertDataOutput = Simplify<z.output<typeof zPaymentMethodRecordUpsertInput>>
export const zPaymentMethodRecordsUpsertData = z.array(zPaymentMethodRecordUpsertInput)
export type PaymentMethodRecordsUpsertDataInput = Simplify<z.input<typeof zPaymentMethodRecordsUpsertData>>
export type PaymentMethodRecordsUpsertDataOutput = Simplify<z.output<typeof zPaymentMethodRecordsUpsertData>>

export const toHumanReadablePaymentMethod = (paymentMethod: PaymentMethodGeneral | ClientPaymentMethodForOwner) => {
  const getLast4 = (str: string | null) => str?.slice(-4) || '—'
  switch (paymentMethod.group) {
    case 'crypto':
      return `${toHumanPaymentMethodType(paymentMethod.type)} ...${getLast4(paymentMethod.wallet)}`
    case 'card':
      return `${toHumanPaymentMethodType(paymentMethod.type)} ...${getLast4(paymentMethod.cardNumber)}`
    case 'sbp':
      return `${toHumanPaymentMethodType(paymentMethod.type)} ${getLast4(paymentMethod.ownerPhone)}`
    case 'garantex':
      return `${toHumanPaymentMethodType(paymentMethod.type)}`
    default:
      return '—'
  }
}

export const isSuitablePaymentMethodByGroupAndType = (
  requestedPaymentGroup: PaymentMethodGroup,
  requestedPaymentType: PaymentMethodType,
  offeredPaymentMethod: ClientPaymentMethodForOwner
) => {
  if (requestedPaymentGroup !== offeredPaymentMethod.group) {
    return false
  }
  if (requestedPaymentGroup === 'sbp') {
    return true
  }
  if (requestedPaymentGroup === 'card') {
    return requestedPaymentType === offeredPaymentMethod.type
  }
  if (requestedPaymentGroup === 'crypto') {
    return requestedPaymentType === offeredPaymentMethod.type
  }
  if (requestedPaymentGroup === 'garantex') {
    return requestedPaymentType === offeredPaymentMethod.type
  }
  return false
}

export const isSuitablePaymentMethodByFamily = (
  requestedFamily: PaymentMethodFamily,
  offeredPaymentMethod: PaymentMethodGeneral | ClientPaymentMethodForOwner
) => {
  switch (requestedFamily) {
    case 'internal':
      return (paymentMethodsGroupsInternal as any).includes(offeredPaymentMethod.group)
    case 'external':
      return (paymentMethodsGroupsExternal as any).includes(offeredPaymentMethod.group)
    case 'externalCrypto':
      return (paymentMethodsGroupsExternalCrypto as any).includes(offeredPaymentMethod.group)
    case 'externalFiat':
      return (paymentMethodsGroupsExternalFiat as any).includes(offeredPaymentMethod.group)
    default:
      return false
  }
}

export const filterPaymentMethodsByGroupAndType = (
  paymentMethods: ClientPaymentMethodForOwner[],
  requestedPaymentGroup: PaymentMethodGroup,
  requestedPaymentType: PaymentMethodType,
  availability: 'input' | 'output' | 'any' | 'none'
) => {
  return paymentMethods.filter(
    (paymentMethod) =>
      isSuitablePaymentMethodByGroupAndType(requestedPaymentGroup, requestedPaymentType, paymentMethod) &&
      (availability === 'any' ||
        (paymentMethod.availableForInput && availability === 'input') ||
        (paymentMethod.availableForOutput && availability === 'output') ||
        (!paymentMethod.availableForInput && !paymentMethod.availableForOutput && availability === 'none'))
  )
}

export const filterPaymentMethodsByFamily = <TPaymentMethodsFamily extends PaymentMethodFamily>(
  paymentMethods: ClientPaymentMethodForOwner[],
  requestedFamily: TPaymentMethodsFamily,
  availability: 'input' | 'output' | 'any' | 'none'
): TPaymentMethodsFamily extends 'internal'
  ? PaymentMethodsInternalFull
  : TPaymentMethodsFamily extends 'external'
    ? PaymentMethodsExternalFull
    : TPaymentMethodsFamily extends 'externalCrypto'
      ? PaymentMethodsExternalCryptoFull
      : TPaymentMethodsFamily extends 'externalFiat'
        ? PaymentMethodsExternalFiatFull
        : never => {
  return paymentMethods.filter(
    (paymentMethod) =>
      isSuitablePaymentMethodByFamily(requestedFamily, paymentMethod) &&
      (availability === 'any' ||
        (paymentMethod.availableForInput && availability === 'input') ||
        (paymentMethod.availableForOutput && availability === 'output') ||
        (!paymentMethod.availableForInput && !paymentMethod.availableForOutput && availability === 'none'))
  ) as never
}

export const isSamePaymentMethod = (a: ClientPaymentMethodGeneral, b: ClientPaymentMethodGeneral) => {
  if (a.type !== b.type) {
    return false
  }
  if (a.group !== b.group) {
    return false
  }
  switch (a.group) {
    case 'crypto':
      return a.wallet === (b as any).wallet
    case 'card':
      return a.cardNumber === (b as any).cardNumber
    case 'sbp':
      return a.ownerPhone === (b as any).ownerPhone
    case 'garantex':
      return true
    default:
      return false
  }
}

// Internal: Garantex and Crypto

export const paymentMethodsGroupsInternal = ['crypto', 'garantex'] as const
export const zPaymentMethodGroupInternal = z.enum(paymentMethodsGroupsInternal)
export type PaymentMethodGroupInternal = (typeof paymentMethodsGroupsInternal)[number]

export const paymentMethodsTypesByGroupsInternal = {
  crypto: paymentMethodsTypesCrypto,
  garantex: paymentMethodsTypesGarantex,
} as const

export const paymentMethodsTypesInternal = [...paymentMethodsTypesCrypto, ...paymentMethodsTypesGarantex] as const
export const zPaymentMethodTypeInternal = z.enum(paymentMethodsTypesInternal)
export type PaymentMethodTypeInternal = (typeof paymentMethodsTypesInternal)[number]

export const zPaymentMethodInternalGeneral = z.union([zPaymentMethodGeneralCrypto, zPaymentMethodGeneralGarantex])
export const zPaymentMethodInternalFull = z.union([zPaymentMethodFullCrypto, zPaymentMethodFullGarantex])
export const zPaymentMethodInternalPreview = z.union([zPaymentMethodPreviewCrypto, zPaymentMethodPreviewGarantex])
export type PaymentMethodInternalGeneral = z.output<typeof zPaymentMethodInternalGeneral>
export type PaymentMethodInternalFull = z.output<typeof zPaymentMethodInternalFull>
export type PaymentMethodInternalPreview = z.output<typeof zPaymentMethodInternalPreview>

export const zPaymentMethodsInternalGeneral = z.array(zPaymentMethodInternalGeneral)
export const zPaymentMethodsInternalFull = z.array(zPaymentMethodInternalFull)
export const zPaymentMethodsInternalPreview = z.array(zPaymentMethodInternalPreview)
export type PaymentMethodsInternalGeneral = z.output<typeof zPaymentMethodsInternalGeneral>
export type PaymentMethodsInternalFull = z.output<typeof zPaymentMethodsInternalFull>
export type PaymentMethodsInternalPreview = z.output<typeof zPaymentMethodsInternalPreview>

// External: Card, Sbp, Crypto

export const paymentMethodsGroupsExternal = ['crypto', 'card', 'sbp'] as const
export const zPaymentMethodGroupExternal = z.enum(paymentMethodsGroupsExternal)
export type PaymentMethodGroupExternal = (typeof paymentMethodsGroupsExternal)[number]

export const paymentMethodsTypesByGroupsExternal = {
  crypto: paymentMethodsTypesCrypto,
  card: paymentMethodsTypesCard,
  sbp: paymentMethodsTypesSbp,
} as const

export const paymentMethodsTypesExternal = [
  ...paymentMethodsTypesCrypto,
  ...paymentMethodsTypesCard,
  ...paymentMethodsTypesSbp,
] as const
export const zPaymentMethodTypeExternal = z.enum(paymentMethodsTypesExternal)
export type PaymentMethodTypeExternal = (typeof paymentMethodsTypesExternal)[number]

export const zPaymentMethodExternalGeneral = z.union([
  zPaymentMethodGeneralCrypto,
  zPaymentMethodGeneralCard,
  zPaymentMethodGeneralSbp,
])
export const zPaymentMethodExternalFull = z.union([
  zPaymentMethodFullCrypto,
  zPaymentMethodFullCard,
  zPaymentMethodFullSbp,
])
export const zPaymentMethodExternalPreview = z.union([
  zPaymentMethodPreviewCrypto,
  zPaymentMethodPreviewCard,
  zPaymentMethodPreviewSbp,
])
export type PaymentMethodExternalGeneral = z.output<typeof zPaymentMethodExternalGeneral>
export type PaymentMethodExternalFull = z.output<typeof zPaymentMethodExternalFull>
export type PaymentMethodExternalPreview = z.output<typeof zPaymentMethodExternalPreview>

export const zPaymentMethodsExternalGeneral = z.array(zPaymentMethodExternalGeneral)
export const zPaymentMethodsExternalFull = z.array(zPaymentMethodExternalFull)
export const zPaymentMethodsExternalPreview = z.array(zPaymentMethodExternalPreview)
export type PaymentMethodsExternalGeneral = z.output<typeof zPaymentMethodsExternalGeneral>
export type PaymentMethodsExternalFull = z.output<typeof zPaymentMethodsExternalFull>
export type PaymentMethodsExternalPreview = z.output<typeof zPaymentMethodsExternalPreview>

export const getPaymentMethodGroupByTypeExternal = (paymentMethodType: PaymentMethodTypeExternal) => {
  if ((paymentMethodsTypesCrypto as never as string[]).includes(paymentMethodType)) {
    return 'crypto'
  }
  if ((paymentMethodsTypesCard as never as string[]).includes(paymentMethodType)) {
    return 'card'
  }
  if ((paymentMethodsTypesSbp as never as string[]).includes(paymentMethodType)) {
    return 'sbp'
  }
  return null
}

// External Crypto: Crypto

export const paymentMethodsGroupsExternalCrypto = ['crypto'] as const
export const zPaymentMethodGroupExternalCrypto = z.enum(paymentMethodsGroupsExternalCrypto)
export type PaymentMethodGroupExternalCrypto = (typeof paymentMethodsGroupsExternalCrypto)[number]

export const paymentMethodsTypesExternalCrypto = paymentMethodsTypesCrypto
export const zPaymentMethodTypeExternalCrypto = z.enum(paymentMethodsTypesExternalCrypto)
export type PaymentMethodTypeExternalCrypto = (typeof paymentMethodsTypesExternalCrypto)[number]

export const zPaymentMethodExternalCryptoGeneral = zPaymentMethodGeneralCrypto
export const zPaymentMethodExternalCryptoFull = zPaymentMethodFullCrypto
export const zPaymentMethodExternalCryptoPreview = zPaymentMethodPreviewCrypto
export type PaymentMethodExternalCryptoGeneral = PaymentMethodGeneralCrypto
export type PaymentMethodExternalCryptoFull = PaymentMethodFullCrypto
export type PaymentMethodExternalCryptoPreview = PaymentMethodPreviewCrypto

export const zPaymentMethodsExternalCryptoGeneral = z.array(zPaymentMethodExternalCryptoGeneral)
export const zPaymentMethodsExternalCryptoFull = z.array(zPaymentMethodExternalCryptoFull)
export const zPaymentMethodsExternalCryptoPreview = z.array(zPaymentMethodExternalCryptoPreview)
export type PaymentMethodsExternalCryptoGeneral = PaymentMethodExternalCryptoGeneral[]
export type PaymentMethodsExternalCryptoFull = PaymentMethodExternalCryptoFull[]
export type PaymentMethodsExternalCryptoPreview = PaymentMethodExternalCryptoPreview[]

export const getPaymentMethodGroupByTypeExternalCrypto = (paymentMethodType: PaymentMethodTypeExternalCrypto) => {
  if ((paymentMethodsTypesCrypto as never as string[]).includes(paymentMethodType)) {
    return 'crypto'
  }
  return null
}

// External Fiat: Card, Sbp

export const paymentMethodsGroupsExternalFiat = ['card', 'sbp'] as const
export const zPaymentMethodGroupExternalFiat = z.enum(paymentMethodsGroupsExternalFiat)
export type PaymentMethodGroupExternalFiat = Simplify<(typeof paymentMethodsGroupsExternalFiat)[number]>

export const paymentMethodsTypesExternalFiat = [...paymentMethodsTypesCard, ...paymentMethodsTypesSbp] as const
export const zPaymentMethodTypeExternalFiat = z.enum(paymentMethodsTypesExternalFiat)
export type PaymentMethodTypeExternalFiat = Simplify<(typeof paymentMethodsTypesExternalFiat)[number]>

export const zPaymentMethodExternalFiatGeneral = z.union([zPaymentMethodGeneralCard, zPaymentMethodGeneralSbp])
export const zPaymentMethodExternalFiatFull = z.union([zPaymentMethodFullCard, zPaymentMethodFullSbp])
export const zPaymentMethodExternalFiatPreview = z.union([zPaymentMethodPreviewCard, zPaymentMethodPreviewSbp])
export type PaymentMethodExternalFiatGeneral = Simplify<z.output<typeof zPaymentMethodExternalFiatGeneral>>
export type PaymentMethodExternalFiatFull = Simplify<z.output<typeof zPaymentMethodExternalFiatFull>>
export type PaymentMethodExternalFiatPreview = Simplify<z.output<typeof zPaymentMethodExternalFiatPreview>>

export const zPaymentMethodsExternalFiatGeneral = z.array(zPaymentMethodExternalFiatGeneral)
export const zPaymentMethodsExternalFiatFull = z.array(zPaymentMethodExternalFiatFull)
export const zPaymentMethodsExternalFiatPreview = z.array(zPaymentMethodExternalFiatPreview)
export type PaymentMethodsExternalFiatGeneral = PaymentMethodExternalFiatGeneral[]
export type PaymentMethodsExternalFiatFull = PaymentMethodExternalFiatFull[]
export type PaymentMethodsExternalFiatPreview = PaymentMethodExternalFiatPreview[]

export const getPaymentMethodGroupByTypeExternalFiat = (paymentMethodType: PaymentMethodTypeExternalFiat) => {
  if ((paymentMethodsTypesCard as never as string[]).includes(paymentMethodType)) {
    return 'card'
  }
  if ((paymentMethodsTypesSbp as never as string[]).includes(paymentMethodType)) {
    return 'sbp'
  }
  return null
}
