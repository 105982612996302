import { pageData } from './pageData.js'
import { integerWithDecimalsToAmountString, toMoney } from '@/general/src/other/money.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminMerchantEditRoute, adminMerchantMemberListRoute, adminMerchantNewRoute } from '@/webapp/src/lib/routes.js'
import { SectionWrapper } from '@/webapp/src/lib/sectionWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, Link, Segment, Table } from '@/webapp/src/lib/uninty.components.js'
import { Tooltip } from '@mantine/core'

const breadcrumbs = [{ title: 'Мерчанты' }]

export const AdminMerchantListPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
})(() => {
  return (
    <Block fcnw>
      <Breadcrumbs items={breadcrumbs} />
      <Segment
        title="Мерчанты"
        size="m"
        desc={
          <Buttons>
            <Button as="a" href={adminMerchantNewRoute.get()} className="buttonA">
              Добавить мерчанта
            </Button>
          </Buttons>
        }
      >
        <SectionWrapper
          useQuery={() => trpc.getMerchantsForAdmin.useQuery({})}
          setProps={({ queryResult }) => ({
            merchants: queryResult.data.merchants,
          })}
        >
          {({ merchants }) => {
            return (
              <Table
                className="unintyTable"
                records={merchants}
                columns={[
                  {
                    heading: (
                      <Tooltip label="Серийный номер мерчанта" position="right">
                        <div>SN</div>
                      </Tooltip>
                    ),
                    body: (record) => record.serialNumber,
                    width: 70,
                  },
                  { heading: 'Имя', body: (record) => record.name, width: 200 },
                  {
                    heading: (
                      <Tooltip label="Свободный баланс">
                        <div>СБ</div>
                      </Tooltip>
                    ),
                    body: (record) => toMoney({ currency: 'usdt', amount: record.balanceUsdtFree }),
                    width: 90,
                  },
                  {
                    heading: (
                      <Tooltip label="Замороженный баланс">
                        <div>ЗБ</div>
                      </Tooltip>
                    ),
                    body: (record) => toMoney({ currency: 'usdt', amount: record.balanceUsdtHolded }),
                    width: 90,
                  },
                  {
                    heading: (
                      <Tooltip label="Комиссия с депозитов">
                        <div>% КД</div>
                      </Tooltip>
                    ),
                    body: (record) => integerWithDecimalsToAmountString({ amount: record.customerDepositFeeShare }),
                    width: 90,
                  },
                  {
                    heading: (
                      <Tooltip label="Комиссия с виздравов">
                        <div>% КВ</div>
                      </Tooltip>
                    ),
                    body: (record) => integerWithDecimalsToAmountString({ amount: record.customerWithdrawFeeShare }),
                    width: 90,
                  },
                  {
                    heading: '',
                    body: (record) => (
                      <Block frnw g={10}>
                        <Link href={adminMerchantEditRoute.get({ merchantSerialNumber: record.serialNumber })}>
                          Редактировать
                        </Link>
                        <Link href={adminMerchantMemberListRoute.get({ merchantSerialNumber: record.serialNumber })}>
                          Сотрудники
                        </Link>
                      </Block>
                    ),
                    width: 250,
                  },
                ]}
              />
            )
          }}
        </SectionWrapper>
      </Segment>
    </Block>
  )
})
