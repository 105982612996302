import { sounds } from './sounds/index.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { toast } from 'react-hot-toast'

export const Notifications = () => {
  trpc.onNotification.useSubscription(
    {},
    {
      onData(notification) {
        toast(notification.message)

        const audioUrl = (sounds as Record<string, string | undefined>)[notification.sound]
        if (audioUrl) {
          const audio = new Audio(audioUrl)
          void audio.play()
        }
      },
    }
  )

  return null
}
