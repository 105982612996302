import { pageData } from './pageData.js'
import { integerWithDecimalsToAmountString, toMoney } from '@/general/src/other/money.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminTraderEditRoute, adminTraderNewRoute } from '@/webapp/src/lib/routes.js'
import { SectionWrapper } from '@/webapp/src/lib/sectionWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, Segment, Table } from '@/webapp/src/lib/uninty.components.js'
import { Tooltip } from '@mantine/core'

const breadcrumbs = [{ title: 'Трейдеры' }]

export const AdminTraderListPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
})(() => {
  return (
    <Block fcnw>
      <Breadcrumbs items={breadcrumbs} />
      <Segment
        title="Трейдеры"
        size="m"
        desc={
          <Buttons>
            <Button as="a" className="buttonA" href={adminTraderNewRoute.get()}>
              Добавить трейдера
            </Button>
          </Buttons>
        }
      >
        <SectionWrapper
          useQuery={() => trpc.getTradersForAdmin.useQuery({})}
          setProps={({ queryResult }) => ({
            traders: queryResult.data.traders,
          })}
        >
          {({ traders }) => {
            return (
              <Table
                className="unintyTable"
                records={traders}
                href={(record) => adminTraderEditRoute.get({ traderSerialNumber: record.serialNumber })}
                columns={[
                  {
                    heading: (
                      <Tooltip label="Серийный номер трейдера" position="right">
                        <div>SN</div>
                      </Tooltip>
                    ),
                    body: (record) => record.serialNumber,
                    width: 70,
                  },
                  { heading: 'Имя', body: (record) => record.name, width: 200 },
                  { heading: 'Почта', body: (record) => record.email, width: 200 },
                  {
                    heading: 'Онлайн',
                    body: (record) => {
                      return record.online ? '🟢' : '🔴'
                    },
                    width: 90,
                  },
                  {
                    heading: 'Автоматор',
                    body: (record) => {
                      return record.automatorOnline ? '🟢' : '🔴'
                    },
                    width: 110,
                  },
                  { heading: 'Телефон', body: (record) => record.phone, width: 200 },
                  {
                    heading: (
                      <Tooltip label="Свободный баланс">
                        <div>СБ</div>
                      </Tooltip>
                    ),
                    body: (record) => toMoney({ currency: 'usdt', amount: record.balanceUsdtFree }),
                    width: 90,
                  },
                  {
                    heading: (
                      <Tooltip label="Замороженный баланс">
                        <div>ЗБ</div>
                      </Tooltip>
                    ),
                    body: (record) => toMoney({ currency: 'usdt', amount: record.balanceUsdtHolded }),
                    width: 90,
                  },
                  {
                    heading: (
                      <Tooltip label="Свободный страховой баланс">
                        <div>ССБ</div>
                      </Tooltip>
                    ),
                    body: (record) => toMoney({ currency: 'usdt', amount: record.balanceUsdtInsuranceFree }),
                    width: 90,
                  },
                  {
                    heading: (
                      <Tooltip label="Замороженный страховой баланс">
                        <div>ЗСБ</div>
                      </Tooltip>
                    ),
                    body: (record) => toMoney({ currency: 'usdt', amount: record.balanceUsdtInsuranceHolded }),
                    width: 90,
                  },
                  {
                    heading: (
                      <Tooltip label="Комиссия с депозитов">
                        <div>% КД</div>
                      </Tooltip>
                    ),
                    body: (record) =>
                      integerWithDecimalsToAmountString({ amount: record.customerDepositInterestShare }),
                    width: 90,
                  },
                  {
                    heading: (
                      <Tooltip label="Комиссия с виздравов">
                        <div>% КВ</div>
                      </Tooltip>
                    ),
                    body: (record) =>
                      integerWithDecimalsToAmountString({ amount: record.customerWithdrawInterestShare }),
                    width: 90,
                  },
                ]}
              />
            )
          }}
        </SectionWrapper>
      </Segment>
    </Block>
  )
})
