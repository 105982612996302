import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { ApiDocsSection } from '@/general/src/api/docs/ApiDocsSection.js'
import { errors } from '@/general/src/api/routes/startDisputeForCustomerDepositV1/errors.js'

export const ApiDocsH2HStartDisputeForCustomerDeposit = ({
  merchant,
}: {
  merchant?: TrpcRouterOutput['getMyMerchant']['merchant'] | null
}) => {
  return (
    <ApiDocsSection
      merchant={merchant}
      title="Создание спора по клиентскому депозиту"
      desc={
        <span>
          Создание спора по клиентскому депозиту, который был завершён без подтверждения трейдером оплаты клиента. Чтобы
          получить статус диспута, используйте <code>GET /api/get-customer-deposit/v1</code>
        </span>
      }
      method="POST"
      path="/api/h2h/start-dispute-for-customer-deposit/v1"
      hashParams={['body:customerDepositId', 'body:message']}
      bodyParams={[
        {
          key: 'customerDepositId',
          title: 'customerDepositId*',
          desc: 'Обязательное поле - ID клиентского депозита. Депозит должен быть в статусе verifying, то есть создать спор можно только в том случае, если трейдер не подтвердил оплату, либо если клиент не подтвердил оплату со своей стороны и сделка отменилась',
          defaultValue: '',
        },
        {
          key: 'message',
          title: 'message',
          desc: 'Сообщение с причиной создания спора',
        },
      ]}
      responseExample={{
        customerDepositId: '0194ea88-9a94-700d-b532-1cd7c50e4caa',
        status: 'disputing',
        message: 'Долго не подтвержается перевод',
      }}
      errorExamples={[errors.notFound(), errors.wrongDepositStatus()]}
    />
  )
}
