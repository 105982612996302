import { pageData } from './pageData.js'
import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { ActionLogs } from '@/general/src/actionLog/components/ActionLogs/index.js'
import { Chat } from '@/general/src/chat/components/Chat/index.js'
import { zDisputeMakeMerchantDepositForAdminEndpointInput } from '@/general/src/merchantDeposit/routes/disputeMakeMerchantDepositForAdmin/input.js'
import { zVerifyMerchantDepositForAdminEndpointInput } from '@/general/src/merchantDeposit/routes/verifyMerchantDepositForAdmin/input.js'
import {
  toHumanMerchantDepositCancellationReason,
  toHumanMerchantDepositStatus,
} from '@/general/src/merchantDeposit/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import { PaymentMethodCard } from '@/general/src/paymentMethod/PaymentMethod.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { Txs } from '@/general/src/tx/components/Txs/index.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminMerchantDepositViewRoute } from '@/webapp/src/lib/routes.js'
import * as routes from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, LabeledValues, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { differenceInMinutes, formatDate } from 'date-fns'
import { useEffect, useState } from 'react'

const Verify = ({
  merchantDeposit,
}: {
  merchantDeposit: TrpcRouterOutput['getMerchantDepositForAdmin']['merchantDeposit']
}) => {
  const verifyMerchantDepositForAdmin = trpc.verifyMerchantDepositForAdmin.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      merchantDepositId: merchantDeposit.id,
    },
    validationSchema: zVerifyMerchantDepositForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { merchantDeposit } = await verifyMerchantDepositForAdmin.mutateAsync(valuesInput)
      trpcUtils.getMerchantDepositForAdmin.setData(
        { merchantDepositSerialNumber: merchantDeposit.serialNumber },
        { merchantDeposit }
      )
    },
    successMessage: 'Мерчантский депозит успешно завершён',
    resetOnSuccess: false,
  })
  if (!merchantDeposit.companyPaymentMethodData) {
    return <p>Нет платёжных методов, обратитесь в администрацию</p>
  }
  return (
    <FormItems as="form" {...formy.formProps}>
      <PaymentMethodCard
        paymentMethod={merchantDeposit.companyPaymentMethodData}
        garantexCode={merchantDeposit.garantexCode}
      />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Деньги получены
        </Button>
      </Buttons>
    </FormItems>
  )
}

const DisputeMake = ({
  merchantDeposit,
}: {
  merchantDeposit: TrpcRouterOutput['getMerchantDepositForAdmin']['merchantDeposit']
}) => {
  const disputeMakeMerchantDepositForAdmin = trpc.disputeMakeMerchantDepositForAdmin.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      merchantDepositId: merchantDeposit.id,
      message: '',
    },
    validationSchema: zDisputeMakeMerchantDepositForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { merchantDeposit } = await disputeMakeMerchantDepositForAdmin.mutateAsync(valuesInput)
      trpcUtils.getMerchantDepositForAdmin.setData(
        { merchantDepositSerialNumber: merchantDeposit.serialNumber },
        { merchantDeposit }
      )
    },
    successMessage: 'Мерчантский депозит успешно отменён через спор',
    resetOnSuccess: false,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Textfieldy {...formy.getFieldProps('message')} label="Причина спора" />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Отменить
        </Button>
      </Buttons>
    </FormItems>
  )
}

export const AdminMerchantDepositViewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(adminMerchantDepositViewRoute)
    const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
    const queryResult = trpc.getMerchantDepositForAdmin.useQuery(
      {
        merchantDepositSerialNumber: +routeParams.merchantDepositSerialNumber,
      },
      {
        refetchInterval,
      }
    )
    const isActive = queryResult.data?.merchantDeposit.isActive
    useEffect(() => {
      if (isActive) {
        setRefetchInterval(3_000)
      } else {
        setRefetchInterval(false)
      }
    }, [isActive])
    return queryResult
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    merchantDeposit: queryResult.data.merchantDeposit,
  }),
})(({ merchantDeposit }) => {
  const breadcrumbs = [
    { title: 'Мерчантские депозиты', href: routes.adminMerchantDepositListRoute.get() },
    { title: `Депозит #${merchantDeposit.serialNumber}` },
  ]

  return (
    <Block fcnw g={30}>
      <Breadcrumbs items={breadcrumbs} />
      <Segment title={`Мерчантский депозит #${merchantDeposit.serialNumber}`} size="m">
        <Block fcnw g={30}>
          <LabeledValues
            $style={{
              labelColor: 'var(--mantine-color-text)',
              hintColor: 'var(--mantine-color-text)',
            }}
            valuesEmptyPolicy="hide"
            items={[
              ['Статус', toHumanMerchantDepositStatus(merchantDeposit.status)],
              ['Сумма в USDT', toMoney({ amount: merchantDeposit.amountUsdt, currency: 'usdt' })],
              [
                'Платёжный метод',
                merchantDeposit.paymentMethodType && toHumanPaymentMethodType(merchantDeposit.paymentMethodType),
              ],
              [
                'Номер кошелька',
                merchantDeposit.companyPaymentMethodData?.group === 'crypto'
                  ? merchantDeposit.companyPaymentMethodData.wallet
                  : null,
              ],
              ['Код гарантекс', merchantDeposit.garantexCode],
              ['Админ', merchantDeposit.admin?.serialNumber && `#${merchantDeposit.admin.serialNumber}`],
              ['Мерчант', `#${merchantDeposit.merchant.serialNumber}`],
              ['Мерчант мембер', `#${merchantDeposit.merchantMember.serialNumber}`],
              ['Создание', formatDate(merchantDeposit.createdAt, 'dd.MM.yyyy HH:mm')],
              ['Взят в работу', merchantDeposit.pickedAt && formatDate(merchantDeposit.pickedAt, 'dd.MM.yyyy HH:mm')],
              ['Оплачено', merchantDeposit.declaredAt && formatDate(merchantDeposit.declaredAt, 'dd.MM.yyyy HH:mm')],
              ['Проверено', merchantDeposit.completedAt && formatDate(merchantDeposit.completedAt, 'dd.MM.yyyy HH:mm')],
              ['Отменено', merchantDeposit.cancelledAt && formatDate(merchantDeposit.cancelledAt, 'dd.MM.yyyy HH:mm')],
              [
                'Начало спора',
                merchantDeposit.disputeStartedAt && formatDate(merchantDeposit.disputeStartedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Завершение спора',
                merchantDeposit.disputeCompletedAt &&
                  formatDate(merchantDeposit.disputeCompletedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Отмена спора',
                merchantDeposit.disputeCancelledAt &&
                  formatDate(merchantDeposit.disputeCancelledAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Длительность в минутах',
                merchantDeposit.finishedAt &&
                  differenceInMinutes(merchantDeposit.finishedAt, merchantDeposit.createdAt),
              ],
            ]}
          />
          {merchantDeposit.status === 'picking' ? (
            <Segment
              title="Ваша задача"
              size="m"
              desc="Перейти на админский дашборд и взяться за этот мерчантский депозит"
            />
          ) : merchantDeposit.status === 'processing' ? (
            <Segment title="Ваша задача" size="m" desc="Дождаться пока мерчант сделает перевод" />
          ) : merchantDeposit.status === 'verifying' ? (
            <>
              <Segment
                title="Ваша задача"
                size="m"
                desc="Проверить, что деньги от мерчанта пришли и подтвердить получение"
              >
                <Verify merchantDeposit={merchantDeposit} />
              </Segment>
              <Segment
                title="Отмена через спор"
                size="m"
                desc="Если деньги не пришли, и вы не смогли договориться в чате, тогда отменяйте сделку"
              >
                <DisputeMake merchantDeposit={merchantDeposit} />
              </Segment>
            </>
          ) : merchantDeposit.status === 'completed' ? (
            <Segment title="Сделка успешно завершена" size="m" />
          ) : merchantDeposit.status === 'cancelled' ? (
            <Segment
              title="Отмена через спор"
              size="m"
              desc={toHumanMerchantDepositCancellationReason(
                merchantDeposit.cancellationCode,
                merchantDeposit.cancellationMessage
              )}
            />
          ) : null}
          <Chat chatId={merchantDeposit.chatId} viewerType="admin" />
          <Segment title="Связанные транзакции" size="m">
            <Txs viewerType="admin" dealId={merchantDeposit.id} />
          </Segment>
          <Segment title="Связанные логи" size="m">
            <ActionLogs dealId={merchantDeposit.id} />
          </Segment>
        </Block>
      </Segment>
    </Block>
  )
})
