import type { AdminPermission as PrismaAdminPermission } from '@/backend/src/services/other/prisma.js'
import type { IsEqual } from 'type-fest'
import { z } from 'zod'

export const adminPermissions = ['createAdmins'] as const
export const zAdminPermission = z.enum(adminPermissions)
export type AdminPermission = z.output<typeof zAdminPermission>
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const check1: IsEqual<AdminPermission, PrismaAdminPermission> = true
export const toHumanAdminPermission = (permission: AdminPermission) => {
  return (
    {
      createAdmins: 'Создание админов',
    }[permission] || 'Неизвестное право'
  )
}
export const adminPermissionsOptions = adminPermissions.map((permission) => ({
  value: permission,
  label: toHumanAdminPermission(permission),
}))

type MaybeAdmin = {
  permissions: AdminPermission[]
} | null
// type MaybeTrader = {} | null
type MaybeMerchantMember = {} | null
// type MaybeMe = {
//   admin: MaybeAdmin
//   trader: MaybeTrader
//   merchantMember: MaybeMerchantMember
// } | null

export type SimpleCanAsAdminFn = (a: MaybeAdmin) => boolean
export type SimpleCanAsMerchantMember = (mm: MaybeMerchantMember) => boolean

export const hasPermissionAsAdmin = (a: MaybeAdmin, permission: AdminPermission) => {
  if (!a) {
    return false
  }
  return a.permissions.includes(permission)
}
// export const hasPermissionAsMerchantMember = (mm: MaybeMerchantMember, permission: MerchantMemberPermission) => {
// if (!a) {
//   return false
// }
// return a.permissions.includes(permission)
// }

const createSimpleCanAsAdminFn = (permission: AdminPermission): SimpleCanAsAdminFn => {
  return (a) => {
    return hasPermissionAsAdmin(a, permission)
  }
}

// const createSimpleCanAsMerchantMemberFn = (permission: MerchantMemberPermission): SimpleCanAsMerchantMember => {
//   return (mm) => {
//     return hasPermissionAsMerchantMember(mm, permission)
//   }
// }

// admin

export const canCreateAdmins = createSimpleCanAsAdminFn('createAdmins')

// merchantMember
