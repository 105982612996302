import { traderAutomatorPushRules } from './automator-push-settings.js'
import { traderAutomatorSmsRules } from './automator-sms-settings.js'
import type {
  AutomatorRule,
  AutomatorRuleType,
  TraderAutomatorLogStatus as PrismaTraderAutomatorLogStatus,
} from '@/backend/src/services/other/prisma.js'
import type { IsEqual } from 'type-fest'
import { z } from 'zod'

export const traderAutomatorLogStatuses = [
  'sourceNotMatched',
  'inputNotMatched',
  'amountNotMatched',
  'customerDepositNotFound',
  'customerDepositMoreThanOne',
  'applyingError',
  'success',
  'successAfterDeclare',
  'customerDepositNotActivePaymentMethod',
] as const satisfies PrismaTraderAutomatorLogStatus[]
export const zTraderAutomatorLogStatus = z.enum(traderAutomatorLogStatuses)
export type TraderAutomatorLogStatus = (typeof traderAutomatorLogStatuses)[number]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const check1: IsEqual<TraderAutomatorLogStatus, PrismaTraderAutomatorLogStatus> = true

export const toHumanTraderAutomatorLogStatus = (status: TraderAutomatorLogStatus) => {
  return {
    sourceNotMatched: 'Источник уведомления не подходит',
    inputNotMatched: 'Входные данные не подходят',
    amountNotMatched: 'Не удалось распарсить сумму перевода',
    customerDepositNotFound: 'Подходящий клиентский депозит не найден',
    customerDepositNotActivePaymentMethod:
      'Клиентский депозит не содержит активных методов оплаты. Вероятно, нужно активировать платёжный метод в приложении',
    customerDepositMoreThanOne: 'Найдено более одного подходящего клиентского депозита',
    applyingError: 'Ошибка во время попытки подтвердить клиентский депозит',
    success: 'Клиентский депозит подтверждён успешно',
    successAfterDeclare: 'Клиентский депозит подтверждён успешно после объявления',
  }[status]
}

export const isTextSuitableToRegex = ({ text, regex }: { text: string; regex: string }) => {
  return new RegExp(regex).test(text)
}

export const getAmountFromNotificationText = ({ text, amountPattern }: { text: string; amountPattern: string }) => {
  const amountString = new RegExp(amountPattern).exec(text)?.[1]
  const amountFloat = parseFloat(amountString?.replace(',', '.').replace(/[^0-9.]/g, '') || '0')
  const amountFloatX100 = isNaN(amountFloat) ? 0 : Math.round(amountFloat * 100)
  const amountBigintWithDecimals = BigInt(amountFloatX100)

  return amountBigintWithDecimals
}

export const getNameFromNotificationText = ({ text, namePattern }: { text: string; namePattern?: string }) => {
  const nameString = namePattern ? new RegExp(namePattern).exec(text)?.[1] : ''
  // eslint-disable-next-line regexp/no-obscure-range
  const nameLettersOnly = (nameString?.replace(/[^a-zA-Zа-яА-Я]/g, '') || '').toLowerCase()
  return nameLettersOnly
}

const moneyPattern = '[0-9][\\s0-9]*([.,][0-9]{1,2})?'
const formatPattern = (pattern: string): string => {
  return pattern.replaceAll('{money}', moneyPattern)
}

export interface SettingMatchMatched {
  status: 'matched'
  rule: AutomatorRule
  amount: bigint
}

export type SettingMatch =
  | SettingMatchMatched
  | { status: 'sourceNotMatched' | 'inputNotMatched' | 'amountNotMatched' }
  | { status: 'invalidConfiguration'; error: unknown }

export type SettingMatchResult =
  | SettingMatch
  | { status: 'multipleMatches'; matches: SettingMatchMatched[] }
  | { status: 'unknown' }

export const getFullMatchingResult = (settings: SettingMatch[]): SettingMatchResult => {
  const matches = settings.filter((s) => s.status === 'matched')

  if (matches.length === 1) {
    return matches[0]
  }

  if (matches.length > 1) {
    return {
      status: 'multipleMatches',
      matches,
    }
  }

  return (
    // Choose the most specific error
    // If we was able to parse source and input (text) but not transaction amount
    // then log error about not being able to parse transaction amount.
    // At the same time we will have a lot of settings with sourceNotMatched status
    settings.find((s) => s.status === 'invalidConfiguration') ??
    settings.find((s) => s.status === 'amountNotMatched') ??
    settings.find((s) => s.status === 'inputNotMatched') ??
    settings.find((s) => s.status === 'sourceNotMatched') ?? { status: 'unknown' }
  )
}

export const findAutomatorMatchingRules = (opts: {
  rules: AutomatorRule[]
  type: AutomatorRuleType
  sender?: string | null
  packageName?: string | null
  text?: string | null
}): SettingMatch[] => {
  return opts.rules.map((rule) => {
    try {
      if (rule.type !== opts.type) {
        throw new Error('Cannot compare with rule of different type')
      }

      if (rule.type === 'push') {
        if (!opts.packageName) {
          throw new Error('packageName is required for rules with type=push')
        }
        if (!rule.packageNames.length) {
          throw new Error('packageNames are required for rules with type=push')
        }

        const suitablePushSettingsByPackageName = opts.rules.filter(
          (rule) => opts.packageName && rule.packageNames.includes(opts.packageName)
        )

        if (!suitablePushSettingsByPackageName.length) {
          return { status: 'sourceNotMatched' }
        }
      }

      if (rule.type === 'sms') {
        if (!rule.senderPattern) {
          throw new Error('senderPattern is required for rules with type=sms')
        }

        if (!isTextSuitableToRegex({ text: opts.sender ?? '', regex: formatPattern(rule.senderPattern) })) {
          return { status: 'sourceNotMatched' }
        }
      }

      if (
        !isTextSuitableToRegex({
          text: opts.text ?? '',
          regex: formatPattern(rule.checkPattern || rule.amountPattern),
        })
      ) {
        return { status: 'inputNotMatched' }
      }

      const amount = getAmountFromNotificationText({
        text: opts.text ?? '',
        amountPattern: formatPattern(rule.amountPattern),
      })

      if (!amount) {
        return { status: 'amountNotMatched' }
      }

      return { status: 'matched', rule, amount }
    } catch (error) {
      return { status: 'invalidConfiguration', error }
    }
  })
}

export const defaultAutomatorRules: AutomatorRule[] = [...traderAutomatorPushRules, ...traderAutomatorSmsRules].map(
  (rule) => ({
    id: crypto.randomUUID(),
    packageNames: [],
    ...rule,
  })
)
