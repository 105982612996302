import { toWritableDeep } from '../other/type-helpers.js'

export const pushSettings = [
  {
    textExample: 'Вам пришли деньги как-то 1234.56 от С. Бом',
    packageNames: ['ru.sberbankmobile'],
    checkPattern: '^Вам пришли деньги как-то',
    amountPattern: '^Вам пришли деньги как-то ({money})',
    namePattern: '^Вам пришли деньги как-то {money} от (.+)$',
    paymentMethodTypes: ['sberbankCard', 'sberbankSbp'],
    paymentMethodProvider: 'sberbank',
  },
  {
    textExample: 'Вам пришли деньги на карту 1234.56 от С. Бом',
    packageNames: ['ru.sberbankmobile'],
    checkPattern: '^Вам пришли деньги на карту',
    amountPattern: '^Вам пришли деньги на карту ({money})',
    namePattern: '^Вам пришли деньги на карту {money} от (.+)$',
    paymentMethodTypes: ['sberbankCard'],
    paymentMethodProvider: 'sberbank',
  },
  {
    textExample: 'Вам пришли деньги по СБП 1234.56 от С. Бом',
    packageNames: ['ru.sberbankmobile'],
    checkPattern: '^Вам пришли деньги по СБП',
    amountPattern: '^Вам пришли деньги по СБП ({money})',
    namePattern: '^Вам пришли деньги по СБП {money} от (.+)$',
    paymentMethodTypes: ['sberbankSbp'],
    paymentMethodProvider: 'sberbank',
  },
  // Sberbank PUSH
  {
    textExample: 'Перевод от С. Бом. + 1 234,56 ₽ - Баланс: 1 234,56 ₽ СЧЁТ •• 0269',
    packageNames: ['ru.sberbankmobile'],
    checkPattern: '^Перевод от',
    amountPattern: '\\+ ({money}) ₽',
    namePattern: '^Перевод от ([^+]+)',
    paymentMethodTypes: ['sberbankCard', 'sberbankSbp'],
    paymentMethodProvider: 'sberbank',
  },
  {
    textExample: 'СЧЁТ1234 16:47 Перевод 1234.56р от Сбо М. Баланс: 9501.80р',
    packageNames: ['ru.sberbankmobile'],
    checkPattern: 'Перевод {money} ?р? ?от',
    amountPattern: 'Перевод ({money})р',
    namePattern: 'от ([^\\.]+)',
    paymentMethodTypes: ['sberbankCard', 'sberbankSbp'],
    paymentMethodProvider: 'sberbank',
  },
  {
    textExample: 'MIR-1234 21:33 Перевод 1234.56р от Сбо М. Баланс: 900р',
    packageNames: ['ru.sberbankmobile'],
    checkPattern: 'Перевод {money} ?р?',
    amountPattern: 'Перевод ({money}) ?р?',
    namePattern: 'от ([А-ЯЁа-яёA-Za-z]+ [А-ЯЁA-Z]\\.)',
    paymentMethodTypes: ['sberbankCard', 'sberbankSbp'],
    paymentMethodProvider: 'sberbank',
  },
  {
    textExample: 'СЧЁТ2127 12:38 перевод 1234.56р Баланс: 4017.11р',
    packageNames: ['ru.sberbankmobile'],
    checkPattern: 'перевод {money} ?р?',
    amountPattern: 'перевод ({money})р',
    namePattern: '',
    paymentMethodTypes: ['sberbankCard', 'sberbankSbp'],
    paymentMethodProvider: 'sberbank',
  },
  {
    textExample: 'Сбер.счёт *1331 Зачисление 1234.56р Баланс: 11 980р',
    packageNames: ['ru.sberbankmobile'],
    checkPattern: 'Зачисление {money} ?р?',
    amountPattern: 'Зачисление ({money}) ?р?',
    namePattern: '',
    paymentMethodTypes: ['sberbankCard', 'sberbankSbp'],
    paymentMethodProvider: 'sberbank',
  },
  {
    textExample: 'СЧЁТ9973 00:47 зачисление 1234.56р со вклада Баланс: 10.96р',
    packageNames: ['ru.sberbankmobile'],
    checkPattern: 'зачисление {money} ?р?',
    amountPattern: 'зачисление ({money}) ?р?',
    namePattern: '',
    paymentMethodTypes: ['sberbankCard', 'sberbankSbp'],
    paymentMethodProvider: 'sberbank',
  },
  // Alfa-Bank
  {
    textExample: '+1234.56 RUR. Поступление Перевод на сумму 1234.56 RUR из ВТБ от Сбо М. по СБП.',
    packageNames: ['ru.alfabank.mobile.android', 'ru.alfabank.mobile.android.huawei'],
    checkPattern: 'Поступление Перевод на сумму {money} RUR',
    amountPattern: 'на сумму ({money}) RUR',
    namePattern: 'от (.+?) по СБП',
    paymentMethodTypes: ['alfabankSbp'],
    paymentMethodProvider: 'alfabank',
  },
  {
    textExample: '+1234.56 ₽. Поступление',
    packageNames: ['ru.alfabank.mobile.android', 'ru.alfabank.mobile.android.huawei'],
    checkPattern: '\\+{money} ?₽?\\. ?Поступление',
    amountPattern: '\\+({money}) ?₽?',
    namePattern: '',
    paymentMethodTypes: ['alfabankSbp', 'alfabankCard'],
    paymentMethodProvider: 'alfabank',
  },
  {
    textExample: 'Перевод на сумму 1234.56 RUR из Т-Банк от СБО М. по СБП.',
    packageNames: ['ru.alfabank.mobile.android', 'ru.alfabank.mobile.android.huawei'],
    checkPattern: 'Перевод на сумму {money} ?RUR',
    amountPattern: 'Перевод на сумму ({money}) ?RUR',
    namePattern: 'от ([А-ЯЁа-яёA-Za-z]+ [А-ЯЁA-Z]\\.) по СБП',
    paymentMethodTypes: ['alfabankSbp'],
    paymentMethodProvider: 'alfabank',
  },

  {
    textExample: '+1234,56 ₽. Поступление Остаток: 199,52 ₽; ··9403',
    packageNames: ['ru.alfabank.mobile.android', 'ru.alfabank.mobile.android.huawei'],
    checkPattern: '\\+{money} ?₽?\\. ?Поступление',
    amountPattern: '\\+({money}) ?₽?',
    namePattern: '',
    paymentMethodTypes: ['alfabankCard'],
    paymentMethodProvider: 'alfabank',
  },
  {
    textExample: '+1234.56 ₽. Поступление\nПеревод на сумму 1234.56 RUR из Т-Банк от СБО М. по СБП.',
    packageNames: ['ru.alfabank.mobile.android', 'ru.alfabank.mobile.android.huawei'],
    checkPattern: '(?:\\+?{money} ?₽?.*\\bПоступление\\b|Перевод на сумму {money} ?RUR)',
    amountPattern: '(?:\\+|Перевод на сумму )({money}) ?(?:₽|RUR)?',
    namePattern: 'от ([А-ЯЁа-яёA-Za-z]+ [А-ЯЁA-Z]\\.) по СБП',
    paymentMethodTypes: ['alfabankSbp'],
    paymentMethodProvider: 'alfabank',
  },
  // Raiffaizenbank
  {
    textExample: '+ 1234.56 ₽ от +79645901694, СБ О М. Теперь на счете 11.48 ₽',
    packageNames: ['ru.raiffeisennews'],
    checkPattern: `\\+ ({money}) ₽ (и сообщение «.+»)? от`,
    amountPattern: `\\+ ({money}) ₽`,
    namePattern: '\\+\\d+, (.+?)\\. Теперь',
    paymentMethodTypes: ['raiffaizenbankCard', 'raiffaizenbankSbp'],
    paymentMethodProvider: 'raiffaizenbank',
  },
  // Sovkombank
  {
    textExample: '*6789 пополнение через СБП 1234.56 р. от СБ О М.',
    packageNames: ['ru.sovcomcard.halva.v1'],
    checkPattern: '\\*\\d+ пополнение через СБП {money} р',
    amountPattern: 'пополнение через СБП ({money}) р',
    namePattern: 'от (.+?)\\.$',
    paymentMethodTypes: ['sovkombankSbp'],
    paymentMethodProvider: 'sovkombank',
  },
  // Yandex Pay
  {
    textExample: 'Пополнение на 1234.56 RUB. СБ О М. 31.10.2024 21:25. Доступно 81220.00 RUB',
    packageNames: ['com.yandex.bank'],
    checkPattern: 'Пополнение на {money} RUB',
    amountPattern: 'Пополнение на ({money}) RUB',
    namePattern: 'RUB\\. (.+?)\\. \\d{2}\\.\\d{2}\\.\\d{4}',
    paymentMethodTypes: ['yandeksbankSbp'],
    paymentMethodProvider: 'yandeksbank',
  },
  {
    textExample: 'Пополнение на 1234.56 RUB. 31.10.2024 21:28. Доступно 81130.00 RUB',
    packageNames: ['com.yandex.bank'],
    checkPattern: 'Пополнение на {money} RUB',
    amountPattern: 'Пополнение на ({money}) RUB',
    namePattern: '',
    paymentMethodTypes: ['yandeksbankSbp'],
    paymentMethodProvider: 'yandeksbank',
  },
  // Akbars
  {
    textExample: 'Пополнение через СБП. Карта *** 4081, Сумма: 1234.56 RUR.',
    packageNames: ['ru.akbars.mobile'],
    checkPattern: 'Пополнение через СБП. Карта \\*\\*\\* \\d{4}, Сумма: {money} RUR.',
    amountPattern: 'Пополнение через СБП. Карта \\*\\*\\* \\d{4}, Сумма: ({money}) RUR.',
    namePattern: '',
    paymentMethodTypes: ['akbarsSbp'],
    paymentMethodProvider: 'akbars',
  },
  // Ozon
  {
    textExample: 'Пополнение через СБП на 1 234.56 ₽. Вячеслав Олегович Р. Сбербанк. Баланс 6 162.03 ₽',
    packageNames: ['ru.ozon.fintech.finance'],
    checkPattern: `Пополнение через СБП на {money} ₽.`,
    amountPattern: `Пополнение через СБП на ({money}) ₽.`,
    namePattern: '',
    paymentMethodTypes: ['ozonbankSbp'],
    paymentMethodProvider: 'ozonbank',
  },
  {
    textExample: 'Пополнение карты *5163 на 1234.56 ₽. Тинькофф. Доступно: 36601.03 ₽',
    packageNames: ['ru.ozon.fintech.finance'],
    checkPattern: `Пополнение карты \\*\\d{4} на {money} ₽.`,
    amountPattern: `Пополнение карты \\*\\d{4} на ({money}) ₽.`,
    namePattern: '',
    paymentMethodTypes: ['ozonbankCard'],
    paymentMethodProvider: 'ozonbank',
  },
  // OTP
  {
    textExample: '2025.03.04 07:01:03. Счет *3665. Зачислен перевод СБП 1234.56RUB. из МТС-Банк от Никита Андреевич П.',
    packageNames: ['ru.otpbank.mobile'],
    amountPattern: `Зачислен перевод СБП ({money})RUB.`,
    namePattern: '',
    paymentMethodTypes: ['otpbankSbp'],
    paymentMethodProvider: 'otpbank',
  },
  {
    textExample: '04.03 10:04. Карта *1108 зачисление на сумму 1234.56р. в SBOL. Доступно 13572.02р.',
    packageNames: ['ru.otpbank.mobile'],
    amountPattern: `зачисление на сумму ({money})р.`,
    namePattern: '',
    paymentMethodTypes: ['otpbankCard'],
    paymentMethodProvider: 'otpbank',
  },
  // PSB
  {
    textExample: '*4290 Пополнение 1234,56p AK BARS online Доступно 2410,27p',
    packageNames: ['logo.com.mbanking'],
    checkPattern: '\\*\\d{4} Пополнение {money}p',
    amountPattern: '\\*\\d{4} Пополнение ({money})p',
    namePattern: '',
    paymentMethodTypes: ['psbCard', 'psbSbp'],
    paymentMethodProvider: 'psb',
  },
  {
    textExample: 'Поступление денежных средств на 220003..4290 в размере 1234.56 руб.',
    packageNames: ['logo.com.mbanking'],
    checkPattern: 'Поступление денежных средств на \\d{6}..\\d{4} в размере {money} руб.',
    amountPattern: 'Поступление денежных средств на \\d{6}..\\d{4} в размере ({money}) руб.',
    namePattern: '',
    paymentMethodTypes: ['psbCard'],
    paymentMethodProvider: 'psb',
  },
  // Цифра банк
  {
    textExample:
      'СБП. Получен перевод 25.02.2025 19:20 (мск). Счет 2964. Зачислено 1234.56 RUB. от Сб О М Баланс 30.00 RUB 0.00 USD 0.00 EUR',
    packageNames: ['com.bankffin.portfolio'],
    checkPattern: 'Зачислено {money} ?RUB?\\.? ?от',
    amountPattern: 'Зачислено ({money}) ?RUB',
    namePattern: 'от ([^\\d]+?) (?:В|Баланс|$)',
    paymentMethodTypes: ['tsifrabankSbp'],
    paymentMethodProvider: 'tsifrabank',
  },
  {
    textExample:
      'Карта 4211:26.02.2025 14:42, пополнение 1234.56 RUB, T-Bank Card2Card RUS MOSCOW.  Баланс 3885.00 RUB 0.00 USD 0.00 EUR',
    packageNames: ['com.bankffin.portfolio'],
    checkPattern: 'пополнение {money} ?RUB',
    amountPattern: 'пополнение ({money}) ?RUB',
    namePattern: '',
    paymentMethodTypes: ['tsifrabankCard'],
    paymentMethodProvider: 'tsifrabank',
  },
  // Уралсиб
  {
    textExample:
      'Perevod SBP ot С Бо М. iz Sberbank of Russia. Summa 1234.56 RUR na schet *6058. Ispolnen 26.02.2025 16:08',
    packageNames: ['ru.bankuralsib.mb.android'],
    checkPattern: 'Perevod SBP ot [^.]+\\. ',
    amountPattern: 'Summa ({money}) ?RUR',
    namePattern: 'Perevod SBP ot ([^\\.]+)\\.',
    paymentMethodTypes: ['uralsibbankSbp'],
    paymentMethodProvider: 'uralsibbank',
  },
] as const

export const traderAutomatorPushRules = toWritableDeep(
  pushSettings.map((setting) => ({
    type: 'push' as const,
    checkPattern: setting.amountPattern,
    senderPattern: null,
    ...setting,
  }))
)
