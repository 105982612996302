import { pageData } from './pageData.js'
import { zCreateMerchantWithdrawForMerchantMemberEndpointInput } from '@/general/src/merchantWithdraw/routes/createMerchantWithdrawForMerchantMember/input.js'
import { PaymentMethodCardsPicker } from '@/general/src/paymentMethod/PaymentMethod.js'
import { filterPaymentMethodsByFamily } from '@/general/src/paymentMethod/utils.shared.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { merchantMerchantWithdrawListRoute, merchantMerchantWithdrawViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useNavigate } from 'react-router-dom'

export const MerchantMerchantWithdrawNewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedMerchantMembersOnly: true,
  useQuery: () => {
    return trpc.getMyMerchant.useQuery()
  },
  setProps: ({ queryResult }) => {
    return {
      merchant: queryResult.data.merchant,
    }
  },
})(({ merchant }) => {
  const navigate = useNavigate()
  const createMerchantWithdrawForMerchantMember = trpc.createMerchantWithdrawForMerchantMember.useMutation()
  const paymentMethods = filterPaymentMethodsByFamily(
    merchant.paymentMethods,
    'internal',
    'input'
  ) as typeof merchant.paymentMethods

  const formy = useFormy({
    initialValues: {
      amountUsdt: '',
      paymentMethodId: paymentMethods[0].id,
    },
    validationSchema: zCreateMerchantWithdrawForMerchantMemberEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { merchantWithdraw } = await createMerchantWithdrawForMerchantMember.mutateAsync(valuesInput)
      navigate(merchantMerchantWithdrawViewRoute.get({ merchantWithdrawSerialNumber: merchantWithdraw.serialNumber }))
    },
    successMessage: 'Мерчантский виздрав создан',
    resetOnSuccess: false,
  })

  const breadcrumbs = [
    { title: 'Мерчантские виздравы', href: merchantMerchantWithdrawListRoute.get() },
    { title: 'Новый мерчантский виздрав' },
  ]

  return (
    <Block>
      <Breadcrumbs items={breadcrumbs} rootRole="merchant" />
      <Segment title="Новый мерчантский виздрав" size="m">
        <FormItems as="form" {...formy.formProps}>
          <Textfieldy label="Сумма в USDT" {...formy.getFieldProps('amountUsdt')} />
          <PaymentMethodCardsPicker
            label="Метод оплаты"
            {...formy.getFieldProps('paymentMethodId')}
            paymentMethods={paymentMethods}
          />
          <Buttons>
            <Button {...formy.buttonProps} type="submit">
              Создать мерчантский виздрав
            </Button>
          </Buttons>
        </FormItems>
      </Segment>
    </Block>
  )
})
