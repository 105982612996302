import { pageData } from './pageData.js'
import { zCreateTraderDepositForTraderEndpointInput } from '@/general/src/traderDeposit/routes/createTraderDepositForTrader/input.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { traderTraderDepositListRoute, traderTraderDepositViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'
import { Text } from '@mantine/core'
import { useNavigate } from 'react-router-dom'

export const TraderTraderDepositNewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedTradersOnly: true,
  useQuery: () => {
    return trpc.getCompanyForTrader.useQuery()
  },
  setProps: ({ queryResult }) => ({
    company: queryResult.data.company,
  }),
})((props) => {
  const navigate = useNavigate()
  const createTraderDepositForTrader = trpc.createTraderDepositForTrader.useMutation()
  const formy = useFormy({
    initialValues: {
      amountUsdt: '',
    },
    validationSchema: zCreateTraderDepositForTraderEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { traderDeposit } = await createTraderDepositForTrader.mutateAsync(valuesInput)
      navigate(traderTraderDepositViewRoute.get({ traderDepositSerialNumber: traderDeposit.serialNumber }))
    },
    successMessage: 'Трейдерский депозит создан',
    resetOnSuccess: false,
  })

  const breadcrumbs = [
    { title: 'Депозиты трейдера', href: traderTraderDepositListRoute.get() },
    { title: 'Новый депозит' },
  ]

  return (
    <Block>
      <Breadcrumbs items={breadcrumbs} rootRole="trader" />
      <Segment title="Создание трейдерского депозита" size="m">
        <Text mb={15}>
          Комиссия за депозит{' '}
          <Text display="inline" fw={700}>
            {props.company.traderDepositFeeUsdt} USDT
          </Text>
        </Text>

        <FormItems as="form" {...formy.formProps}>
          <Textfieldy label="Сумма в USDT" {...formy.getFieldProps('amountUsdt')} />
          <Buttons>
            <Button {...formy.buttonProps} type="submit">
              Создать трейдерский депозит
            </Button>
          </Buttons>
        </FormItems>
      </Segment>
    </Block>
  )
})
