import { pageData } from './pageData.js'
import { Chat } from '@/general/src/chat/components/Chat/index.js'
import {
  toHumanCustomerDepositCancellationReason,
  toHumanCustomerDepositStatus,
} from '@/general/src/customerDeposit/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { merchantCustomerDepositListRoute, merchantCustomerDepositViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, LabeledValues, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { withMessage } from '@/webapp/src/lib/utils.js'
import { differenceInMinutes, formatDate } from 'date-fns'
import { useEffect, useState } from 'react'

export const MerchantCustomerDepositViewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedMerchantMembersOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(merchantCustomerDepositViewRoute)
    const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
    const queryResult = trpc.getCustomerDepositForMerchantMember.useQuery(
      {
        customerDepositSerialNumber: +routeParams.customerDepositSerialNumber,
      },
      {
        refetchInterval,
      }
    )
    const isActive = queryResult.data?.customerDeposit.isActive
    useEffect(() => {
      if (isActive) {
        setRefetchInterval(3_000)
      } else {
        setRefetchInterval(false)
      }
    }, [isActive])
    return queryResult
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    customerDeposit: queryResult.data.customerDeposit,
  }),
})(({ customerDeposit }) => {
  const breadcrumbs = [
    { title: 'Клиентские депозиты', href: merchantCustomerDepositListRoute.get() },
    { title: `Депозит #${customerDeposit.serialNumber}` },
  ]

  return (
    <Block>
      <Breadcrumbs items={breadcrumbs} rootRole="merchant" />
      <Segment title={`Клиентский депозит #${customerDeposit.serialNumber}`} size="m">
        <Block fcnw g={30}>
          <LabeledValues
            $style={{
              labelColor: 'var(--mantine-color-text)',
              hintColor: 'var(--mantine-color-text)',
            }}
            valuesEmptyPolicy="hide"
            items={[
              ['Статус', toHumanCustomerDepositStatus(customerDeposit.status)],
              ['Сумма', toMoney({ amount: customerDeposit.dealAmount, currency: customerDeposit.dealCurrency })],
              [
                'Платёжный метод',
                customerDeposit.paymentMethodType && toHumanPaymentMethodType(customerDeposit.paymentMethodType),
              ],
              ['Сумма в USDT', toMoney({ amount: customerDeposit.amountUsdt, currency: 'usdt' })],
              [
                'Комиссия мерчанта в USDT',
                !!customerDeposit.merchantFeeUsdt &&
                  toMoney({ amount: customerDeposit.merchantFeeUsdt, currency: 'usdt' }),
              ],
              ['Создание', formatDate(customerDeposit.createdAt, 'dd.MM.yyyy HH:mm')],
              ['Взят в работу', customerDeposit.pickedAt && formatDate(customerDeposit.pickedAt, 'dd.MM.yyyy HH:mm')],
              ['Оплачено', customerDeposit.declaredAt && formatDate(customerDeposit.declaredAt, 'dd.MM.yyyy HH:mm')],
              ['Проверено', customerDeposit.completedAt && formatDate(customerDeposit.completedAt, 'dd.MM.yyyy HH:mm')],
              ['Отменено', customerDeposit.cancelledAt && formatDate(customerDeposit.cancelledAt, 'dd.MM.yyyy HH:mm')],
              [
                'Начало спора',
                customerDeposit.disputeStartedAt && formatDate(customerDeposit.disputeStartedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Завершение спора',
                customerDeposit.disputeCompletedAt &&
                  formatDate(customerDeposit.disputeCompletedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Отмена спора',
                customerDeposit.disputeCancelledAt &&
                  formatDate(customerDeposit.disputeCancelledAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Длительность в минутах',
                customerDeposit.finishedAt &&
                  differenceInMinutes(customerDeposit.finishedAt, customerDeposit.createdAt),
              ],
            ]}
          />
          {customerDeposit.status === 'completed' ? (
            <Segment title="Сделка успешно завершена" size="m" />
          ) : customerDeposit.status === 'cancelled' ? (
            <Segment
              title="Сделка была отменена"
              size="m"
              desc={toHumanCustomerDepositCancellationReason(
                customerDeposit.cancellationCode,
                customerDeposit.cancellationMessage
              )}
            />
          ) : customerDeposit.status === 'disputing' ? (
            <Segment
              title="Идёт спор"
              size="m"
              desc={withMessage(`Администрация разбирается в споре`, customerDeposit.disputeStartMessage)}
            />
          ) : null}
          <Chat chatId={customerDeposit.chatId} viewerType="merchantMember" />
        </Block>
      </Segment>
    </Block>
  )
})
