import { pageData } from './pageData.js'
import { Chats } from '@/general/src/chat/components/Chats/index.js'
import { UnresolvedChats } from '@/general/src/chat/components/UnresolvedChats/index.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { Block, Segment } from '@/webapp/src/lib/uninty.components.js'

const breadcrumbs = [{ title: 'Чаты' }]

export const MerchantChatListPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedMerchantMembersOnly: true,
})(() => {
  return (
    <Block>
      <Breadcrumbs items={breadcrumbs} rootRole="merchant" />
      <Block fcnw g={40}>
        <Segment title="Непросмотренные чаты" size="m">
          <UnresolvedChats viewerType="merchantMember" />
        </Segment>
        <Segment title="Все чаты" size="m">
          <Chats viewerType="merchantMember" />
        </Segment>
      </Block>
    </Block>
  )
})
