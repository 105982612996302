import { zAmountIntegerWithDecimalsLimited, zNumericStringAmountLimited } from '@/general/src/other/money.js'
import { zEmailRequired, zPhoneOptionalNullable, zStringRequired } from '@/general/src/other/validation.js'
import type { toClientTraderForAdmin } from '@/general/src/trader/utils.server.js'
import { z } from 'zod'

export const zUpdateTraderForAdminEndpointInput = (trader?: ReturnType<typeof toClientTraderForAdmin>) => {
  const teamleadDepositInterestShareNumber = trader?.referrer?.customerDepositInterestShare || BigInt(0)
  const teamleadWithdrawInterestShareNumber = trader?.referrer?.customerWithdrawInterestShare || BigInt(0)

  // Проценты тимлида не могут быть меньше процентов трейдера
  const maxWithdrawCompanyInterestShare =
    trader && teamleadWithdrawInterestShareNumber > BigInt(0)
      ? Number(teamleadWithdrawInterestShareNumber) - Number(trader.customerWithdrawInterestShare)
      : 10_000
  const maxDepositCompanyInterestShare =
    trader && teamleadDepositInterestShareNumber > BigInt(0)
      ? Number(teamleadDepositInterestShareNumber) - Number(trader.customerDepositInterestShare)
      : 10_000

  return z.object({
    traderId: zStringRequired,
    customerWithdrawInterestShare: zAmountIntegerWithDecimalsLimited(0, 10_000),
    customerDepositInterestShare: zAmountIntegerWithDecimalsLimited(0, 10_000),
    customerWithdrawCompanyInterestShare: zNumericStringAmountLimited(0, maxWithdrawCompanyInterestShare),
    customerDepositCompanyInterestShare: zNumericStringAmountLimited(0, maxDepositCompanyInterestShare),
    email: zEmailRequired,
    phone: zPhoneOptionalNullable,
    telegram: zStringRequired,
    name: zStringRequired,
    referrerSerialNumber: z.coerce.number().int().optional().nullable(),
    banned: z.boolean(),
    online: z.boolean(),
    externalChatLink: z
      .string()
      .optional()
      .nullable()
      .transform((v) => v ?? null),
  })
}
