import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { ApiDocsSection } from '@/general/src/api/docs/ApiDocsSection.js'

export const ApiDocsH2HPatchCancelCustomerDeposit = ({
  merchant,
}: {
  merchant?: TrpcRouterOutput['getMyMerchant']['merchant'] | null
}) => {
  return (
    <ApiDocsSection
      merchant={merchant}
      title="Отмена сделки (депозита)"
      desc="Досрочно закрывает сделку, если она находится в статусе processing и не имеет открытых споров."
      method="PATCH"
      path="/api/h2h/customer-deposit/:id/cancel/v1"
      urlParams={[
        {
          key: 'id',
          title: 'id',
          desc: 'id сделки в нашей системе',
        },
      ]}
      hashParams={[]}
      responseExample={{
        customerDeposit: {
          id: '01914b92-40fe-7990-956c-2dab326c7f6d',
          externalId: '01914b92-40fe-7990-956c-2dab326c7sdf',
          status: 'cancelled',
          cancellationCode: null,
          cancellationMessage: null,
          createdAt: '2024-08-13T11:49:21.788Z',
          pickedAt: '2024-08-13T11:49:21.788Z',
          cancelledAt: '2024-08-13T11:49:21.788Z',
          demo: false,
        },
      }}
    />
  )
}
