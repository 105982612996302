import { pageData } from './pageData.js'
import { zPasswordsMustBeTheSame, zStringRequired } from '@/general/src/other/validation.js'
import { PaymentMethodsEditor } from '@/general/src/paymentMethod/PaymentMethod.js'
import { zUpdateMyPasswordForTraderEndpointInput } from '@/general/src/trader/routes/updateMyPasswordForTrader/input.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, Segment, Segments } from '@/webapp/src/lib/uninty.components.js'

const Password = () => {
  const updatePassword = trpc.updateMyPasswordForTrader.useMutation()
  const formy = useFormy({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordAgain: '',
    },
    validationSchema: zUpdateMyPasswordForTraderEndpointInput
      .extend({
        newPasswordAgain: zStringRequired,
      })
      .superRefine(zPasswordsMustBeTheSame('newPassword', 'newPasswordAgain')),
    onSubmit: async ({ valuesInput: { newPassword, oldPassword } }) => {
      await updatePassword.mutateAsync({ newPassword, oldPassword })
    },
    successMessage: 'Пароль изменён',
    resetOnSuccess: true,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Textfieldy label="Старый пароль" type="password" {...formy.getFieldProps('oldPassword')} />
      <Textfieldy label="Новый пароль" type="password" {...formy.getFieldProps('newPassword')} />
      <Textfieldy label="Новый пароль ещё раз" type="password" {...formy.getFieldProps('newPasswordAgain')} />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Сменить пароль
        </Button>
      </Buttons>
    </FormItems>
  )
}

export const TraderSettingsPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedTradersOnly: true,
  setProps: ({ getAuthorizedTraderMe }) => ({
    me: getAuthorizedTraderMe(),
  }),
})(({ me }) => {
  const breadcrumbs = [{ title: 'Настройки' }]

  return (
    <Block fcnw>
      <Breadcrumbs items={breadcrumbs} rootRole="trader" />
      <Segment title="Трейдерские настройки" size="m">
        <Segments>
          <PaymentMethodsEditor
            updateMyPaymentMethodsTrpcRouteName="updateMyPaymentMethodsForTrader"
            paymentMethods={me.trader.paymentMethods}
          />
          <Segment title="Управление паролем" size="s">
            <Password />
          </Segment>
        </Segments>
      </Segment>
    </Block>
  )
})
