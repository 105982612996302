import { pageData } from './pageData.js'
import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { Chat } from '@/general/src/chat/components/Chat/index.js'
import { toMoney } from '@/general/src/other/money.js'
import { zCancelPickingTraderInsuranceWithdrawForTraderEndpointInput } from '@/general/src/traderInsuranceWithdraw/routes/cancelPickingTraderInsuranceWithdrawForTrader/input.js'
import {
  toHumanTraderInsuranceWithdrawCancellationReason,
  toHumanTraderInsuranceWithdrawStatus,
} from '@/general/src/traderInsuranceWithdraw/utils.shared.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import {
  traderTraderInsuranceWithdrawListRoute,
  traderTraderInsuranceWithdrawViewRoute,
} from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, LabeledValues, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { differenceInMinutes } from 'date-fns'
import { formatDate } from 'date-fns/format'
import { useEffect, useState } from 'react'

const CancelPicking = ({
  traderInsuranceWithdraw,
}: {
  traderInsuranceWithdraw: TrpcRouterOutput['getTraderInsuranceWithdrawForTrader']['traderInsuranceWithdraw']
}) => {
  const cancelPickingTraderInsuranceWithdrawForTrader = trpc.cancelPickingTraderInsuranceWithdrawForTrader.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      traderInsuranceWithdrawId: traderInsuranceWithdraw.id,
      message: '',
    },
    validationSchema: zCancelPickingTraderInsuranceWithdrawForTraderEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { traderInsuranceWithdraw } = await cancelPickingTraderInsuranceWithdrawForTrader.mutateAsync(valuesInput)
      trpcUtils.getTraderInsuranceWithdrawForTrader.setData(
        { traderInsuranceWithdrawSerialNumber: traderInsuranceWithdraw.serialNumber },
        { traderInsuranceWithdraw }
      )
    },
    successMessage: 'Трейдерский депозит успешно отменён',
    resetOnSuccess: false,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Textfieldy {...formy.getFieldProps('message')} label="Причина отмены" />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Отменить
        </Button>
      </Buttons>
    </FormItems>
  )
}

export const TraderTraderInsuranceWithdrawViewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedTradersOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(traderTraderInsuranceWithdrawViewRoute)
    const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
    const queryResult = trpc.getTraderInsuranceWithdrawForTrader.useQuery(
      {
        traderInsuranceWithdrawSerialNumber: +routeParams.traderInsuranceWithdrawSerialNumber,
      },
      {
        refetchInterval,
      }
    )
    const isActive = queryResult.data?.traderInsuranceWithdraw.isActive
    useEffect(() => {
      if (isActive) {
        setRefetchInterval(3_000)
      } else {
        setRefetchInterval(false)
      }
    }, [isActive])
    return queryResult
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    traderInsuranceWithdraw: queryResult.data.traderInsuranceWithdraw,
  }),
})(({ traderInsuranceWithdraw }) => {
  const breadcrumbs = [
    { title: 'Трейдерские страховые виздравы', href: traderTraderInsuranceWithdrawListRoute.get() },
    { title: `Виздрав #${traderInsuranceWithdraw.serialNumber}` },
  ]

  return (
    <Block>
      <Breadcrumbs items={breadcrumbs} rootRole="trader" />
      <Segment title={`Трейдерский страховой виздрав #${traderInsuranceWithdraw.serialNumber}`} size="m">
        <Block fcnw g={30}>
          <LabeledValues
            $style={{
              labelColor: 'var(--mantine-color-text)',
              hintColor: 'var(--mantine-color-text)',
            }}
            valuesEmptyPolicy="hide"
            items={[
              ['Статус', toHumanTraderInsuranceWithdrawStatus(traderInsuranceWithdraw.status)],
              ['Сумма в USDT', toMoney({ amount: traderInsuranceWithdraw.amountUsdt, currency: 'usdt' })],
              [
                'Админ',
                traderInsuranceWithdraw.admin?.serialNumber && `#${traderInsuranceWithdraw.admin.serialNumber}`,
              ],
              ['Создание', formatDate(traderInsuranceWithdraw.createdAt, 'dd.MM.yyyy HH:mm')],
              [
                'Взят в работу',
                traderInsuranceWithdraw.pickedAt && formatDate(traderInsuranceWithdraw.pickedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Одобрено',
                traderInsuranceWithdraw.completedAt &&
                  formatDate(traderInsuranceWithdraw.completedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Отменено',
                traderInsuranceWithdraw.cancelledAt &&
                  formatDate(traderInsuranceWithdraw.cancelledAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Начало спора',
                traderInsuranceWithdraw.disputeStartedAt &&
                  formatDate(traderInsuranceWithdraw.disputeStartedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Завершение спора',
                traderInsuranceWithdraw.disputeCompletedAt &&
                  formatDate(traderInsuranceWithdraw.disputeCompletedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Отмена спора',
                traderInsuranceWithdraw.disputeCancelledAt &&
                  formatDate(traderInsuranceWithdraw.disputeCancelledAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Длительность в минутах',
                traderInsuranceWithdraw.completedAt &&
                  differenceInMinutes(traderInsuranceWithdraw.completedAt, traderInsuranceWithdraw.createdAt),
              ],
            ]}
          />
          {traderInsuranceWithdraw.status === 'picking' ? (
            <>
              <Segment title="Ваша задача" size="m" desc="Дождаться пока админ возьмётся за ваш страховой виздрав" />
              <Segment title="Отмена сделки" size="m" desc="Вы можете отменить сделку на это этапе">
                <CancelPicking traderInsuranceWithdraw={traderInsuranceWithdraw} />
              </Segment>
            </>
          ) : traderInsuranceWithdraw.status === 'processing' ? (
            <Segment title="Ваша задача" size="m" desc="Дождаться пока админ одобрит ваш страховой виздрав" />
          ) : traderInsuranceWithdraw.status === 'completed' ? (
            <Segment title="Сделка успешно завершена" size="m" />
          ) : traderInsuranceWithdraw.status === 'cancelled' ? (
            <Segment
              title="Сделка была отменена"
              size="m"
              desc={toHumanTraderInsuranceWithdrawCancellationReason(
                traderInsuranceWithdraw.cancellationCode,
                traderInsuranceWithdraw.cancellationMessage
              )}
            />
          ) : null}
        </Block>
        <Chat chatId={traderInsuranceWithdraw.chatId} viewerType="trader" />
      </Segment>
    </Block>
  )
})
