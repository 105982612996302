import { pageData } from './pageData.js'
import { toMoney } from '@/general/src/other/money.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { toHumanTraderDepositStatus } from '@/general/src/traderDeposit/utils.shared.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { traderTraderDepositNewRoute, traderTraderDepositViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, Segment, Table } from '@/webapp/src/lib/uninty.components.js'
import { formatDate } from 'date-fns/format'

export const TraderTraderDepositListPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedTradersOnly: true,
})(() => {
  const breadcrumbs = [{ title: 'Трейдерские депозиты' }]

  const queryResult = trpc.getTraderDepositsForTrader.useInfiniteQuery(
    {},
    {
      getNextPageParam: (lastPage) => {
        return lastPage.nextCursor
      },
      refetchOnMount: true,
    }
  )
  const traderDeposits = queryResult.data?.pages.flatMap((page) => page.traderDeposits) || []
  return (
    <Block fcnw>
      <Breadcrumbs items={breadcrumbs} rootRole="trader" />
      <Segment
        title="Мои трейдерские депозиты"
        size="m"
        desc={
          <>
            Трейдерский депозит — это способ пополнения вашего свободного баланса
            <br />
            <Buttons>
              <Button as="a" className="buttonA" href={traderTraderDepositNewRoute.get()}>
                Создать новый трейдерский депозит
              </Button>
            </Buttons>
          </>
        }
      >
        {queryResult.isLoading ? (
          <p>Загрузка...</p>
        ) : !traderDeposits.length ? (
          <p>Здесь пусто</p>
        ) : (
          <Table
            className="unintyTable"
            records={traderDeposits}
            href={(record) => traderTraderDepositViewRoute.get({ traderDepositSerialNumber: record.serialNumber })}
            columns={[
              { heading: 'SN', body: (record) => record.serialNumber, width: 100 },
              { heading: 'Статус', body: (record) => toHumanTraderDepositStatus(record.status), width: 150 },
              {
                heading: 'Дата',
                body: (record) => formatDate(record.createdAt, 'dd.MM.yyyy HH:mm'),
                width: 150,
              },
              {
                heading: 'Метод',
                body: (record) => toHumanPaymentMethodType(record.paymentMethodType),
                width: 200,
              },
              {
                heading: 'Сумма',
                body: (record) => toMoney({ amount: record.amount, currency: record.currency }),
                width: 100,
              },
              {
                heading: 'Сумма, ₮',
                body: (record) => toMoney({ amount: record.amountUsdt, currency: 'usdt' }),
                width: 100,
              },
            ]}
            loadMore={() => {
              if (!queryResult.isFetchingNextPage && queryResult.hasNextPage) {
                void queryResult.fetchNextPage()
              }
            }}
            hasMore={queryResult.hasNextPage}
          />
        )}
      </Segment>
    </Block>
  )
})
