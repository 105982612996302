import type { AuthorizedTraderMe } from '@/webapp/src/lib/ctx.js'
import { useFormy } from '@/webapp/src/lib/formy.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'

const Start = () => {
  const trpcUrils = trpc.useUtils()
  const startOnlineForTrader = trpc.startOnlineForTrader.useMutation()
  const formy = useFormy({
    onSubmit: async () => {
      const result = await startOnlineForTrader.mutateAsync({})
      trpcUrils.getMe.setData(undefined, result)
    },
    successMessage: 'Вы перешли в рабочий режим',
    resetOnSuccess: true,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Перейти в рабочий режим
        </Button>
      </Buttons>
    </FormItems>
  )
}

const Stop = () => {
  const trpcUrils = trpc.useUtils()
  const stopOnlineForTrader = trpc.stopOnlineForTrader.useMutation()
  const formy = useFormy({
    onSubmit: async () => {
      const result = await stopOnlineForTrader.mutateAsync({})
      trpcUrils.getMe.setData(undefined, result)
    },
    successMessage: 'Вы перешли в режим отдыха',
    resetOnSuccess: true,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Уйти отдыхать
        </Button>
      </Buttons>
    </FormItems>
  )
}

export const StatusEditor = ({ me }: { me: AuthorizedTraderMe }) => {
  const traderStatus = me.trader.online ? `🔥 Я работаю` : `😴 Я отдыхаю`
  const automatorStatus = (() => {
    if (me.trader.automatorOnline) return 'автоматика включена 🟢'
    if (me.trader.automatorRecentlyOnline) return 'автоматика недавно отключена 🟡'
    return 'автоматика отключена 🔴'
  })()

  return (
    <Segment
      title={`${traderStatus} (${automatorStatus})`}
      size="s"
      desc="Если вы находитесь в режиме работы, вы автоматически будете назначаться на новые клиентские депозиты. Будьте внимательны. Если вы отдыхаете, автоматически вы не будете назначать ни на какие сделки"
    >
      {me.trader.online ? <Stop /> : <Start />}
    </Segment>
  )
}
