import type { ClientTraderAutomatorLogForPublic } from '@/general/src/automator/utils.server.js'
import { toHumanTraderAutomatorLogStatus } from '@/general/src/automator/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import { Table } from '@/webapp/src/lib/uninty.components.js'
import { formatDate } from 'date-fns/format'

export const TraderAutomatorLogsTable = ({ logs }: { logs: ClientTraderAutomatorLogForPublic[] }) => {
  return (
    <Table
      className="unintyTable"
      records={logs}
      columns={[
        {
          heading: 'SN',
          body: (record) => record.serialNumber,
          width: 100,
        },
        {
          heading: 'Дата',
          body: (record) => formatDate(record.createdAt, 'dd.MM.yyyy HH:mm'),
          width: 160,
        },
        {
          heading: 'Автоматор',
          body: (record) => (
            <span
              style={{
                wordWrap: 'break-word',
              }}
            >
              {record.appVersion} ({record.debug ? 'debug' : 'nondebug'})
            </span>
          ),
          width: 160,
        },
        {
          heading: 'Тип',
          body: (record) => (
            <span
              style={{
                wordWrap: 'break-word',
              }}
            >
              {record.sourceType}
            </span>
          ),
          width: 80,
        },
        { heading: 'Статус', body: (record) => toHumanTraderAutomatorLogStatus(record.status), width: 200 },
        {
          heading: 'Отправитель',
          body: (record) => (
            <span
              style={{
                wordWrap: 'break-word',
              }}
            >
              {record.sourceType === 'push' ? record.packageName : record.sender}
            </span>
          ),
          width: 200,
        },
        {
          heading: 'Заголовок',
          body: (record) => record.title || '—',
          width: 200,
        },
        {
          heading: 'Текст',
          body: (record) => record.text || '—',
          width: 300,
        },
        {
          heading: 'Сумма',
          body: (record) => toMoney({ amount: record.amount, currency: 'rub' }),
          width: 200,
        },
        {
          heading: 'Имя',
          body: (record) => record.name || '—',
          width: 200,
        },
      ]}
    />
  )
}
