import { integerWithDecimalsToAmountString, zAmountIntegerWithDecimalsLimited } from '@/general/src/other/money.js'
import { TextInput, type TextInputProps } from '@mantine/core'
import { useCallback, useEffect, useRef, useState } from 'react'

interface Props extends TextInputProps {
  amount: bigint
  onAmountChange: (opts: { amount: bigint; textAmount: string; isValid: boolean }) => void
}

export const MoneyTextInput = ({ amount, onAmountChange, ...props }: Props) => {
  const [textValue, setTextValue] = useState(integerWithDecimalsToAmountString({ amount, thousandsSeparator: '' }))
  const [isValid, setIsValid] = useState(true)
  const lastParsed = useRef(amount)

  useEffect(() => {
    if (amount !== lastParsed.current) {
      setTextValue(integerWithDecimalsToAmountString({ amount, thousandsSeparator: '' }))
    }
  }, [amount])

  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setTextValue(e.target.value)
      const parsed = zAmountIntegerWithDecimalsLimited(0, Number.MAX_SAFE_INTEGER).safeParse(e.target.value)
      if (parsed.success) {
        lastParsed.current = BigInt(parsed.data)
        onAmountChange({ amount: BigInt(parsed.data), textAmount: e.target.value, isValid: true })
        setIsValid(true)
      } else {
        onAmountChange({ amount: lastParsed.current, textAmount: e.target.value, isValid: false })
        setIsValid(false)
      }
    },
    [lastParsed, onAmountChange, setIsValid, setTextValue]
  )

  return <TextInput {...props} value={textValue} onChange={onChange} error={!isValid} />
}
