import { pageData } from './pageData.js'
import { canCreateAdmins } from '@/general/src/auth/can.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminAdminEditRoute, adminAdminNewRoute } from '@/webapp/src/lib/routes.js'
import { SectionWrapper } from '@/webapp/src/lib/sectionWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, Segment, Table } from '@/webapp/src/lib/uninty.components.js'

const breadcrumbs = [{ title: 'Админы' }]

export const AdminAdminListPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  setProps: ({ getAuthorizedAdminMe }) => ({ me: getAuthorizedAdminMe() }),
})(({ me }) => {
  return (
    <Block fcnw>
      <Breadcrumbs items={breadcrumbs} />
      <Segment
        title="Админы"
        size="m"
        desc={
          canCreateAdmins(me.admin) && (
            <Buttons>
              <Button as="a" href={adminAdminNewRoute.get()} className="buttonA">
                Добавить админа
              </Button>
            </Buttons>
          )
        }
      >
        <SectionWrapper
          useQuery={() => trpc.getAdminsForAdmin.useQuery({})}
          setProps={({ queryResult }) => ({
            admins: queryResult.data.admins,
          })}
        >
          {({ admins }) => {
            return (
              <Table
                className="unintyTable"
                records={admins}
                href={(record) => adminAdminEditRoute.get({ adminSerialNumber: record.serialNumber })}
                columns={[
                  { heading: 'SN', body: (record) => record.serialNumber, width: 70 },
                  { heading: 'Имя', body: (record) => record.name, width: 200 },
                  { heading: 'Почта', body: (record) => record.email, width: 200 },
                  { heading: 'Телефон', body: (record) => record.phone, width: 200 },
                ]}
              />
            )
          }}
        </SectionWrapper>
      </Segment>
    </Block>
  )
})
