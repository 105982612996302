import { pageData } from './pageData.js'
import { Txs } from '@/general/src/tx/components/Txs/index.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { Block, Segment } from '@/webapp/src/lib/uninty.components.js'

const breadcrumbs = [{ title: 'Транзакции' }]

export const MerchantTxListPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedMerchantMembersOnly: true,
})(() => {
  return (
    <Block fcnw>
      <Breadcrumbs items={breadcrumbs} rootRole="merchant" />
      <Segment title="Мерчантские транзакции" size="m">
        <Txs viewerType="merchantMember" />
      </Segment>
    </Block>
  )
})
