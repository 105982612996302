import { pageData } from './pageData.js'
import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { Chat } from '@/general/src/chat/components/Chat/index.js'
import { toMoney } from '@/general/src/other/money.js'
import { PaymentMethodCard } from '@/general/src/paymentMethod/PaymentMethod.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { zCancelPickingTraderDepositForTraderEndpointInput } from '@/general/src/traderDeposit/routes/cancelPickingTraderDepositForTrader/input.js'
import { zCancelProcessingTraderDepositForTraderEndpointInput } from '@/general/src/traderDeposit/routes/cancelProcessingTraderDepositForTrader/input.js'
import { zDeclareTraderDepositForTraderEndpointInput } from '@/general/src/traderDeposit/routes/declareTraderDepositForTrader/input.js'
import {
  toHumanTraderDepositCancellationReason,
  toHumanTraderDepositStatus,
} from '@/general/src/traderDeposit/utils.shared.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { traderTraderDepositListRoute, traderTraderDepositViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, LabeledValues, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { Text } from '@mantine/core'
import { differenceInMinutes } from 'date-fns'
import { formatDate } from 'date-fns/format'
import { useEffect, useState } from 'react'

const Declare = ({
  traderDeposit,
}: {
  traderDeposit: TrpcRouterOutput['getTraderDepositForTrader']['traderDeposit']
}) => {
  const declareTraderDepositForTrader = trpc.declareTraderDepositForTrader.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      traderDepositId: traderDeposit.id,
      garantexCode: '',
    },
    validationSchema: zDeclareTraderDepositForTraderEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { traderDeposit } = await declareTraderDepositForTrader.mutateAsync(valuesInput)
      trpcUtils.getTraderDepositForTrader.setData(
        { traderDepositSerialNumber: traderDeposit.serialNumber },
        { traderDeposit }
      )
    },
    successMessage: 'Трейдерский депозит переведён в статус проверки',
    resetOnSuccess: false,
  })
  if (!traderDeposit.companyPaymentMethodData) {
    return <p>Нет платёжных методов, обратитесь в администрацию</p>
  }
  return (
    <FormItems as="form" {...formy.formProps}>
      <PaymentMethodCard paymentMethod={traderDeposit.companyPaymentMethodData} />
      {traderDeposit.paymentMethodType === 'garantex' && (
        <Textfieldy label="Код гарантекс" {...formy.getFieldProps('garantexCode')} />
      )}
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Я оплатил
        </Button>
      </Buttons>
    </FormItems>
  )
}

const CancelPicking = ({
  traderDeposit,
}: {
  traderDeposit: TrpcRouterOutput['getTraderDepositForTrader']['traderDeposit']
}) => {
  const cancelPickingTraderDepositForTrader = trpc.cancelPickingTraderDepositForTrader.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      traderDepositId: traderDeposit.id,
      message: '',
    },
    validationSchema: zCancelPickingTraderDepositForTraderEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { traderDeposit } = await cancelPickingTraderDepositForTrader.mutateAsync(valuesInput)
      trpcUtils.getTraderDepositForTrader.setData(
        { traderDepositSerialNumber: traderDeposit.serialNumber },
        { traderDeposit }
      )
    },
    successMessage: 'Трейдерский депозит успешно отменён',
    resetOnSuccess: false,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Textfieldy {...formy.getFieldProps('message')} label="Причина отмены" />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Отменить
        </Button>
      </Buttons>
    </FormItems>
  )
}

const CancelProcessing = ({
  traderDeposit,
}: {
  traderDeposit: TrpcRouterOutput['getTraderDepositForTrader']['traderDeposit']
}) => {
  const cancelProcessingTraderDepositForTrader = trpc.cancelProcessingTraderDepositForTrader.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      traderDepositId: traderDeposit.id,
      message: '',
    },
    validationSchema: zCancelProcessingTraderDepositForTraderEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { traderDeposit } = await cancelProcessingTraderDepositForTrader.mutateAsync(valuesInput)
      trpcUtils.getTraderDepositForTrader.setData(
        { traderDepositSerialNumber: traderDeposit.serialNumber },
        { traderDeposit }
      )
    },
    successMessage: 'Трейдерский депозит успешно отменён',
    resetOnSuccess: false,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Textfieldy {...formy.getFieldProps('message')} label="Причина отмены" />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Отменить
        </Button>
      </Buttons>
    </FormItems>
  )
}

export const TraderTraderDepositViewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedTradersOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(traderTraderDepositViewRoute)
    const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
    const queryResult = trpc.getTraderDepositForTrader.useQuery(
      {
        traderDepositSerialNumber: +routeParams.traderDepositSerialNumber,
      },
      {
        refetchInterval,
      }
    )
    const isActive = queryResult.data?.traderDeposit.isActive
    useEffect(() => {
      if (isActive) {
        setRefetchInterval(3_000)
      } else {
        setRefetchInterval(false)
      }
    }, [isActive])
    return queryResult
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    traderDeposit: queryResult.data.traderDeposit,
  }),
})(({ traderDeposit }) => {
  const breadcrumbs = [
    { title: 'Депозиты трейдера', href: traderTraderDepositListRoute.get() },
    { title: `Депозит #${traderDeposit.serialNumber}` },
  ]

  return (
    <Block>
      <Breadcrumbs items={breadcrumbs} rootRole="trader" />
      <Segment title={`Депозит трейдера #${traderDeposit.serialNumber}`} size="m">
        <Block fcnw g={30}>
          <LabeledValues
            $style={{
              labelColor: 'var(--mantine-color-text)',
              hintColor: 'var(--mantine-color-text)',
            }}
            valuesEmptyPolicy="hide"
            items={[
              ['Статус', toHumanTraderDepositStatus(traderDeposit.status)],
              ['Сумма в USDT', toMoney({ amount: traderDeposit.amountUsdt, currency: 'usdt' })],
              ['Комиссия в USDT', toMoney({ amount: traderDeposit.feeUsdt, currency: 'usdt' })],
              [
                'Платёжный метод',
                traderDeposit.paymentMethodType && toHumanPaymentMethodType(traderDeposit.paymentMethodType),
              ],
              [
                'Номер кошелька',
                traderDeposit.companyPaymentMethodData?.group === 'crypto'
                  ? traderDeposit.companyPaymentMethodData.wallet
                  : null,
              ],
              ['Код гарантекс', traderDeposit.garantexCode],
              ['Админ', traderDeposit.admin?.serialNumber && `#${traderDeposit.admin.serialNumber}`],
              ['Создание', formatDate(traderDeposit.createdAt, 'dd.MM.yyyy HH:mm')],
              ['Взят в работу', traderDeposit.pickedAt && formatDate(traderDeposit.pickedAt, 'dd.MM.yyyy HH:mm')],
              ['Оплачено', traderDeposit.declaredAt && formatDate(traderDeposit.declaredAt, 'dd.MM.yyyy HH:mm')],
              ['Проверено', traderDeposit.completedAt && formatDate(traderDeposit.completedAt, 'dd.MM.yyyy HH:mm')],
              ['Отменено', traderDeposit.cancelledAt && formatDate(traderDeposit.cancelledAt, 'dd.MM.yyyy HH:mm')],
              [
                'Начало спора',
                traderDeposit.disputeStartedAt && formatDate(traderDeposit.disputeStartedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Завершение спора',
                traderDeposit.disputeCompletedAt && formatDate(traderDeposit.disputeCompletedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Отмена спора',
                traderDeposit.disputeCancelledAt && formatDate(traderDeposit.disputeCancelledAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Длительность в минутах',
                traderDeposit.finishedAt && differenceInMinutes(traderDeposit.finishedAt, traderDeposit.createdAt),
              ],
            ]}
          />
          {traderDeposit.status === 'picking' ? (
            <>
              <Segment title="Ваша задача" size="m" desc="Дождаться пока админ возьмётся за ваш депозит" />

              <Segment title="Отмена сделки" size="m" desc="Вы можете отменить сделку на этом этапе">
                <CancelPicking traderDeposit={traderDeposit} />
              </Segment>
            </>
          ) : traderDeposit.status === 'processing' ? (
            <>
              <Segment
                title="Ваша задача"
                size="m"
                desc={
                  <span>
                    Сделать перевод{' '}
                    <Text display="inline" fw={700}>
                      {toMoney({ amount: traderDeposit.amountUsdt, currency: 'usdt' })}
                    </Text>{' '}
                    по указанным реквизитам и нажать на кнопку «Я оплатил». На ваш счёт начислится сумма с учётом
                    комиссии{' '}
                    <Text display="inline" fw={700}>
                      {toMoney({ amount: traderDeposit.amountUsdt - traderDeposit.feeUsdt, currency: 'usdt' })}
                    </Text>
                  </span>
                }
              >
                <Declare traderDeposit={traderDeposit} />
              </Segment>

              <Segment title="Отмена сделки" size="m" desc="Вы можете отменить сделку на этом этапе">
                <CancelProcessing traderDeposit={traderDeposit} />
              </Segment>
            </>
          ) : traderDeposit.status === 'verifying' ? (
            <Segment
              title="Ваша задача"
              size="m"
              desc="Дождаться, пока админ подтвердит получение средств. После этого деньги отобразятся на вашем свободном балансе"
            />
          ) : traderDeposit.status === 'completed' ? (
            <Segment title="Сделка успешно завершена" size="m" />
          ) : traderDeposit.status === 'cancelled' ? (
            <Segment
              title="Сделка была отменена"
              size="m"
              desc={toHumanTraderDepositCancellationReason(
                traderDeposit.cancellationCode,
                traderDeposit.cancellationMessage
              )}
            />
          ) : null}
          <Chat chatId={traderDeposit.chatId} viewerType="trader" />
        </Block>
      </Segment>
    </Block>
  )
})
