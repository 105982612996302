import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { ApiDocsSection } from '@/general/src/api/docs/ApiDocsSection.js'

export const ApiDocsH2HGetCustomerDeposit = ({
  merchant,
}: {
  merchant?: TrpcRouterOutput['getMyMerchant']['merchant'] | null
}) => {
  return (
    <ApiDocsSection
      merchant={merchant}
      title="Получение информации о сделке (депозите)"
      desc="Возвращает информацию о сделке. Возвращает такой же объект как при создании сделки."
      method="GET"
      path="/api/h2h/customer-deposit/:id/v1"
      urlParams={[
        {
          key: 'id',
          title: 'id',
          desc: 'id сделки в нашей системе',
        },
      ]}
      hashParams={[]}
      responseExample={{
        customerDeposit: {
          id: '01914b92-40fe-7990-956c-2dab326c7f6d',
          externalId: '01914b92-40fe-7990-956c-2dab326c7sdf',
          status: 'processing',
          cancellationCode: null,
          cancellationMessage: null,
          disputeStartMessage: null,
          dealAmount: 100_000,
          dealCurrency: 'rub',
          amountUsdt: 1_079,
          dealCurrencyInOneUsdt: 9_262,
          merchantCurrency: 'rub',
          merchantCurrencyInOneUsdt: 9_262,
          merchantAmount: 100_000,
          merchantFeeShare: 400,
          merchantFeeUsdt: 43,
          trader: {
            id: 'ba77faf6-56f2-4592-9a8d-118684c99ee6',
            serialNumber: 1,
          },
          traderPaymentMethodData: {
            type: 'sberbankCard',
            group: 'card',
            provider: 'sberbank',
            ownerName: 'qweqwe qweqwe',
            cardNumber: '1234123412341238',
          },
          createdAt: '2024-08-13T11:49:21.788Z',
          pickedAt: '2024-08-13T11:49:21.788Z',
          cancelledAt: null,
          declaredAt: null,
          completedAt: null,
          completedBy: null,
          disputeStartedAt: null,
          disputeCompletedAt: null,
          disputeCancelledAt: null,
          willBeCancelledBecauseNotPickedAt: '2024-08-13T12:09:21.790Z',
          willBeCancelledBecauseNotDeclaredAt: null,
          finishedAt: null,
          demo: false,
        },
      }}
    />
  )
}
