import { pageData } from './pageData.js'
import { zUpdateAdminForAdminEndpointInput } from '@/general/src/admin/routes/updateAdminForAdmin/input.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { Switchy, Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminAdminEditRoute } from '@/webapp/src/lib/routes.js'
import * as routes from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'

export const AdminAdminEditPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(adminAdminEditRoute)
    return trpc.getAdminForAdmin.useQuery({
      adminSerialNumber: +routeParams.adminSerialNumber,
    })
  },
  setProps: ({ queryResult }) => ({
    admin: queryResult.data.admin,
  }),
})(({ admin }) => {
  const updateAdminForAdmin = trpc.updateAdminForAdmin.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      adminId: admin.id,
      name: admin.name,
      phone: admin.phone,
      email: admin.email,
      telegram: admin.telegram || '',
      banned: !!admin.bannedAt,
    },
    validationSchema: zUpdateAdminForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const res = await updateAdminForAdmin.mutateAsync({
        ...valuesInput,
      })
      trpcUtils.getAdminForAdmin.setData({ adminSerialNumber: res.admin.serialNumber }, res)
    },
    successMessage: 'Админ обновлён',
  })

  const breadcrumbs = [
    { title: 'Админы', href: routes.adminAdminListRoute.get() },
    { title: `Админ #${admin.serialNumber}` },
  ]

  return (
    <Block fcnw>
      <Breadcrumbs items={breadcrumbs} />
      <Segment title={`Админ #${admin.serialNumber}`} size="m">
        <FormItems as="form" {...formy.formProps}>
          <Textfieldy label="Имя" {...formy.getFieldProps('name')} />
          <Textfieldy label="Почта" {...formy.getFieldProps('email')} />
          <Textfieldy label="Телефон" {...formy.getFieldProps('phone')} />
          <Textfieldy label="Телеграм" {...formy.getFieldProps('telegram')} />
          <Switchy {...formy.getFieldProps('banned')} label="Забанен" optionLabel="Да" />
          <Buttons>
            <Button {...formy.buttonProps} type="submit">
              Сохранить
            </Button>
          </Buttons>
        </FormItems>
      </Segment>
    </Block>
  )
})
