import { pageData } from './pageData.js'
import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { Chat } from '@/general/src/chat/components/Chat/index.js'
import { zDisputeStartCustomerDepositForTraderEndpointInput } from '@/general/src/customerDeposit/routes/disputeStartCustomerDepositForTrader/input.js'
import { zVerifyCustomerDepositForTraderEndpointInput } from '@/general/src/customerDeposit/routes/verifyCustomerDepositForTrader/input.js'
import {
  toHumanCustomerDepositCancellationReason,
  toHumanCustomerDepositStatus,
} from '@/general/src/customerDeposit/utils.shared.js'
import { integerWithDecimalsToAmountString, toMoney } from '@/general/src/other/money.js'
import { PaymentMethodCard } from '@/general/src/paymentMethod/PaymentMethod.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { traderCustomerDepositListRoute, traderCustomerDepositViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, LabeledValues, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { withMessage } from '@/webapp/src/lib/utils.js'
import { differenceInMinutes, formatDate } from 'date-fns'
import { useEffect, useState } from 'react'

const Verify = ({
  customerDeposit,
}: {
  customerDeposit: TrpcRouterOutput['getCustomerDepositForTrader']['customerDeposit']
}) => {
  const verifyCustomerDepositForTrader = trpc.verifyCustomerDepositForTrader.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      customerDepositId: customerDeposit.id,
    },
    validationSchema: zVerifyCustomerDepositForTraderEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { customerDeposit } = await verifyCustomerDepositForTrader.mutateAsync(valuesInput)
      trpcUtils.getCustomerDepositForTrader.setData(
        { customerDepositSerialNumber: customerDeposit.serialNumber },
        { customerDeposit }
      )
    },
    successMessage: 'Клиентский депозит успешно завершён',
    resetOnSuccess: false,
  })
  if (!customerDeposit.traderPaymentMethodData) {
    return <p>Нет платёжных методов, обратитесь в администрацию</p>
  }
  return (
    <FormItems as="form" {...formy.formProps}>
      <PaymentMethodCard paymentMethod={customerDeposit.traderPaymentMethodData} />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Деньги получены
        </Button>
      </Buttons>
    </FormItems>
  )
}

const DispiteStart = ({
  customerDeposit,
}: {
  customerDeposit: TrpcRouterOutput['getCustomerDepositForTrader']['customerDeposit']
}) => {
  const disputeStartCustomerDepositForTrader = trpc.disputeStartCustomerDepositForTrader.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      customerDepositId: customerDeposit.id,
      message: '',
    },
    validationSchema: zDisputeStartCustomerDepositForTraderEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { customerDeposit } = await disputeStartCustomerDepositForTrader.mutateAsync(valuesInput)
      trpcUtils.getCustomerDepositForTrader.setData(
        { customerDepositSerialNumber: customerDeposit.serialNumber },
        { customerDeposit }
      )
    },
    successMessage: 'Спор по клиентскому депозиту начат',
    resetOnSuccess: false,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Textfieldy {...formy.getFieldProps('message')} label="Причина" />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Начать спор
        </Button>
      </Buttons>
    </FormItems>
  )
}

export const TraderCustomerDepositViewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedTradersOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(traderCustomerDepositViewRoute)
    const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
    const queryResult = trpc.getCustomerDepositForTrader.useQuery(
      {
        customerDepositSerialNumber: +routeParams.customerDepositSerialNumber,
      },
      {
        refetchInterval,
      }
    )
    const isActive = queryResult.data?.customerDeposit.isActive
    useEffect(() => {
      if (isActive) {
        setRefetchInterval(3_000)
      } else {
        setRefetchInterval(false)
      }
    }, [isActive])
    return queryResult
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    customerDeposit: queryResult.data.customerDeposit,
  }),
})(({ customerDeposit }) => {
  const breadcrumbs = [
    { title: 'Клиентские депозиты', href: traderCustomerDepositListRoute.get() },
    { title: `Депозит #${customerDeposit.serialNumber}` },
  ]

  return (
    <Block>
      <Breadcrumbs items={breadcrumbs} rootRole="trader" />
      <Segment title={`Клиентский депозит #${customerDeposit.serialNumber}`} size="m">
        <Block fcnw g={30}>
          <LabeledValues
            $style={{
              labelColor: 'var(--mantine-color-text)',
              hintColor: 'var(--mantine-color-text)',
            }}
            valuesEmptyPolicy="hide"
            items={[
              ['Статус', toHumanCustomerDepositStatus(customerDeposit.status)],
              ['Сумма', toMoney({ amount: customerDeposit.dealAmount, currency: customerDeposit.dealCurrency })],
              [
                'Платёжный метод',
                customerDeposit.paymentMethodType && toHumanPaymentMethodType(customerDeposit.paymentMethodType),
              ],
              ['Сумма в USDT', toMoney({ amount: customerDeposit.amountUsdt, currency: 'usdt' })],
              [
                'Вознаграждение в USDT',
                !!customerDeposit.traderInterestUsdt &&
                  toMoney({ amount: customerDeposit.traderInterestUsdt, currency: 'usdt' }),
              ],
              [
                'Вознаграждение в %',
                !!customerDeposit.traderInterestShare &&
                  integerWithDecimalsToAmountString({ amount: customerDeposit.traderInterestShare }) + '%',
              ],
              ['Создание', formatDate(customerDeposit.createdAt, 'dd.MM.yyyy HH:mm')],
              ['Взят в работу', customerDeposit.pickedAt && formatDate(customerDeposit.pickedAt, 'dd.MM.yyyy HH:mm')],
              ['Оплачено', customerDeposit.declaredAt && formatDate(customerDeposit.declaredAt, 'dd.MM.yyyy HH:mm')],
              ['Проверено', customerDeposit.completedAt && formatDate(customerDeposit.completedAt, 'dd.MM.yyyy HH:mm')],
              ['Отменено', customerDeposit.cancelledAt && formatDate(customerDeposit.cancelledAt, 'dd.MM.yyyy HH:mm')],
              [
                'Начало спора',
                customerDeposit.disputeStartedAt && formatDate(customerDeposit.disputeStartedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Завершение спора',
                customerDeposit.disputeCompletedAt &&
                  formatDate(customerDeposit.disputeCompletedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Отмена спора',
                customerDeposit.disputeCancelledAt &&
                  formatDate(customerDeposit.disputeCancelledAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Длительность в минутах',
                customerDeposit.finishedAt &&
                  differenceInMinutes(customerDeposit.finishedAt, customerDeposit.createdAt),
              ],
            ]}
          />
          {customerDeposit.status === 'processing' ? (
            <Segment
              title="Ваша задача"
              size="m"
              desc="Дождаться пока клиент сделает перевод. Если вы видите, что клиент уже сделал перевод, вы можете уже его подтвердить"
            >
              <Verify customerDeposit={customerDeposit} />
            </Segment>
          ) : customerDeposit.status === 'verifying' ? (
            <>
              <Segment
                title="Ваша задача"
                size="m"
                desc="Проверить, что деньги от клиента пришли и подтвердить получение"
              >
                <Verify customerDeposit={customerDeposit} />
              </Segment>
              <Segment
                title="Спор не начат"
                size="m"
                desc="Если деньги не пришли, вы можете начать спор, чтобы пришёл админ и разобрался"
              >
                <DispiteStart customerDeposit={customerDeposit} />
              </Segment>
            </>
          ) : customerDeposit.status === 'completed' ? (
            <Segment title="Сделка успешно завершена" size="m" />
          ) : customerDeposit.status === 'cancelled' ? (
            <Segment
              title="Сделка была отменена"
              size="m"
              desc={toHumanCustomerDepositCancellationReason(
                customerDeposit.cancellationCode,
                customerDeposit.cancellationMessage
              )}
            />
          ) : customerDeposit.status === 'disputing' ? (
            <Segment
              title="Идёт спор"
              size="m"
              desc={withMessage(`Администрация разбирается в споре`, customerDeposit.disputeStartMessage)}
            />
          ) : null}
          <Chat chatId={customerDeposit.chatId} viewerType="trader" />
        </Block>
      </Segment>
    </Block>
  )
})
