import axios from 'axios'

export const replacePathParams = (path: string, params?: Record<string, string>): string => {
  if (!params) return path

  return path.replace(/:(\w+)/g, (_, key) => params[key] || `:${key}`)
}

interface RestCallOptions {
  baseUrl: string
  path: string
  method: 'get' | 'post' | 'put' | 'delete' | 'patch'
  searchParams?: Record<string, string>
  urlParams?: Record<string, string>
  body?: any
  headers?: Record<string, string>
}

export const callRestRoute = async (options: RestCallOptions) => {
  const { baseUrl, path, method, searchParams, urlParams, body, headers = {} } = options

  const urlSearchParams = new URLSearchParams(searchParams)
  const searchString = urlSearchParams.toString()
  const searchStringWithQuestionMark = searchString ? '?' + searchString : ''
  const defaultHeaders = {
    'Content-Type': 'application/json',
  }
  const finalHeaders = { ...defaultHeaders, ...headers }
  const finalPath = replacePathParams(path, urlParams)

  try {
    const response = await axios({
      method,
      url: `${baseUrl}${finalPath}${searchStringWithQuestionMark}`,
      headers: finalHeaders,
      data: body,
    })
    return {
      status: response.status,
      data: response.data,
    }
  } catch (error: any) {
    return {
      status: error.response?.status,
      data: error.response?.data,
    }
  }
}
