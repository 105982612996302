import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { ApiDocsSection } from '@/general/src/api/docs/ApiDocsSection.js'

export const ApiDocsGetStats = ({ merchant }: { merchant?: TrpcRouterOutput['getMyMerchant']['merchant'] | null }) => {
  return (
    <ApiDocsSection
      merchant={merchant}
      title="Получение статистики"
      method="GET"
      path="/api/stats/v1"
      hashParams={[]}
      responseExample={{
        availableBalances: {
          card: {
            amountRub: 438_114.14,
            amountUsdt: 4_998.45,
          },
          sbp: {
            amountRub: 438_114.14,
            amountUsdt: 4_998.45,
          },
        },
        exchangeRate: 87.65,
        exchangeRateSource: 'bybit',
      }}
    />
  )
}
