import { pageData } from './pageData.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, LabeledValues, Segment, Segments } from '@/webapp/src/lib/uninty.components.js'

export const MerchantMerchantApiPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedMerchantMembersOnly: true,
  useQueries: () => {
    return trpc.useQueries((t) => [t.getMe(), t.getMyMerchant()])
  },
  setProps: ({ getAuthorizedMerchantMemberMe, queriesResults }) => ({
    me: getAuthorizedMerchantMemberMe(),
    merchant: queriesResults[1].data.merchant,
  }),
})(({ merchant }) => {
  const breadcrumbs = [{ title: 'Настройки API' }]

  return (
    <Block fcnw>
      <Breadcrumbs items={breadcrumbs} rootRole="merchant" />
      <Segment title="Настройки API" size="m">
        <Segments>
          <Segment title="Данные доступа" size="s">
            <LabeledValues
              $style={{
                labelColor: 'var(--mantine-color-text)',
                hintColor: 'var(--mantine-color-text)',
              }}
              items={[
                ['API ключ', merchant.apiKey, 'Используйте этот ключ только в продакшене'],
                ['Demo API ключ', merchant.demoApiKey, 'Используйте демо-ключ для тестового окружения'],
                ['API секрет', merchant.apiSecret],
              ]}
            />
          </Segment>
        </Segments>
      </Segment>
    </Block>
  )
})
