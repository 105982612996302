import { layoutStorik } from '@/webapp/src/components/layout/store.js'
import { Disabler, Layout } from '@/webapp/src/lib/uninty.components.js'
import React from 'react'

export const MerchantCustomerLayout = ({ children }: { children: React.ReactNode }) => {
  const { loading } = layoutStorik.useStore()
  return (
    <Layout
      $style={{
        headerFixed: true,
        sidebarFixed: true,
      }}
    >
      <Disabler
        disabled={loading}
        style={{
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'stretch',
          justifyContent: 'stretch',
          flex: '1 1 100%',
          width: '100%',
        }}
      >
        {children}
      </Disabler>
    </Layout>
  )
}
