import { createErrors } from '@/general/src/api/errors.js'

export const errors = createErrors({
  notFound: () => ({
    status: 404,
    data: {
      code: 'not_found',
      error: 'Клиентский депозит не найден',
    },
  }),
  wrongDepositStatus: () => ({
    status: 400,
    data: {
      code: 'wrong_deposit_status',
      error:
        'Клиентский депозит должен быть назначен на трейдера, чтобы его оспорить, либо отменён, так как клиент не подтвердил перевод',
    },
  }),
  noTrader: () => ({
    status: 404,
    data: {
      code: 'no_trader',
      error: 'Трейдер депозита не найден',
    },
  }),
  internalServerError: () => ({
    status: 500,
    data: {
      code: 'internal_server_error',
      error: 'Internal Server Error',
    },
  }),
})
