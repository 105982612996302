import type {
  CustomerWithdrawCancellationCode,
  CustomerWithdrawStatus as PrismaCustomerWithdrawStatus,
} from '@/backend/src/services/other/prisma.js'
import type { IsEqual } from 'type-fest'
import { z } from 'zod'

export const customerWithdrawStatuses = [
  'picking',
  'processing',
  'completed',
  'cancelled',
  'disputing',
  'refunded',
] as const satisfies PrismaCustomerWithdrawStatus[]
export const zCustomerWithdrawStatus = z.enum(customerWithdrawStatuses)
export type CustomerWithdrawStatus = (typeof customerWithdrawStatuses)[number]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const check1: IsEqual<CustomerWithdrawStatus, PrismaCustomerWithdrawStatus> = true

export const toHumanCustomerWithdrawStatus = (status: CustomerWithdrawStatus) => {
  return {
    picking: 'Поиск исполнителя',
    processing: 'Ожидание перевода',
    completed: 'Завершён',
    cancelled: 'Отменён',
    disputing: 'Спор',
    refunded: 'Возвращён',
  }[status]
}

export const toHumanCustomerWithdrawCancellationCode = (code: CustomerWithdrawCancellationCode | null) => {
  if (!code) {
    return 'Сделка не отменена'
  }
  return {
    picking_system_expires: 'Трейдер-исполнитель не был найден в заданный срок',
    processing_trader_manual: 'Трейдер отказался от сделки на этапе перевода денег',
  }[code]
}

export const toHumanCustomerWithdrawCancellationReason = (
  code: CustomerWithdrawCancellationCode | null,
  message: string | null
) => {
  const humanCode = toHumanCustomerWithdrawCancellationCode(code)
  if (!message) {
    return humanCode
  }
  return `${humanCode}: ${message}`
}
