import css from './index.module.css'
import { pageData } from './pageData.js'
import { toHumanFindTraderConditionInverted } from '@/general/src/api/utils.shared.js'
import type { ClientCustomerDepositPickUpLogForAdmin } from '@/general/src/customerDeposit/utils.server.js'
import { toMoney } from '@/general/src/other/money.js'
import { UnreachableCodeError } from '@/general/src/other/type-helpers.js'
import { toHumanPaymentMethodGroup, toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminTraderEditRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Accordion, Badge, Button, Divider, List } from '@mantine/core'
import { formatDate } from 'date-fns/format'
import { useCallback } from 'react'
import { entries, isTruthy } from 'remeda'

const breadcrumbs = [{ title: 'Логи сделок' }]

const PickUpLogStatus = (props: { status: ClientCustomerDepositPickUpLogForAdmin['status'] }) => {
  if (props.status === 'picked') {
    return <Badge color="green">Взят</Badge>
  }

  if (props.status === 'skipped') {
    return <Badge color="orange">Пропущен</Badge>
  }

  throw new UnreachableCodeError(props.status)
}

const PaymentMethod = ({
  paymentMethod,
}: {
  paymentMethod: ClientCustomerDepositPickUpLogForAdmin['report']['matches'][number]['paymentMethod']
}) => {
  return [
    toHumanPaymentMethodType(paymentMethod.type),
    paymentMethod.ownerName,
    paymentMethod.ownerPhone && `+${paymentMethod.ownerPhone}`,
    paymentMethod.cardNumber,
  ]
    .filter(isTruthy)
    .join(', ')
}

const PickUpLog = ({ log }: { log: ClientCustomerDepositPickUpLogForAdmin }) => {
  const noTraders = log.status === 'skipped' && !log.report?.matches?.length

  return (
    <Accordion>
      <Accordion.Item value="main">
        <Accordion.Control>
          <div className={css.logBaseInfo}>
            <PickUpLogStatus status={log.status} />
            <span>{formatDate(log.createdAt, 'dd.MM.yyyy HH:mm:ss')}</span>
            <span className={css.bold}>+&nbsp;{toMoney({ amount: log.dealAmount, currency: log.dealCurrency })}</span>
            {toHumanPaymentMethodGroup(log.paymentMethodGroup)}{' '}
            {log.paymentMethodType && toHumanPaymentMethodType(log.paymentMethodType)}
            <span>{log.durationMs} мс</span>
            {noTraders && <span className={css.bold}>Нет онлайн трейдеров</span>}
          </div>
        </Accordion.Control>
        <Accordion.Panel>
          {log.report?.matches
            ?.flatMap((match) => {
              const key = match.trader.id + match.paymentMethod.id
              return [
                <div key={key}>
                  Трейдер{' '}
                  <a href={adminTraderEditRoute.get({ traderSerialNumber: match.trader.serialNumber })}>
                    {match.trader.name} #{match.trader.serialNumber}
                  </a>
                  , <PaymentMethod paymentMethod={match.paymentMethod} />
                  <List withPadding>
                    {entries(match.conditions)
                      .filter(([, result]) => !result)
                      .map(([name]) => {
                        return <List.Item key={name}>• {toHumanFindTraderConditionInverted(name)}</List.Item>
                      })}
                  </List>
                </div>,
                <Divider key={`devider-${key}`} my="md" />,
              ]
            })
            .slice(0, -1)}
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  )
}

export const AdminCustomerDepositPickUpLogsPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
})(() => {
  const queryResult = trpc.getCustomerDepositPickUpLogsForAdmin.useInfiniteQuery(
    {},
    {
      getNextPageParam: (lastPage) => {
        return lastPage.nextCursor
      },
      refetchOnMount: true,
      refetchInterval: 3_000,
    }
  )

  const loadMore = useCallback(() => {
    if (!queryResult.isFetchingNextPage && queryResult.hasNextPage) {
      void queryResult.fetchNextPage()
    }
  }, [queryResult.isFetchingNextPage, queryResult.hasNextPage, queryResult.fetchNextPage])

  const logs = queryResult.data?.pages.flatMap((page) => page.customerDepositPickUpLogs) || []

  return (
    <div className={css.container}>
      <Breadcrumbs items={breadcrumbs} />
      {queryResult.isError ? (
        <p>Ошибка</p>
      ) : queryResult.isFetching && !logs.length ? (
        <p>Загрузка...</p>
      ) : !logs.length ? (
        <p>Здесь пусто</p>
      ) : (
        logs.map((log) => <PickUpLog key={log.id} log={log} />)
      )}
      {queryResult.hasNextPage && (
        <Button mt={20} onClick={loadMore}>
          Загрузить ещё
        </Button>
      )}
    </div>
  )
})
