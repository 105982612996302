import css from './index.module.css'
import { pageData } from './pageData.js'
import { toHumanCustomerDepositStatus } from '@/general/src/customerDeposit/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { CustomerDepositStatusIcon } from '@/webapp/src/components/other/CustomerDepositStatusIcon/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { traderCustomerDepositViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Segment, Table } from '@/webapp/src/lib/uninty.components.js'
import libCss from '@/webapp/src/styles/lib.module.scss'
import { formatDate } from 'date-fns/format'

export const TraderCustomerDepositListPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedTradersOnly: true,
})(() => {
  const queryResult = trpc.getCustomerDepositsForTrader.useInfiniteQuery(
    {},
    {
      getNextPageParam: (lastPage) => {
        return lastPage.nextCursor
      },
      refetchOnMount: true,
    }
  )
  const customerDeposits = queryResult.data?.pages.flatMap((page) => page.customerDeposits) || []
  const breadcrumbs = [{ title: 'Клиентские депозиты' }]

  return (
    <Block>
      <Breadcrumbs items={breadcrumbs} rootRole="trader" />
      <Segment
        title="Мои клиентские депозиты"
        size="m"
        desc={<>Клиентский депозит — это перевод денег от клиента мерчанта трейдеру платформы</>}
      >
        {queryResult.isLoading ? (
          <p>Загрузка...</p>
        ) : !customerDeposits.length ? (
          <p>Здесь пусто</p>
        ) : (
          <Table
            className="unintyTable"
            records={customerDeposits}
            href={(record) => traderCustomerDepositViewRoute.get({ customerDepositSerialNumber: record.serialNumber })}
            columns={[
              { heading: 'SN', body: (record) => record.serialNumber, width: 100 },
              {
                heading: 'Статус',
                body: (record) => {
                  return (
                    <div className={css.statusRow}>
                      {toHumanCustomerDepositStatus(record.status)}
                      <CustomerDepositStatusIcon data={record} />
                    </div>
                  )
                },
                width: 150,
              },
              {
                heading: 'Дата',
                body: (record) => formatDate(record.createdAt, 'dd.MM.yyyy HH:mm'),
                width: 150,
              },
              {
                heading: 'Метод',
                body: (record) => toHumanPaymentMethodType(record.paymentMethodType),
                width: 200,
              },
              {
                heading: 'Сумма',
                body: (record) => toMoney({ amount: record.dealAmount, currency: record.dealCurrency }),
                width: 100,
              },
              {
                heading: 'Сумма, ₮',
                body: (record) => toMoney({ amount: record.amountUsdt, currency: 'usdt' }),
                width: 100,
              },
              {
                heading: 'ID',
                body: (record) => (
                  <div dir="rtl" className={libCss.ellipsis}>
                    {record.id}
                  </div>
                ),
                width: 200,
              },
              {
                heading: 'External ID',
                body: (record) => (
                  <div dir="rtl" className={libCss.ellipsis}>
                    {record.externalId}
                  </div>
                ),
                width: 200,
              },
            ]}
            loadMore={() => {
              if (!queryResult.isFetchingNextPage && queryResult.hasNextPage) {
                void queryResult.fetchNextPage()
              }
            }}
            hasMore={queryResult.hasNextPage}
          />
        )}
      </Segment>
    </Block>
  )
})
