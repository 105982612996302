import { pageData } from './pageData.js'
import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { ActionLogs } from '@/general/src/actionLog/components/ActionLogs/index.js'
import { Chat } from '@/general/src/chat/components/Chat/index.js'
import { zDisputeCancelCustomerDepositForAdminEndpointInput } from '@/general/src/customerDeposit/routes/disputeCancelCustomerDepositForAdmin/input.js'
import { zDisputeCompleteCustomerDepositForAdminEndpointInput } from '@/general/src/customerDeposit/routes/disputeCompleteCustomerDepositForAdmin/input.js'
import { zDisputeStartCustomerDepositForAdminEndpointInput } from '@/general/src/customerDeposit/routes/disputeStartCustomerDepositForAdmin/input.js'
import { toHumanCustomerDepositCancellationReason } from '@/general/src/customerDeposit/utils.shared.js'
import { PaymentMethodCard } from '@/general/src/paymentMethod/PaymentMethod.js'
import { Txs } from '@/general/src/tx/components/Txs/index.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { TraderAutomatorLogsTable } from '@/webapp/src/components/other/TraderAutomatorLogsTable/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminCustomerDepositViewRoute } from '@/webapp/src/lib/routes.js'
import * as routes from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { CustomerDepositInfo } from '@/webapp/src/pages/admin/other/AdminCustomerDepositViewPage/CustomerDepositInfo.js'
import { useEffect, useState } from 'react'

const DispiteStart = ({
  customerDeposit,
}: {
  customerDeposit: TrpcRouterOutput['getCustomerDepositForAdmin']['customerDeposit']
}) => {
  const disputeStartCustomerDepositForAdmin = trpc.disputeStartCustomerDepositForAdmin.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      customerDepositId: customerDeposit.id,
      message: '',
    },
    validationSchema: zDisputeStartCustomerDepositForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { customerDeposit } = await disputeStartCustomerDepositForAdmin.mutateAsync(valuesInput)
      trpcUtils.getCustomerDepositForAdmin.setData(
        { customerDepositSerialNumber: customerDeposit.serialNumber },
        { customerDeposit }
      )
    },
    successMessage: 'Спор по клиентскому депозиту начат',
    resetOnSuccess: false,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Textfieldy {...formy.getFieldProps('message')} label="Причина" />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Начать спор
        </Button>
      </Buttons>
    </FormItems>
  )
}

const DisputeDecision = ({
  customerDeposit,
}: {
  customerDeposit: TrpcRouterOutput['getCustomerDepositForAdmin']['customerDeposit']
}) => {
  const trpcUtils = trpc.useUtils()

  const disputeCompleteCustomerDepositForAdmin = trpc.disputeCompleteCustomerDepositForAdmin.useMutation()
  const formyComplete = useFormy({
    initialValues: {
      customerDepositId: customerDeposit.id,
    },
    validationSchema: zDisputeCompleteCustomerDepositForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { customerDeposit } = await disputeCompleteCustomerDepositForAdmin.mutateAsync(valuesInput)
      trpcUtils.getCustomerDepositForAdmin.setData(
        { customerDepositSerialNumber: customerDeposit.serialNumber },
        { customerDeposit }
      )
    },
    successMessage: 'Спор успешно исполнен',
    resetOnSuccess: false,
  })

  const disputeCancelCustomerDepositForAdmin = trpc.disputeCancelCustomerDepositForAdmin.useMutation()
  const formyCancel = useFormy({
    initialValues: {
      customerDepositId: customerDeposit.id,
    },
    validationSchema: zDisputeCancelCustomerDepositForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { customerDeposit } = await disputeCancelCustomerDepositForAdmin.mutateAsync(valuesInput)
      trpcUtils.getCustomerDepositForAdmin.setData(
        { customerDepositSerialNumber: customerDeposit.serialNumber },
        { customerDeposit }
      )
    },
    successMessage: 'Спор успешно отменён',
    resetOnSuccess: false,
  })
  return (
    <FormItems>
      <FormItems as="form" {...formyComplete.formProps}>
        <Buttons>
          <Button {...formyComplete.buttonProps} type="submit">
            Исполнить спор, трейдер прав, клиент не прав, сделка будет отменена
          </Button>
        </Buttons>
      </FormItems>
      <FormItems as="form" {...formyCancel.formProps}>
        <Buttons>
          <Button {...formyCancel.buttonProps} type="submit">
            Отменить спор, трейдер не прав, клиент прав, сделка закроется в пользу мерчанта
          </Button>
        </Buttons>
      </FormItems>
    </FormItems>
  )
}

export const AdminCustomerDepositViewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(adminCustomerDepositViewRoute)
    const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
    const queryResult = trpc.getCustomerDepositForAdmin.useQuery(
      {
        customerDepositSerialNumber: +routeParams.customerDepositSerialNumber,
      },
      {
        refetchInterval,
      }
    )
    const isActive = queryResult.data?.customerDeposit.isActive
    useEffect(() => {
      if (isActive) {
        setRefetchInterval(3_000)
      } else {
        setRefetchInterval(false)
      }
    }, [isActive])
    return queryResult
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    customerDeposit: queryResult.data.customerDeposit,
  }),
})(({ customerDeposit }) => {
  const breadcrumbs = [
    { title: 'Клиентские депозиты', href: routes.adminCustomerDepositListRoute.get() },
    { title: `Депозит #${customerDeposit.serialNumber}` },
  ]

  return (
    <Block>
      <Breadcrumbs items={breadcrumbs} />
      <Segment title={`Клиентский депозит #${customerDeposit.serialNumber}`} size="m">
        <Block fcnw g={30}>
          <CustomerDepositInfo customerDeposit={customerDeposit} />
          {customerDeposit.traderPaymentMethodData && (
            <Segment title="Реквизиты" size="m">
              <PaymentMethodCard paymentMethod={customerDeposit.traderPaymentMethodData} />
            </Segment>
          )}
          {customerDeposit.status === 'completed' && <Segment title="Сделка успешно завершена" size="m" />}
          {customerDeposit.status === 'cancelled' && (
            <Segment
              title="Сделка была отменена"
              size="m"
              desc={toHumanCustomerDepositCancellationReason(
                customerDeposit.cancellationCode,
                customerDeposit.cancellationMessage
              )}
            />
          )}
          {['processing', 'verifying', 'cancelled'].includes(customerDeposit.status) && (
            <Segment
              title="Спор не начат"
              size="m"
              desc="Если мёрчант прислал чек от клиента, откройте спор и приложите чек в чат или в телеграм"
            >
              <DispiteStart customerDeposit={customerDeposit} />
            </Segment>
          )}
          {customerDeposit.status === 'disputing' && (
            <Segment title="Разрешите спор" size="m" desc={customerDeposit.disputeStartMessage}>
              <DisputeDecision customerDeposit={customerDeposit} />
            </Segment>
          )}
          <Chat
            chatId={customerDeposit.chatId}
            externalChatLink={customerDeposit.trader?.externalChatLink ?? undefined}
            viewerType="admin"
          />
          <Segment title="Связанные транзакции" size="m">
            <Txs viewerType="admin" dealId={customerDeposit.id} />
          </Segment>
          <Segment title="Связанные логи" size="m">
            <ActionLogs dealId={customerDeposit.id} />
          </Segment>
          <Segment title="Логи автоматора во время открытой сделки" size="m">
            <TraderAutomatorLogsTable logs={customerDeposit.automatorLogs} />
          </Segment>
        </Block>
      </Segment>
    </Block>
  )
})
