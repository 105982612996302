import { pageData } from './pageData.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { RichText } from '@/webapp/src/components/other/RichText/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { Block, Segment } from '@/webapp/src/lib/uninty.components.js'

export const HomePage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
})(() => {
  return (
    <Block fcnw>
      <Segment title="Добро пожаловать!" size="m">
        <RichText>
          <p>
            У нас особые условия для высокорисковых проектов, таких как обменники. Мы предлагаем уникальный и надежный
            сервис процессинга с использованием P2P технологии.
          </p>
          <p>Наши ключевые преимущества:</p>
          <ul>
            <li>
              Возможность работы с разнообразными ГЕО, включая Россию, Казахстан, Узбекистан, Тайланд, Индию и другие по
              запросу.
            </li>
            {/* <li>Поддержка большого количества валют — RUB, EUR, GBP, KZT, TRY, AZN, INR, UZS, IDR и другие.</li>*/}
            <li>
              Наш платежный шлюз обеспечивает максимальную стабильность и высокую проходимость транзакций с конверсией
              до 80%.
            </li>
            <li>Мы даем лучшую ставку в любой стране!</li>
            <li>Функциональный личный кабинет и круглосуточная поддержка в чате.</li>
            <li>Сеттелмент в USDT/РУБ КОДЕ.</li>
            <li>
              Мы учитываем индивидуальные потребности каждого проекта, а также предлагаем гибкие условия и приятные
              лимиты на минимальные и максимальные платежи.
            </li>
          </ul>
          <p>Наши услуги также включают автоматические массовые выплаты и предлагают превосходную защиту.</p>
          <p>
            Наша цель — долгосрочное сотрудничество и построение партнерских отношений. Для дополнительной информации и
            ответов на все ваши вопросы, пожалуйста, пишите в телеграм
          </p>
        </RichText>
      </Segment>
    </Block>
  )
})
