import { amountStringToIntegerWithDecimals, integerWithDecimalsToAmountString } from '@/general/src/other/money.js'
import type { toClientTraderForAdmin } from '@/general/src/trader/utils.server.js'
import { Textfieldy } from '@/webapp/src/lib/formy.js'
import { Block } from '@/webapp/src/lib/uninty.components.js'
import { Divider, Fieldset, Text } from '@mantine/core'
import type { Formy } from 'svag-formy'

const toPercent = (value: bigint) => integerWithDecimalsToAmountString({ amount: value })
const fromPercent = (value: string) => BigInt(amountStringToIntegerWithDecimals({ amountString: value }))

export const InterestSharesFieldset = ({
  formy,
  trader,
  referrer,
  referrerError,
}: {
  formy: Formy
  trader: ReturnType<typeof toClientTraderForAdmin>
  referrer?: ReturnType<typeof toClientTraderForAdmin>
  referrerError?: any
}) => {
  const teamleadWithdraw = referrer?.customerWithdrawInterestShare || BigInt(0)
  const teamleadDeposit = referrer?.customerDepositInterestShare || BigInt(0)

  const companyWithdrawMax = teamleadWithdraw - trader.customerWithdrawInterestShare
  const companyDepositMax = teamleadDeposit - trader.customerDepositInterestShare

  const companyWithdrawCurrent = formy.values.customerWithdrawCompanyInterestShare || '0'
  const companyDepositCurrent = formy.values.customerDepositCompanyInterestShare || '0'

  const teamleadWithdrawCurrent = toPercent(companyWithdrawMax - fromPercent(companyWithdrawCurrent))
  const teamleadDepositCurrent = toPercent(companyDepositMax - fromPercent(companyDepositCurrent))

  return (
    <Fieldset
      legend={
        <Text size="lg" fw={700}>
          Условия сделок
        </Text>
      }
      variant="filled"
      style={{ maxWidth: 320 }}
    >
      <Block fcnw g={10}>
        <Textfieldy
          {...formy.getFieldProps('referrerSerialNumber')}
          label="Серийный номер тимлида"
          hint={
            <div>
              <Text size="xs">{`Процент тимлида с пополнения ${toPercent(teamleadDeposit)}%`}</Text>
              <Text size="xs">{`Процент тимлида с вывода ${toPercent(teamleadWithdraw)}%`}</Text>
            </div>
          }
          error={referrerError ? 'Такой тимлид не найден' : undefined}
        />

        <Divider label={<Text size="sm">Пополнение</Text>} labelPosition="left" />

        <Textfieldy label="Процент с пополнения" {...formy.getFieldProps('customerDepositInterestShare')} />
        {formy.values.referrerSerialNumber && (
          <Textfieldy
            {...formy.getFieldProps('customerDepositCompanyInterestShare')}
            // @ts-expect-error no number type
            type="number"
            step="0.1"
            min={0}
            max={Number(companyDepositMax) / 100}
            label={`Доля компании (максимум ${toPercent(companyDepositMax)}%)`}
            hint={
              <Text size="xs">{`${companyDepositCurrent.replace('.', ',')}% компании – ${teamleadDepositCurrent}% тимлиду`}</Text>
            }
          />
        )}

        <Divider label={<Text size="sm">Вывод средств</Text>} labelPosition="left" />

        <Textfieldy label="Процент с вывода" {...formy.getFieldProps('customerWithdrawInterestShare')} />
        {formy.values.referrerSerialNumber && (
          <Textfieldy
            {...formy.getFieldProps('customerWithdrawCompanyInterestShare')}
            // @ts-expect-error no number type
            type="number"
            step="0.1"
            min={0}
            max={Number(companyWithdrawMax) / 100}
            label={`Доля компании (максимум ${toPercent(companyWithdrawMax)}%)`}
            hint={
              <Text size="xs">{`${companyWithdrawCurrent.replace('.', ',')}% компании – ${teamleadWithdrawCurrent}% тимлиду`}</Text>
            }
          />
        )}
      </Block>
    </Fieldset>
  )
}
