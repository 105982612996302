/* eslint-disable no-alert */
import coffeeBrazil from './images/coffee-brazil.webp'
import coffeeEthiopia from './images/coffee-ethiopia.webp'
import css from './index.module.scss'
import { useAppContext } from '@/webapp/src/lib/ctx.js'
import { useState } from 'react'

const buy = async (opts: { backendUrl: string; amount: number; idempotencyKey: string; paymentMethodType: string }) => {
  try {
    const body = {
      idempotencyKey: opts.idempotencyKey,
      amount: opts.amount,
      paymentMethodType: opts.paymentMethodType,
    }

    const url = new URL('/api/demo/create-customer-deposit/v1', opts.backendUrl)
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    const data = await res.json()

    if (data.redirectUrl) {
      window.location.href = data.redirectUrl
    } else {
      throw new Error(data.error ?? 'No redirect url')
    }
  } catch (error) {
    console.error(error)
    alert((error instanceof Error && error.message) || 'Ошибка!')
  }
}

const ProductCard = (props: {
  name: string
  photoUrl: string
  price: number
  idempotencyKey: string
  paymentMethodType: string
}) => {
  const { env } = useAppContext()

  return (
    <div className={css.productCard}>
      <img width={150} height={150} src={props.photoUrl} alt="Coffee pack" />
      <div className={css.content}>
        <h3 className={css.title}>{props.name}</h3>
        <div className={css.price}>{props.price} ₽</div>
        <div className={css.buttonContainer}>
          <button
            className={css.button}
            onClick={async () => {
              try {
                await buy({
                  backendUrl: env.VITE_BACKEND_URL,
                  amount: props.price * 100,
                  idempotencyKey: props.idempotencyKey,
                  paymentMethodType: props.paymentMethodType,
                })
              } catch (error) {
                console.error(error)
              }
            }}
          >
            Купить
          </button>
        </div>
      </div>
    </div>
  )
}

const paymentMethods = [
  {
    name: 'sberbankCard',
    title: 'Сбербанк карта',
  },
  {
    name: 'tinkoffCard',
    title: 'Тинькофф карта',
  },
  {
    name: 'alfabankCard',
    title: 'Альфа-Банк карта',
  },
  {
    name: 'raiffaizenbankCard',
    title: 'Райффайзенбанк карта',
  },
  {
    name: 'uralsibbankCard',
    title: 'Уралсиб Банк карта',
  },
  {
    name: 'ozonbankCard',
    title: 'Озон Банк карта',
  },
  {
    name: 'tsifrabankCard',
    title: 'Цифра банк карта',
  },
  {
    name: 'akbarsCard',
    title: 'Ак Барс карта',
  },
  {
    name: 'gazprombankCard',
    title: 'Газпромбанк карта',
  },
  {
    name: 'otpbankCard',
    title: 'ОТП-банк карта',
  },
  {
    name: 'vtbCard',
    title: 'ВТБ карта',
  },
  {
    name: 'bcsbankCard',
    title: 'БКС карта',
  },
  {
    name: 'rosselkhozbankCard',
    title: 'Россельхозбанк карта',
  },
]

const PaymentMethod = (props: {
  name: string
  title: string
  setPaymentMethodType: (name: string) => void
  checked: boolean
}) => {
  return (
    <label className={css.paymentMethod}>
      <input
        type="radio"
        name="paymentMethod"
        value={props.name}
        onClick={() => props.setPaymentMethodType(props.name)}
        checked={props.checked}
      />
      {props.title}
    </label>
  )
}

export const DemoPage = () => {
  const [paymentMethodType, setPaymentMethodType] = useState('sberbankCard')

  return (
    <div className={css.pageContainer}>
      <div className={css.app}>
        <h1 className={css.pageTitle}>Coffee Shop Demo</h1>
        <h2 className={css.subtitle}>Мой платёжный метод</h2>
        <div className={css.paymentMethods}>
          {paymentMethods.map((method) => (
            <PaymentMethod
              key={method.name}
              name={method.name}
              title={method.title}
              setPaymentMethodType={setPaymentMethodType}
              checked={paymentMethodType === method.name}
            />
          ))}
        </div>
        <h2 className={css.subtitle}>Товары</h2>
        <ProductCard
          name="Бразилия Коликоли"
          photoUrl={coffeeBrazil}
          price={50}
          idempotencyKey={Math.random().toString()}
          paymentMethodType={paymentMethodType}
        />
        <ProductCard
          name="Эфиопия Дара"
          photoUrl={coffeeEthiopia}
          price={1_000}
          idempotencyKey={Math.random().toString()}
          paymentMethodType={paymentMethodType}
        />
      </div>
    </div>
  )
}

export const DemoSuccessPage = () => {
  return (
    <div className={css.pageContainer}>
      <div className={css.app}>
        <h1 className={css.pageTitle}>Успешно!</h1>
      </div>
    </div>
  )
}

export const DemoFailPage = () => {
  return (
    <div className={css.pageContainer}>
      <div className={css.app}>
        <h1 className={css.pageTitle}>Ошибка!</h1>
      </div>
    </div>
  )
}
