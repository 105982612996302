import type { ClientCustomerDepositForAdmin } from '@/general/src/customerDeposit/utils.server.js'
import { toHumanCustomerDepositStatus } from '@/general/src/customerDeposit/utils.shared.js'
import { integerWithDecimalsToAmountString, toMoney } from '@/general/src/other/money.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { useFormy } from '@/webapp/src/lib/formy.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { MoneyTextInput } from '@/webapp/src/lib/ui/MoneyTextInput/index.js'
import { FormItems, LabeledValues } from '@/webapp/src/lib/uninty.components.js'
import { ActionIcon, Box } from '@mantine/core'
import { differenceInMinutes, formatDate } from 'date-fns'
import { useCallback, useState } from 'react'
import { MdEdit, MdSave } from 'react-icons/md'
import { z } from 'zod'

const EditableDealAmount = ({ customerDeposit }: { customerDeposit: ClientCustomerDepositForAdmin }) => {
  const [isEditing, setIsEditing] = useState(false)
  const editCustomerDepositForAdmin = trpc.editCustomerDepositForAdmin.useMutation()
  const trpcUtils = trpc.useUtils()

  const setIsEditingTrue = useCallback(() => {
    setIsEditing(true)
  }, [setIsEditing])

  const formy = useFormy({
    initialValues: {
      isValid: true,
      amount: customerDeposit.dealAmount,
    },
    validationSchema: z.object({
      isValid: z.boolean(),
      amount: z.bigint(),
    }),
    async onSubmit({ valuesOutput }) {
      const { customerDeposit: updatedCustomerDeposit } = await editCustomerDepositForAdmin.mutateAsync({
        customerDepositId: customerDeposit.id,
        dealAmount: valuesOutput.amount,
      })
      trpcUtils.getCustomerDepositForAdmin.setData(
        { customerDepositSerialNumber: customerDeposit.serialNumber },
        { customerDeposit: updatedCustomerDeposit }
      )
      void trpcUtils.getTxsForAdmin.refetch({ dealId: customerDeposit.id })

      // Use setTimeout to update data before hiding form
      // to avoid amount rerender
      setTimeout(() => {
        setIsEditing(false)
      })
    },
    successMessage: 'Сохранено',
  })

  return (
    <Box w={150} display="flex">
      {isEditing ? (
        <FormItems as="form" {...formy.formProps}>
          <Box w={150} display="flex">
            <MoneyTextInput
              amount={customerDeposit.dealAmount}
              onAmountChange={({ amount, isValid }) => {
                void formy.setFieldValue('amount', amount)
                void formy.setFieldValue('isValid', isValid)
              }}
            />
            <ActionIcon size="input-sm" ml={5} variant="filled" aria-label="Сохранить сумму" type="submit">
              <MdSave />
            </ActionIcon>
          </Box>
        </FormItems>
      ) : (
        <>
          {toMoney({ amount: customerDeposit.dealAmount, currency: customerDeposit.dealCurrency })}
          <ActionIcon ml={15} variant="filled" aria-label="Редактировать сумму" onClick={setIsEditingTrue}>
            <MdEdit />
          </ActionIcon>
        </>
      )}
    </Box>
  )
}

export const CustomerDepositInfo = ({ customerDeposit }: { customerDeposit: ClientCustomerDepositForAdmin }) => {
  return (
    <LabeledValues
      $style={{
        labelColor: 'var(--mantine-color-text)',
        hintColor: 'var(--mantine-color-text)',
      }}
      valuesEmptyPolicy="hide"
      items={[
        ['Статус', toHumanCustomerDepositStatus(customerDeposit.status)],
        ['Сумма', <EditableDealAmount key="dealAmount" customerDeposit={customerDeposit} />],
        [
          'Платёжный метод',
          customerDeposit.paymentMethodType && toHumanPaymentMethodType(customerDeposit.paymentMethodType),
        ],
        ['Сумма в USDT', toMoney({ amount: customerDeposit.amountUsdt, currency: 'usdt' })],
        [
          'Прибыль компании в USDT',
          !!customerDeposit.companyProfitUsdt &&
            toMoney({ amount: customerDeposit.companyProfitUsdt, currency: 'usdt' }),
        ],
        [
          'Прибыль компании в %',
          !!customerDeposit.companyProfitShare &&
            integerWithDecimalsToAmountString({ amount: customerDeposit.companyProfitShare }) + '%',
        ],
        ['Мерчант', customerDeposit.merchant && `#${customerDeposit.merchant.serialNumber}`],
        [
          'Комиссия мерчанта в USDT',
          !!customerDeposit.merchantFeeUsdt && toMoney({ amount: customerDeposit.merchantFeeUsdt, currency: 'usdt' }),
        ],
        [
          'Комиссия мерчанта в %',
          !!customerDeposit.merchantFeeShare &&
            integerWithDecimalsToAmountString({ amount: customerDeposit.merchantFeeShare }) + '%',
        ],
        ['Трейдер', customerDeposit.trader && `#${customerDeposit.trader.serialNumber}`],
        [
          'Вознаграждение трейдера в USDT',
          !!customerDeposit.traderInterestUsdt &&
            toMoney({ amount: customerDeposit.traderInterestUsdt, currency: 'usdt' }),
        ],
        [
          'Вознаграждение трейдера в %',
          !!customerDeposit.traderInterestShare &&
            integerWithDecimalsToAmountString({ amount: customerDeposit.traderInterestShare }) + '%',
        ],
        ['Создание', formatDate(customerDeposit.createdAt, 'dd.MM.yyyy HH:mm')],
        ['Взят в работу', customerDeposit.pickedAt && formatDate(customerDeposit.pickedAt, 'dd.MM.yyyy HH:mm')],
        ['Оплачено', customerDeposit.declaredAt && formatDate(customerDeposit.declaredAt, 'dd.MM.yyyy HH:mm')],
        ['Проверено', customerDeposit.completedAt && formatDate(customerDeposit.completedAt, 'dd.MM.yyyy HH:mm')],
        ['Отменено', customerDeposit.cancelledAt && formatDate(customerDeposit.cancelledAt, 'dd.MM.yyyy HH:mm')],
        [
          'Начало спора',
          customerDeposit.disputeStartedAt && formatDate(customerDeposit.disputeStartedAt, 'dd.MM.yyyy HH:mm'),
        ],
        [
          'Завершение спора',
          customerDeposit.disputeCompletedAt && formatDate(customerDeposit.disputeCompletedAt, 'dd.MM.yyyy HH:mm'),
        ],
        [
          'Отмена спора',
          customerDeposit.disputeCancelledAt && formatDate(customerDeposit.disputeCancelledAt, 'dd.MM.yyyy HH:mm'),
        ],
        [
          'Длительность в минутах',
          customerDeposit.finishedAt && differenceInMinutes(customerDeposit.finishedAt, customerDeposit.createdAt),
        ],
        ['ID', customerDeposit.id],
        ['ID мерчанта', customerDeposit.externalId],
      ]}
    />
  )
}
