import { createMoneyThings } from 'svag-money'
import { z } from 'zod'

export const {
  toMoney,
  zCurrency,
  zAmountIntegerWithDecimalsLimited,
  integerWithDecimalsToAmountString,
  amountStringToIntegerWithDecimals,
} = createMoneyThings({
  currencies: ['rub', 'usdt'],
  defaultSymbolPosition: 'after',
  defaultSymbolDelimiter: ' ',
})
export type Currency = z.output<typeof zCurrency>

// берем из инпута 0,01 = это 1% = 100
export const zNumericStringAmountLimited = (min: number, max: number) =>
  z
    .string()
    .trim()
    .refine((val) => val !== '', { message: `Обязательное поле. Значение должно быть от ${min / 100} до ${max / 100}` })
    .transform((val) => Number(val.replace(',', '.')))
    .refine((val) => !isNaN(val), { message: 'Введите число' })
    .transform((val) => Math.round(val * 100))
    .refine((val) => val >= min && val <= max, {
      message: `Значение должно быть от ${min / 100} до ${max / 100}`,
    })
