import type {
  TraderInsuranceWithdrawCancellationCode,
  TraderInsuranceWithdrawStatus as PrismaTraderInsuranceWithdrawStatus,
} from '@/backend/src/services/other/prisma.js'
import type { IsEqual } from 'type-fest'
import { z } from 'zod'

export const traderInsuranceWithdrawStatuses = [
  'picking',
  'processing',
  'completed',
  'cancelled',
  'disputing',
] as const satisfies PrismaTraderInsuranceWithdrawStatus[]
export const zTraderInsuranceWithdrawStatus = z.enum(traderInsuranceWithdrawStatuses)
export type TraderInsuranceWithdrawStatus = (typeof traderInsuranceWithdrawStatuses)[number]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const check1: IsEqual<TraderInsuranceWithdrawStatus, PrismaTraderInsuranceWithdrawStatus> = true

export const toHumanTraderInsuranceWithdrawStatus = (status: TraderInsuranceWithdrawStatus) => {
  return {
    picking: 'Поиск исполнителя',
    processing: 'Ожидание одобрения',
    completed: 'Завершён',
    cancelled: 'Отменён',
    disputing: 'Спор',
  }[status]
}

export const toHumanTraderInsuranceWithdrawCancellationCode = (
  code: TraderInsuranceWithdrawCancellationCode | null
) => {
  if (!code) {
    return 'Сделка не отменена'
  }
  return {
    picking_trader_manual: 'Трейдер отменил сделку на этапе подбора исполнителя',
    disputing_admin_disputeCompletion: 'Спор завершён администратором в пользу платформы',
  }[code]
}

export const toHumanTraderInsuranceWithdrawCancellationReason = (
  code: TraderInsuranceWithdrawCancellationCode | null,
  message: string | null
) => {
  const humanCode = toHumanTraderInsuranceWithdrawCancellationCode(code)
  if (!message) {
    return humanCode
  }
  return `${humanCode}: ${message}`
}
