import { pageData } from './pageData.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { Block, Segment } from '@/webapp/src/lib/uninty.components.js'

export const TraderTraderWithdrawListPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedTradersOnly: true,
})(() => {
  const breadcrumbs = [{ title: 'Трейдерские виздравы' }]

  return (
    <Block fcnw>
      <Breadcrumbs items={breadcrumbs} rootRole="trader" />
      <Segment title="Мои трейдерские виздравы" size="m">
        <p>На данный момент чтобы вывести свои деньги с платформы, вам нужно участвовать в клиентских депозитах</p>
      </Segment>
    </Block>
  )
})
