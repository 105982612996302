import { pageData } from './pageData.js'
import { ActionLogs } from '@/general/src/actionLog/components/ActionLogs/index.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { Block, Segment } from '@/webapp/src/lib/uninty.components.js'

const breadcrumbs = [{ title: 'Логи' }]

export const AdminActionLogListPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
})(() => {
  return (
    <Block fcnw>
      <Breadcrumbs items={breadcrumbs} />
      <Segment title="Логи" size="m">
        <ActionLogs />
      </Segment>
    </Block>
  )
})
