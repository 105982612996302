import { pageData } from './pageData.js'
import { ActionLogs } from '@/general/src/actionLog/components/ActionLogs/index.js'
import { Chat } from '@/general/src/chat/components/Chat/index.js'
import { toMoney } from '@/general/src/other/money.js'
import { toHumanTraderInsuranceDepositStatus } from '@/general/src/traderInsuranceDeposit/utils.shared.js'
import { Txs } from '@/general/src/tx/components/Txs/index.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminTraderInsuranceDepositViewRoute } from '@/webapp/src/lib/routes.js'
import * as routes from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, LabeledValues, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { formatDate } from 'date-fns'

export const AdminTraderInsuranceDepositViewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(adminTraderInsuranceDepositViewRoute)
    const queryResult = trpc.getTraderInsuranceDepositForAdmin.useQuery({
      traderInsuranceDepositSerialNumber: +routeParams.traderInsuranceDepositSerialNumber,
    })
    return queryResult
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    traderInsuranceDeposit: queryResult.data.traderInsuranceDeposit,
  }),
})(({ traderInsuranceDeposit }) => {
  const breadcrumbs = [
    { title: 'Трейдерские страховые депозиты', href: routes.adminTraderInsuranceDepositListRoute.get() },
    { title: `Страховой депозит #${traderInsuranceDeposit.serialNumber}` },
  ]

  return (
    <Block>
      <Breadcrumbs items={breadcrumbs} />
      <Segment title={`Трейдерский страховой депозит #${traderInsuranceDeposit.serialNumber}`} size="m">
        <Block fcnw g={30}>
          <LabeledValues
            $style={{
              labelColor: 'var(--mantine-color-text)',
              hintColor: 'var(--mantine-color-text)',
            }}
            valuesEmptyPolicy="hide"
            items={[
              ['Статус', toHumanTraderInsuranceDepositStatus(traderInsuranceDeposit.status)],
              ['Сумма в USDT', toMoney({ amount: traderInsuranceDeposit.amountUsdt, currency: 'usdt' })],
              ['Трейдер', `#${traderInsuranceDeposit.trader.serialNumber}`],
              ['Создание', formatDate(traderInsuranceDeposit.createdAt, 'dd.MM.yyyy HH:mm')],
            ]}
          />
        </Block>
        <Chat chatId={traderInsuranceDeposit.chatId} viewerType="admin" />
        <Segment title="Связанные транзакции" size="m">
          <Txs viewerType="admin" dealId={traderInsuranceDeposit.id} />
        </Segment>
        <Segment title="Связанные логи" size="m">
          <ActionLogs dealId={traderInsuranceDeposit.id} />
        </Segment>
      </Segment>
    </Block>
  )
})
