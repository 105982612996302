import type { SendNotificationFromAutomatorEndpointBodyInput } from '@/general/src/automator/routes/sendNotificationFromAutomatorV1/route.rest.js'
import type { AppContext } from '@/webapp/src/lib/ctx.js'
import { callRestRoute } from '@/webapp/src/lib/rest/index.js'

export interface AutomatorAuthData {
  token: string
  host: string
}

export const sendNotificationFromAutomator = async (
  ctx: AppContext,
  automatorAuthData: AutomatorAuthData,
  bodyInput: SendNotificationFromAutomatorEndpointBodyInput
) => {
  const headers = {
    Authorization: `Bearer ${automatorAuthData.token}`,
  }

  try {
    const response = await callRestRoute({
      baseUrl: ctx.env.VITE_BACKEND_URL,
      path: '/api/automator/send-notification-from-automator/v1',
      method: 'post',
      headers,
      body: bodyInput,
    })

    return response
  } catch (error) {
    console.error(error)

    return error
  }
}
