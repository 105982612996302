import css from './index.module.css'
import type {
  ClientCustomerDepositForAdmin,
  ClientCustomerDepositForTrader,
} from '@/general/src/customerDeposit/utils.server.js'
import { Tooltip } from '@mantine/core'
import cn from 'classnames'
import { TbHexagonLetterAFilled } from 'react-icons/tb'

export const CustomerDepositStatusIcon = ({
  data,
}: {
  data: ClientCustomerDepositForAdmin | ClientCustomerDepositForTrader
}) => {
  const isStatusCompleted = data.status === 'completed'

  if (!isStatusCompleted || !data?.completedBy) {
    return null
  }

  const tooltipText =
    data?.completedBy === 'automator' ? 'Сделка была закрыта автоматически' : 'Сделка была закрыта вручную'

  const iconCss = cn({
    [css.automatorIcon]: true,
    [css.completedByTrader]: data?.completedBy !== 'automator',
    [css.completedAutomatically]: data?.completedBy === 'automator',
  })

  return (
    <Tooltip label={tooltipText}>
      <div className={css.iconWrapper}>
        <TbHexagonLetterAFilled size={24} className={iconCss} />
      </div>
    </Tooltip>
  )
}
