import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { zUpdateCompanyForAdminEndpointInput } from '@/general/src/company/routes/updateCompanyForAdmin/input.js'
import { Switchy, Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'

export const CompanySettings = ({ company }: { company: TrpcRouterOutput['getCompanyForAdmin']['company'] }) => {
  const updateCompanyForAdmin = trpc.updateCompanyForAdmin.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      available: company.available,
      traderDepositFeeUsdt: company.traderDepositFeeUsdt,
    },
    validationSchema: zUpdateCompanyForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const res = await updateCompanyForAdmin.mutateAsync({
        ...valuesInput,
      })
      trpcUtils.getCompanyForAdmin.setData(undefined, res)
    },
    successMessage: 'Настройки обновлены',
  })
  return (
    <Segment title="Другие настройки" size="m">
      <FormItems as="form" {...formy.formProps}>
        <Textfieldy {...formy.getFieldProps('traderDepositFeeUsdt')} label="Комиссия на трейдерские депозиты, USDT" />
        <Switchy
          {...formy.getFieldProps('available')}
          label="Доступность сервиса"
          optionLabel="Да"
          hint="Если выключить доступность, то никто кроме админов не сможет пользоваться сервисом"
        />
        <Buttons>
          <Button {...formy.buttonProps} type="submit">
            Сохранить
          </Button>
        </Buttons>
      </FormItems>
    </Segment>
  )
}
