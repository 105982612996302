import type { WebhookEventName as PrismaWebhookEventName } from '@/backend/src/services/other/prisma.js'
import type { IsEqual } from 'type-fest'
import { z } from 'zod'

export const webhookEventNames = [
  'customerDepositCreated',
  'customerDepositCompleted',
  'customerDepositUpdated',
  'customerWithdrawCreated',
  'customerWithdrawCompleted',
  'customerWithdrawRefunded',
] as const
export const zWebhookEventName = z.enum(webhookEventNames)
export const zWebhookEventNames = z.array(zWebhookEventName)
export type WebhookEventName = (typeof webhookEventNames)[number]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const check1: IsEqual<WebhookEventName, PrismaWebhookEventName> = true

export const toHumanWebhookEventName = (eventName: WebhookEventName) => {
  return {
    customerDepositCreated: 'Создание клиентского депозита',
    customerDepositCompleted: 'Исполнение клиентского депозита',
    customerDepositUpdated: 'Обновление клиентского депозита',
    customerWithdrawCreated: 'Создание клиентского виздрава',
    customerWithdrawCompleted: 'Исполнение клиентского виздрава',
    customerWithdrawRefunded: 'Возврат клиентского виздрава',
  }[eventName]
}

export const toFullHumanWebhookEventName = (eventName: WebhookEventName) => {
  return `${toHumanWebhookEventName(eventName)} (${eventName})`
}

export const webhookEventNameOptions = webhookEventNames.map((eventName) => ({
  value: eventName,
  label: toFullHumanWebhookEventName(eventName),
}))
