import css from './index.module.css'
import { useAppContext } from '@/webapp/src/lib/ctx.js'
import * as routes from '@/webapp/src/lib/routes.js'
import { Anchor, Breadcrumbs as MantineBreadcrumbs } from '@mantine/core'
import { TbChevronRight } from 'react-icons/tb'

interface Crumb {
  title: string
  href?: string
}

type Role = 'admin' | 'trader' | 'merchant'

const rootTitles: Record<Role, string> = {
  admin: 'Дашборд админа',
  trader: 'Дашборд трейдера',
  merchant: 'Дашборд мерчанта',
}

const rootRoutes: Record<Role, () => string> = {
  admin: routes.adminDashboardRoute.get,
  trader: routes.traderDashboardRoute.get,
  merchant: routes.merchantDashboardRoute.get,
}

const getRootCrumb = (role: Role, singleUser: boolean): Crumb => ({
  title: singleUser ? 'Дашборд' : rootTitles[role],
  href: rootRoutes[role](),
})

const renderCrumb = ({ title, href }: Crumb) =>
  href ? (
    <Anchor key={href} href={href} className={css.crumb}>
      {title}
    </Anchor>
  ) : (
    <span key={title} className={css.crumbActive}>
      {title}
    </span>
  )

interface BreadcrumbsProps {
  items: Crumb[]
  rootRole?: Role
}

export const Breadcrumbs = ({ items = [], rootRole = 'admin' }: BreadcrumbsProps) => {
  const { me } = useAppContext()
  const isSingleUser = Object.values(me).filter(Boolean).length === 1
  const allCrumbs = [getRootCrumb(rootRole, isSingleUser), ...items]

  return (
    <MantineBreadcrumbs separator={<TbChevronRight className={css.crumbSeparator} />} mb="md">
      {allCrumbs.map(renderCrumb)}
    </MantineBreadcrumbs>
  )
}
