import css from './index.module.scss'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { useAppContext } from '@/webapp/src/lib/ctx.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Segment } from '@/webapp/src/lib/uninty.components.js'
import { Accordion } from '@mantine/core'

export const WebhooksDocsPage = withPageWrapper({
  title: 'Документация по вебхукам',
  Layout: GeneralLayout,
  useQuery: () => {
    const { me } = useAppContext()
    return trpc.getMyWebhookConfig.useQuery(undefined, {
      enabled: !!me.merchantMember,
    })
  },
  setProps: ({ queryResult }) => {
    return {
      webhookConfig: queryResult.data?.webhookConfig,
    }
  },
})(({ webhookConfig }) => {
  const ctx = useAppContext()
  return (
    <Segment title="Документация по вебхукам" size="m">
      <div className={css.page}>
        <div className={css.desc}>
          <p>
            В мерчантской на странице с вебхуками вы можете настроить урл на который будут приходить реквесты, когда
            будут происходить следующие события:
            <br />
            <br />
            Создание клиентского депозита (customerDepositCreated)
            <br />
            Исполнение клиентского депозита (customerDepositFinished) — момент, когда деньги от клиента поступили на
            счёт трейдера платформы. В этот момент вам нужно программно зачислить деньги на счёт вашего клиента в вашей
            системе. Сумма платежа это "customerDeposit.dealAmount", валюта платежа это "customerDeposit.dealCurrency".
            <br />
            Создание клиентского виздрава (customerWithdrawCreated)
            <br />
            Исполнение клиентского виздрава (customerWithdrawFinished) — момент, когда деньги от трейдера поступили на
            счёт вашего клиента. В этот момент вы должны прогаммно списать деньги со счёта вашего клиента в вашей
            системе. Сумма платежа это "customerWithdraw.dealAmount", валюта платежа это
            "customerWithdraw.dealCurrency".
            <br />
            Исполнение клиентского виздрава (customerWithdrawRefunded) — момент, когда было оспорено исполнение
            клиентского виздрава. В этом случае деньги возвращаются на счёт мерчанта внутри платформы. А вам программно
            на своей стороне нужно в личном кабинете вашего клиента вернуть ему деньги. Сумма платежа это
            "customerWithdraw.dealAmount", валюта платежа это "customerWithdraw.dealCurrency".
          </p>
          <p>
            Примеры тел запросов будут описаны ниже. Но надо учитывать, что мы также отправляем хедер x-webhook-hash в
            котором передаём хеш от тела запроса и вашего секретного ключа: sha256(webhookSecret + '|' + rawBody). Это
            сделано для того, чтобы вы могли проверить, что запрос пришёл от нас. webhookSecret вы можете найти в
            настройках вебхуков в мерчантской.
          </p>
          <p>
            Мы ожидаем от вас ответ 200 в статусе. Если будет люой другой статус мы будем пытаться отправить запрос
            снова и снова, каждые 5 минут, суммарно 10 раз включая первую попытку, то есть в течение 45 минут. Потом
            перестанем отправлять.
          </p>
          <p>Если вы авторизованы в мерчантской, то в примере кода вы будете видеть ваш реальный webhookSecret.</p>
        </div>

        <Accordion className={css.codeExampleAccordion}>
          <Accordion.Item className={css.codeExampleAccordionItem} value="1">
            <Accordion.Control className={css.codeExampleTitle}>Пример кода на PHP</Accordion.Control>
            <Accordion.Panel>
              <pre className={css.codeExampleSelf}>
                {`<?php

// Get the provided webhook hash from headers
$providedWebhookHash = isset($_SERVER['HTTP_X_WEBHOOK_HASH']) ? $_SERVER['HTTP_X_WEBHOOK_HASH'] : '';

// Define the webhook secret
$webhookSecret = '${webhookConfig?.webhookSecret || 'YOUR_WEBHOOK_SECRET'}';

// Get the request body
$requestBody = file_get_contents('php://input');
$parsedBody = json_decode($requestBody, true);

// Create the initial string
$initialString = $webhookSecret . '|' . $requestBody;

// Calculate the desired webhook hash
$desiredWebhookHash = hash('sha256', $initialString);

// Check if the provided webhook hash matches the desired webhook hash
if ($providedWebhookHash !== $desiredWebhookHash) {
    // Send a 401 response if the hashes don't match
    http_response_code(401);
    echo json_encode(['error' => 'Invalid Webhook hash']);
    exit;
}

// Process the event if the hash is valid
if (isset($parsedBody['eventName']) && $parsedBody['eventName'] === 'customerDepositFinished') {
    // Handle the event here...
}

// Send a 200 response if everything is OK
http_response_code(200);
echo json_encode(['ok' => true]);
?>`}
              </pre>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <Accordion className={css.codeExampleAccordion}>
          <Accordion.Item className={css.codeExampleAccordionItem} value="1">
            <Accordion.Control className={css.codeExampleTitle}>
              Пример тела запроса: customerDepositCreated
            </Accordion.Control>
            <Accordion.Panel>
              <pre className={css.codeExampleSelf}>
                {JSON.stringify(
                  {
                    event: {
                      env: ctx.env.HOST_ENV,
                      id: 'ce47fafd-2a44-4e71-a2aa-c3a6fc37ca9e',
                      eventName: 'customerDepositCreated',
                      createdAt: '2024-08-13T11:49:21.833Z',
                    },
                    customerDeposit: {
                      id: '01914b92-40fe-7990-956c-2dab326c7f6d',
                      chatId: '46b25e20-87a1-4c7f-881d-7c4e2e01da3b',
                      status: 'picking',
                      trader: null,
                      dealUrl:
                        'https://example.com/customer/deposit/01914b92-40fe-7990-956c-2dab326c7f6d/bb6c0f85-d671-4e1d-bab6-467c4de6f3f8',
                      failUrl: 'https://google.com?id=01914b92-40fe-7990-956c-2dab326c7f6d',
                      isActive: true,
                      pickedAt: null,
                      createdAt: '2024-08-13T11:49:21.788Z',
                      amountUsdt: 1_079,
                      dealAmount: 100_000,
                      declaredAt: null,
                      finishedAt: null,
                      successUrl: 'https://yandex.ru?id=01914b92-40fe-7990-956c-2dab326c7f6d',
                      cancelledAt: null,
                      completedAt: null,
                      completedBy: null,
                      dealCurrency: 'rub',
                      serialNumber: 5,
                      merchantAmount: 100_000,
                      merchantFeeUsdt: 43,
                      cancellationCode: null,
                      disputeStartedAt: null,
                      merchantCurrency: 'rub',
                      merchantFeeShare: 400,
                      paymentMethodType: 'sberbankCard',
                      disputeCancelledAt: null,
                      disputeCompletedAt: null,
                      paymentMethodGroup: 'card',
                      cancellationMessage: null,
                      disputeStartMessage: null,
                      dealCurrencyInOneUsdt: 9_262,
                      merchantCurrencyInOneUsdt: 9_262,
                      traderPaymentMethodProvider: null,
                      willBeCancelledBecauseNotPickedAt: '2024-08-13T12:09:21.790Z',
                      willBeCancelledBecauseNotDeclaredAt: null,
                    },
                  },
                  null,
                  2
                )}
              </pre>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <Accordion className={css.codeExampleAccordion}>
          <Accordion.Item className={css.codeExampleAccordionItem} value="1">
            <Accordion.Control className={css.codeExampleTitle}>
              Пример тела запроса: customerDepositCompleted
            </Accordion.Control>
            <Accordion.Panel>
              <pre className={css.codeExampleSelf}>
                {JSON.stringify(
                  {
                    event: {
                      env: ctx.env.HOST_ENV,
                      id: '242a269c-2de2-440a-82e5-c14ba7d776ca',
                      eventName: 'customerDepositCompleted',
                      createdAt: '2024-08-13T11:54:55.964Z',
                    },
                    customerDeposit: {
                      id: '01914b92-40fe-7990-956c-2dab326c7f6d',
                      chatId: '46b25e20-87a1-4c7f-881d-7c4e2e01da3b',
                      status: 'completed',
                      trader: {
                        id: '0efa518a-73d5-4349-86ca-f32adc46f6d8',
                        serialNumber: 8,
                      },
                      dealUrl:
                        'https://example.com/customer/deposit/01914b92-40fe-7990-956c-2dab326c7f6d/bb6c0f85-d671-4e1d-bab6-467c4de6f3f8',
                      failUrl: 'https://google.com?id=01914b92-40fe-7990-956c-2dab326c7f6d',
                      isActive: false,
                      pickedAt: '2024-08-13T11:53:58.635Z',
                      createdAt: '2024-08-13T11:49:21.788Z',
                      amountUsdt: 1_079,
                      dealAmount: 100_000,
                      declaredAt: '2024-08-13T11:54:45.252Z',
                      finishedAt: '2024-08-13T11:54:55.925Z',
                      successUrl: 'https://yandex.ru?id=01914b92-40fe-7990-956c-2dab326c7f6d',
                      cancelledAt: null,
                      completedAt: '2024-08-13T11:54:55.925Z',
                      completedBy: 'automator',
                      dealCurrency: 'rub',
                      serialNumber: 5,
                      merchantAmount: 100_000,
                      merchantFeeUsdt: 43,
                      cancellationCode: null,
                      disputeStartedAt: null,
                      merchantCurrency: 'rub',
                      merchantFeeShare: 400,
                      paymentMethodType: 'sberbankCard',
                      disputeCancelledAt: null,
                      disputeCompletedAt: null,
                      paymentMethodGroup: 'card',
                      cancellationMessage: null,
                      disputeStartMessage: null,
                      dealCurrencyInOneUsdt: 9_262,
                      merchantCurrencyInOneUsdt: 9_262,
                      traderPaymentMethodProvider: 'sberbank',
                      willBeCancelledBecauseNotPickedAt: null,
                      willBeCancelledBecauseNotDeclaredAt: null,
                    },
                  },
                  null,
                  2
                )}
              </pre>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <Accordion className={css.codeExampleAccordion}>
          <Accordion.Item className={css.codeExampleAccordionItem} value="1">
            <Accordion.Control className={css.codeExampleTitle}>
              Пример тела запроса: customerWithdrawCreated
            </Accordion.Control>
            <Accordion.Panel>
              <pre className={css.codeExampleSelf}>
                {JSON.stringify(
                  {
                    event: {
                      env: ctx.env.HOST_ENV,
                      id: '3b2a981e-48d2-4e5f-9177-66d052d988e7',
                      eventName: 'customerWithdrawCreated',
                      createdAt: '2024-08-13T11:52:42.359Z',
                    },
                    customerWithdraw: {
                      id: '01914b95-5032-7990-956c-328f7d348943',
                      chatId: '3285d77e-c2e5-4b0b-8c24-301cbfabbaaf',
                      status: 'picking',
                      trader: null,
                      dealUrl:
                        'https://example.com/customer/withdraw/view/01914b95-5032-7990-956c-328f7d348943/eddf976d-f931-45db-bf00-5aced7b52ca9',
                      failUrl: 'https://google.com?id=01914b95-5032-7990-956c-328f7d348943',
                      isActive: true,
                      pickedAt: null,
                      createdAt: '2024-08-13T11:52:42.285Z',
                      amountUsdt: 1_082,
                      dealAmount: 100_000,
                      finishedAt: null,
                      refundedAt: null,
                      successUrl: 'https://yandex.ru?id=01914b95-5032-7990-956c-328f7d348943',
                      cancelledAt: null,
                      completedAt: null,
                      dealCurrency: 'rub',
                      serialNumber: 2,
                      merchantAmount: 100_000,
                      merchantFeeUsdt: 64,
                      cancellationCode: null,
                      disputeStartedAt: null,
                      merchantCurrency: 'rub',
                      merchantFeeShare: 600,
                      paymentMethodType: 'sberbankCard',
                      disputeCancelledAt: null,
                      disputeCompletedAt: null,
                      paymentMethodGroup: 'card',
                      cancellationMessage: null,
                      disputeStartMessage: null,
                      dealCurrencyInOneUsdt: 9_238,
                      merchantCurrencyInOneUsdt: 9_238,
                      customerPaymentMethodProvider: 'sberbank',
                      willBeCancelledBecauseNotPickedAt: '2024-08-13T12:12:42.307Z',
                    },
                  },
                  null,
                  2
                )}
              </pre>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <Accordion className={css.codeExampleAccordion}>
          <Accordion.Item className={css.codeExampleAccordionItem} value="1">
            <Accordion.Control className={css.codeExampleTitle}>
              Пример тела запроса: customerWithdrawCompleted
            </Accordion.Control>
            <Accordion.Panel>
              <pre className={css.codeExampleSelf}>
                {JSON.stringify(
                  {
                    event: {
                      env: ctx.env.HOST_ENV,
                      id: 'ba229485-a5c8-495e-8730-bf6c07c345da',
                      eventName: 'customerWithdrawCompleted',
                      createdAt: '2024-08-13T11:55:31.936Z',
                    },
                    customerWithdraw: {
                      id: '01914b95-5032-7990-956c-328f7d348943',
                      chatId: '3285d77e-c2e5-4b0b-8c24-301cbfabbaaf',
                      status: 'completed',
                      trader: {
                        id: '0efa518a-73d5-4349-86ca-f32adc46f6d8',
                        serialNumber: 8,
                      },
                      dealUrl:
                        'https://example.com/customer/withdraw/view/01914b95-5032-7990-956c-328f7d348943/eddf976d-f931-45db-bf00-5aced7b52ca9',
                      failUrl: 'https://google.com?id=01914b95-5032-7990-956c-328f7d348943',
                      isActive: false,
                      pickedAt: '2024-08-13T11:55:23.109Z',
                      createdAt: '2024-08-13T11:52:42.285Z',
                      amountUsdt: 1_082,
                      dealAmount: 100_000,
                      finishedAt: '2024-08-13T11:55:31.884Z',
                      refundedAt: null,
                      successUrl: 'https://yandex.ru?id=01914b95-5032-7990-956c-328f7d348943',
                      cancelledAt: null,
                      completedAt: '2024-08-13T11:55:31.884Z',
                      dealCurrency: 'rub',
                      serialNumber: 2,
                      merchantAmount: 100_000,
                      merchantFeeUsdt: 64,
                      cancellationCode: null,
                      disputeStartedAt: null,
                      merchantCurrency: 'rub',
                      merchantFeeShare: 600,
                      paymentMethodType: 'sberbankCard',
                      disputeCancelledAt: null,
                      disputeCompletedAt: null,
                      paymentMethodGroup: 'card',
                      cancellationMessage: null,
                      disputeStartMessage: null,
                      dealCurrencyInOneUsdt: 9_238,
                      merchantCurrencyInOneUsdt: 9_238,
                      customerPaymentMethodProvider: 'sberbank',
                      willBeCancelledBecauseNotPickedAt: '2024-08-13T12:12:42.307Z',
                    },
                  },
                  null,
                  2
                )}
              </pre>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <Accordion className={css.codeExampleAccordion}>
          <Accordion.Item className={css.codeExampleAccordionItem} value="1">
            <Accordion.Control className={css.codeExampleTitle}>
              Пример тела запроса: customerWithdrawRefunded
            </Accordion.Control>
            <Accordion.Panel>
              <pre className={css.codeExampleSelf}>
                {JSON.stringify(
                  {
                    event: {
                      env: ctx.env.HOST_ENV,
                      id: '8f74474f-6079-442e-95b1-c6c960ba9121',
                      eventName: 'customerWithdrawRefunded',
                      createdAt: '2024-08-13T11:56:06.481Z',
                    },
                    customerWithdraw: {
                      id: '01914b95-5032-7990-956c-328f7d348943',
                      chatId: '3285d77e-c2e5-4b0b-8c24-301cbfabbaaf',
                      status: 'refunded',
                      trader: { id: '0efa518a-73d5-4349-86ca-f32adc46f6d8', serialNumber: 8 },
                      dealUrl:
                        'https://example.com/customer/withdraw/view/01914b95-5032-7990-956c-328f7d348943/eddf976d-f931-45db-bf00-5aced7b52ca9',
                      failUrl: 'https://google.com?id=01914b95-5032-7990-956c-328f7d348943',
                      isActive: false,
                      pickedAt: '2024-08-13T11:55:23.109Z',
                      createdAt: '2024-08-13T11:52:42.285Z',
                      amountUsdt: 1_082,
                      dealAmount: 100_000,
                      finishedAt: '2024-08-13T11:55:31.884Z',
                      refundedAt: '2024-08-13T11:56:06.448Z',
                      successUrl: 'https://yandex.ru?id=01914b95-5032-7990-956c-328f7d348943',
                      cancelledAt: null,
                      completedAt: '2024-08-13T11:55:31.884Z',
                      dealCurrency: 'rub',
                      serialNumber: 2,
                      merchantAmount: 100_000,
                      merchantFeeUsdt: 64,
                      cancellationCode: null,
                      disputeStartedAt: '2024-08-13T11:55:54.849Z',
                      merchantCurrency: 'rub',
                      merchantFeeShare: 600,
                      paymentMethodType: 'sberbankCard',
                      disputeCancelledAt: null,
                      disputeCompletedAt: '2024-08-13T11:56:06.448Z',
                      paymentMethodGroup: 'card',
                      cancellationMessage: null,
                      disputeStartMessage: 'dewc',
                      dealCurrencyInOneUsdt: 9_238,
                      merchantCurrencyInOneUsdt: 9_238,
                      customerPaymentMethodProvider: 'sberbank',
                      willBeCancelledBecauseNotPickedAt: '2024-08-13T12:12:42.307Z',
                    },
                  },
                  null,
                  2
                )}
              </pre>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </div>
    </Segment>
  )
})
