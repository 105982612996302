import css from './index.module.css'
import type { ExchangeSourceValues } from '@/general/src/merchant/utils.shared.js'
import { exchangeSourceLabels } from '@/general/src/merchant/utils.shared.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { SegmentedControl, Text } from '@mantine/core'
import { useState } from 'react'
import type { Formy } from 'svag-formy'

export const ExchangeRateControl = ({ formy }: { formy: Formy }) => {
  const [currentExchangeSourceValue, setExchangeSource] = useState<ExchangeSourceValues>(formy.values.exchangeSource)
  const exchangesRateQueryResult = trpc.getCurrencyExchangeRate.useQuery({
    source: currentExchangeSourceValue,
  })

  const exchangeSourceLabel: string = exchangeSourceLabels[currentExchangeSourceValue]
  const exchangeRate = Number(exchangesRateQueryResult.data?.rubInOneUsdt) / 100

  return (
    <div className={css.fieldWrapper}>
      <Text size="md" mb={3}>
        Откуда брать курс
      </Text>
      <SegmentedControl
        {...formy.getFieldProps('exchangeSource')}
        color="blue"
        data={[
          { label: 'Bybit', value: 'bybit' },
          { label: 'Garantex', value: 'garantex' },
        ]}
        value={formy.values.exchangeSource}
        onChange={(value) => {
          setExchangeSource(value as ExchangeSourceValues)
          void formy.setFieldValue('exchangeSource', value)
        }}
      />
      <Text size="xs" ta="center">
        Курс на {exchangeSourceLabel}: {!exchangesRateQueryResult.isFetched ? 'загрузка...' : `1 ₮ = ${exchangeRate} ₽`}
      </Text>
    </div>
  )
}
