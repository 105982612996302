import { pageData } from './pageData.js'
import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { ActionLogs } from '@/general/src/actionLog/components/ActionLogs/index.js'
import { Chat } from '@/general/src/chat/components/Chat/index.js'
import { toMoney } from '@/general/src/other/money.js'
import { PaymentMethodCard } from '@/general/src/paymentMethod/PaymentMethod.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { zDisputeMakeTraderDepositForAdminEndpointInput } from '@/general/src/traderDeposit/routes/disputeMakeTraderDepositForAdmin/input.js'
import { zVerifyTraderDepositForAdminEndpointInput } from '@/general/src/traderDeposit/routes/verifyTraderDepositForAdmin/input.js'
import {
  toHumanTraderDepositCancellationReason,
  toHumanTraderDepositStatus,
} from '@/general/src/traderDeposit/utils.shared.js'
import { Txs } from '@/general/src/tx/components/Txs/index.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminTraderDepositViewRoute } from '@/webapp/src/lib/routes.js'
import * as routes from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, LabeledValues, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { differenceInMinutes, formatDate } from 'date-fns'
import { useEffect, useState } from 'react'

const Verify = ({
  traderDeposit,
}: {
  traderDeposit: TrpcRouterOutput['getTraderDepositForAdmin']['traderDeposit']
}) => {
  const verifyTraderDepositForAdmin = trpc.verifyTraderDepositForAdmin.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      traderDepositId: traderDeposit.id,
    },
    validationSchema: zVerifyTraderDepositForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { traderDeposit } = await verifyTraderDepositForAdmin.mutateAsync(valuesInput)
      trpcUtils.getTraderDepositForAdmin.setData(
        { traderDepositSerialNumber: traderDeposit.serialNumber },
        { traderDeposit }
      )
    },
    successMessage: 'Трейдерский депозит успешно завершён',
    resetOnSuccess: false,
  })
  if (!traderDeposit.companyPaymentMethodData) {
    return <p>Нет платёжных методов, обратитесь в администрацию</p>
  }
  return (
    <FormItems as="form" {...formy.formProps}>
      <PaymentMethodCard
        paymentMethod={traderDeposit.companyPaymentMethodData}
        garantexCode={traderDeposit.garantexCode}
      />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Деньги получены
        </Button>
      </Buttons>
    </FormItems>
  )
}

const DisputeMake = ({
  traderDeposit,
}: {
  traderDeposit: TrpcRouterOutput['getTraderDepositForAdmin']['traderDeposit']
}) => {
  const disputeMakeTraderDepositForAdmin = trpc.disputeMakeTraderDepositForAdmin.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      traderDepositId: traderDeposit.id,
      message: '',
    },
    validationSchema: zDisputeMakeTraderDepositForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { traderDeposit } = await disputeMakeTraderDepositForAdmin.mutateAsync(valuesInput)
      trpcUtils.getTraderDepositForAdmin.setData(
        { traderDepositSerialNumber: traderDeposit.serialNumber },
        { traderDeposit }
      )
    },
    successMessage: 'Трейдерский депозит успешно отменён через спор',
    resetOnSuccess: false,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Textfieldy {...formy.getFieldProps('message')} label="Причина спора" />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Отменить
        </Button>
      </Buttons>
    </FormItems>
  )
}

export const AdminTraderDepositViewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(adminTraderDepositViewRoute)
    const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
    const queryResult = trpc.getTraderDepositForAdmin.useQuery(
      {
        traderDepositSerialNumber: +routeParams.traderDepositSerialNumber,
      },
      {
        refetchInterval,
      }
    )
    const isActive = queryResult.data?.traderDeposit.isActive
    useEffect(() => {
      if (isActive) {
        setRefetchInterval(3_000)
      } else {
        setRefetchInterval(false)
      }
    }, [isActive])
    return queryResult
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    traderDeposit: queryResult.data.traderDeposit,
  }),
})(({ traderDeposit }) => {
  const breadcrumbs = [
    { title: 'Трейдерские депозиты', href: routes.adminTraderDepositListRoute.get() },
    { title: `Депозит #${traderDeposit.serialNumber}` },
  ]

  return (
    <Block>
      <Breadcrumbs items={breadcrumbs} />
      <Segment title={`Трейдерский депозит #${traderDeposit.serialNumber}`} size="m">
        <Block fcnw g={30}>
          <LabeledValues
            $style={{
              labelColor: 'var(--mantine-color-text)',
              hintColor: 'var(--mantine-color-text)',
            }}
            valuesEmptyPolicy="hide"
            items={[
              ['Статус', toHumanTraderDepositStatus(traderDeposit.status)],
              ['Сумма в USDT', toMoney({ amount: traderDeposit.amountUsdt, currency: 'usdt' })],
              ['Комиссия в USDT', toMoney({ amount: traderDeposit.feeUsdt, currency: 'usdt' })],
              [
                'Платёжный метод',
                traderDeposit.paymentMethodType && toHumanPaymentMethodType(traderDeposit.paymentMethodType),
              ],
              [
                'Номер кошелька',
                traderDeposit.companyPaymentMethodData?.group === 'crypto'
                  ? traderDeposit.companyPaymentMethodData.wallet
                  : null,
              ],
              ['Код гарантекс', traderDeposit.garantexCode],
              ['Админ', traderDeposit.admin?.serialNumber && `#${traderDeposit.admin.serialNumber}`],
              ['Трейдер', `#${traderDeposit.trader.serialNumber}`],
              ['Создание', formatDate(traderDeposit.createdAt, 'dd.MM.yyyy HH:mm')],
              ['Взят в работу', traderDeposit.pickedAt && formatDate(traderDeposit.pickedAt, 'dd.MM.yyyy HH:mm')],
              ['Оплачено', traderDeposit.declaredAt && formatDate(traderDeposit.declaredAt, 'dd.MM.yyyy HH:mm')],
              ['Проверено', traderDeposit.completedAt && formatDate(traderDeposit.completedAt, 'dd.MM.yyyy HH:mm')],
              ['Отменено', traderDeposit.cancelledAt && formatDate(traderDeposit.cancelledAt, 'dd.MM.yyyy HH:mm')],
              [
                'Начало спора',
                traderDeposit.disputeStartedAt && formatDate(traderDeposit.disputeStartedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Завершение спора',
                traderDeposit.disputeCompletedAt && formatDate(traderDeposit.disputeCompletedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Отмена спора',
                traderDeposit.disputeCancelledAt && formatDate(traderDeposit.disputeCancelledAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Длительность в минутах',
                traderDeposit.finishedAt && differenceInMinutes(traderDeposit.finishedAt, traderDeposit.createdAt),
              ],
            ]}
          />
          {traderDeposit.status === 'picking' ? (
            <Segment
              title="Ваша задача"
              size="m"
              desc="Перейти на админский дашборд и взяться за этот трейдерский депозит"
            />
          ) : traderDeposit.status === 'processing' ? (
            <Segment title="Ваша задача" size="m" desc="Дождаться пока трейдер сделает перевод" />
          ) : traderDeposit.status === 'verifying' ? (
            <>
              <Segment
                title="Ваша задача"
                size="m"
                desc="Проверить, что деньги от трейдера пришли и подтвердить получение"
              >
                <Verify traderDeposit={traderDeposit} />
              </Segment>
              <Segment
                title="Отмена через спор"
                size="m"
                desc="Если деньги не пришли, и вы не смогли договориться в чате, тогда отменяйте сделку"
              >
                <DisputeMake traderDeposit={traderDeposit} />
              </Segment>
            </>
          ) : traderDeposit.status === 'completed' ? (
            <Segment title="Сделка успешно завершена" size="m" />
          ) : traderDeposit.status === 'cancelled' ? (
            <Segment
              title="Отмена через спор"
              size="m"
              desc={toHumanTraderDepositCancellationReason(
                traderDeposit.cancellationCode,
                traderDeposit.cancellationMessage
              )}
            />
          ) : null}
          <Chat chatId={traderDeposit.chatId} viewerType="admin" />
          <Segment title="Связанные транзакции" size="m">
            <Txs viewerType="admin" dealId={traderDeposit.id} />
          </Segment>
          <Segment title="Связанные логи" size="m">
            <ActionLogs dealId={traderDeposit.id} />
          </Segment>
        </Block>
      </Segment>
    </Block>
  )
})
