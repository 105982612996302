import { toWritableDeep } from '../other/type-helpers.js'

export const smsSettings = [
  {
    senderExample: '+79170119030',
    textExample: 'Вам пришли деньги как-то 1234,56 от С. Бом',
    senderPattern: '^\\+79170119030$',
    checkPattern: '^Вам пришли деньги как-то',
    amountPattern: '^Вам пришли деньги как-то ({money})',
    namePattern: '^Вам пришли деньги как-то {money} от (.+)$',
    paymentMethodTypes: ['sberbankCard', 'sberbankSbp'],
    paymentMethodProvider: 'sberbank',
  },
  {
    senderExample: '+79170119030',
    textExample: 'Вам пришли деньги на карту 1234,56 от С. Бом',
    senderPattern: '^\\+79170119030$',
    checkPattern: '^Вам пришли деньги на карту',
    amountPattern: '^Вам пришли деньги на карту ({money})',
    namePattern: '^Вам пришли деньги на карту {money} от (.+)$',
    paymentMethodTypes: ['sberbankCard'],
    paymentMethodProvider: 'sberbank',
  },
  {
    senderExample: '+79170119030',
    textExample: 'Вам пришли деньги по СБП 1234,56 от С. Бом',
    senderPattern: '^\\+79170119030$',
    checkPattern: '^Вам пришли деньги по СБП',
    amountPattern: '^Вам пришли деньги по СБП ({money})',
    namePattern: '^Вам пришли деньги по СБП {money} от (.+)$',
    paymentMethodTypes: ['sberbankSbp'],
    paymentMethodProvider: 'sberbank',
  },
  // Sberbank SMS
  {
    senderExample: '+79170119030',
    textExample: 'СЧЁТ9394 20:12 Перевод 1234,56р от Сбо М. Баланс: 49.14р',
    senderPattern: '^\\+79170119030$',
    checkPattern: 'Перевод {money}р от ',
    amountPattern: 'Перевод ({money})р',
    namePattern: 'от ([^\\.]+)',
    paymentMethodTypes: ['sberbankCard'],
    paymentMethodProvider: 'sberbank',
  },
  {
    senderExample: '+79170119031',
    textExample: 'СЧЁТ7475 20:17 Перевод из Газпромбанк +1234,56р от СБО М. Баланс: 38.14р',
    senderPattern: '^\\+79170119031$',
    checkPattern: 'Перевод из [^ ]+ \\+{money}р от ',
    amountPattern: '\\+({money})р',
    namePattern: 'от ([^\\.]+)',
    paymentMethodTypes: ['sberbankCard'],
    paymentMethodProvider: 'sberbank',
  },
  {
    senderExample: '+79170119032',
    textExample: 'MIR-8418 20:18 зачисление 1234,56р СБО М Баланс: 60.14р',
    senderPattern: '^\\+79170119032$',
    checkPattern: 'зачисление {money}р',
    amountPattern: 'зачисление ({money})р',
    namePattern: 'зачисление {money}р (.+?) Баланс:',
    paymentMethodTypes: ['sberbankCard'],
    paymentMethodProvider: 'sberbank',
  },
  {
    senderExample: '+79170119032',
    textExample: 'MIR-8418 20:20 Перевод 1234,56р от Сбо М. Баланс: 65.14р',
    senderPattern: '^\\+79170119032$',
    checkPattern: 'Перевод {money}р от ',
    amountPattern: 'Перевод ({money})р',
    namePattern: 'от ([^\\.]+)',
    paymentMethodTypes: ['sberbankCard'],
    paymentMethodProvider: 'sberbank',
  },
  {
    senderExample: '+79170119032',
    textExample: 'Сбер.счёт *6454 Зачисление 1234,56р Баланс: 273 127р',
    senderPattern: '^\\+79170119032$',
    checkPattern: 'Зачисление {money}р',
    amountPattern: 'Зачисление ({money})р',
    namePattern: '',
    paymentMethodTypes: ['sberbankCard'],
    paymentMethodProvider: 'sberbank',
  },
  {
    senderExample: '+79170119032',
    textExample: 'СЧЁТ2127 12:38 перевод 1234.56р Баланс: 4017.11р',
    senderPattern: '^\\+79170119032$',
    checkPattern: 'перевод {money} ?р?',
    amountPattern: 'перевод ({money})р',
    namePattern: '',
    paymentMethodTypes: ['sberbankCard', 'sberbankSbp'],
    paymentMethodProvider: 'sberbank',
  },
  {
    senderExample: '+79170119032',
    textExample: 'Сбер.счёт *1331 Зачисление 1234.56р Баланс: 11 980р',
    senderPattern: '^\\+79170119032$',
    checkPattern: 'Зачисление {money} ?р?',
    amountPattern: 'Зачисление ({money}) ?р?',
    namePattern: '',
    paymentMethodTypes: ['sberbankCard', 'sberbankSbp'],
    paymentMethodProvider: 'sberbank',
  },
  {
    senderExample: '+79170119032',
    textExample: 'СЧЁТ9973 00:47 зачисление 1234.56р со вклада Баланс: 10.96р',
    senderPattern: '^\\+79170119032$',
    checkPattern: 'зачисление {money} ?р?',
    amountPattern: 'зачисление ({money}) ?р?',
    namePattern: '',
    paymentMethodTypes: ['sberbankCard', 'sberbankSbp'],
    paymentMethodProvider: 'sberbank',
  },
  {
    senderExample: '+79170119032',
    textExample: 'MIR-1234 21:33 Перевод 1234.56р от Сбо М. Баланс: 900р',
    senderPattern: '^\\+79170119032$',
    checkPattern: 'Перевод {money} ?р?',
    amountPattern: 'Перевод ({money}) ?р?',
    namePattern: 'от ([А-ЯЁа-яёA-Za-z]+ [А-ЯЁA-Z]\\.)',
    paymentMethodTypes: ['sberbankCard', 'sberbankSbp'],
    paymentMethodProvider: 'sberbank',
  },
  // ДомРФ SMS
  {
    senderExample: '+79170119033',
    textExample: 'Пополнение +1234.56 RUB на счет **1688 по СБП от СБО М. успешно. Доступно 10.00 RUB',
    senderPattern: '^\\+79170119033$',
    checkPattern: 'Пополнение \\+{money} RUB',
    amountPattern: '\\+({money}) RUB',
    namePattern: 'от (.+?) успешно',
    paymentMethodTypes: ['bankdomrfSbp'],
    paymentMethodProvider: 'bankdomrf',
  },
  // Gazprombank
  {
    senderExample: '+79170119034',
    senderPattern: '^\\+79170119034$',
    textExample: '*9679 Получен перевод 1234.56р C2C R-ONLINE MIR ANDRO Доступно 158.27р',
    checkPattern: 'Получен перевод {money}р',
    amountPattern: 'Получен перевод ({money})р',
    namePattern: '',
    paymentMethodTypes: ['gazprombankCard'],
    paymentMethodProvider: 'gazprombank',
  },
  {
    senderExample: '+79170119034',
    senderPattern: '^\\+79170119034$',
    textExample: '*9679 Получен перевод 1234,56р SBP C2C ZACHISLENIE Доступно 58.27р',
    checkPattern: 'Получен перевод {money}р',
    amountPattern: 'Получен перевод ({money})р',
    namePattern: '',
    paymentMethodTypes: ['gazprombankCard'],
    paymentMethodProvider: 'gazprombank',
  },
  // Alfa-Bank
  {
    senderExample: '+79170119035',
    senderPattern: '^\\+79170119035$',
    textExample: 'Поступление 1234.56 RUR по СБП от СБО М.',
    checkPattern: 'Поступление {money} RUR',
    amountPattern: 'Поступление ({money}) RUR',
    namePattern: 'от (.+)',
    paymentMethodTypes: ['alfabankSbp'],
    paymentMethodProvider: 'alfabank',
  },
  {
    senderExample: '+79170119035',
    senderPattern: '^\\+79170119035$',
    textExample: 'Пополнение *6083 на 1234.56 RUR Баланс: 97,41 RUR',
    checkPattern: 'Пополнение \\*\\d+ на {money} RUR',
    amountPattern: 'на ({money}) RUR',
    namePattern: '',
    paymentMethodTypes: ['alfabankCard'],
    paymentMethodProvider: 'alfabank',
  },
  // T-Bank
  {
    senderExample: '+79170119036',
    senderPattern: '^\\+79170119036$',
    textExample: 'Пополнение, счет RUB. 1234.56 RUB. Доступно 130 RUB',
    checkPattern: 'Пополнение.*?{money} RUB',
    amountPattern: '\\b({money}) RUB',
    namePattern: '',
    paymentMethodTypes: ['tinkoffCard', 'tinkoffSbp'],
    paymentMethodProvider: 'tinkoff',
  },
  {
    senderExample: '+79170119036',
    senderPattern: '^\\+79170119036$',
    textExample: 'Пополнение, счет RUB. 1234.56 RUB. Сбо М. Доступно 130 RUB',
    checkPattern: 'Пополнение.*?{money} RUB',
    amountPattern: '\\b({money}) RUB',
    namePattern: '[0-9]+[.,][0-9]{1,2} RUB\\. (.+?) Доступно',
    paymentMethodTypes: ['tinkoffCard', 'tinkoffSbp'],
    paymentMethodProvider: 'tinkoff',
  },
  // OTP Bank
  {
    senderExample: '+79170119037',
    senderPattern: '^\\+79170119037$',
    textExample: 'Карта *9985 зачисление 1234,56р. Доступно 100р. otpbank.ru/tr',
    checkPattern: 'зачисление {money}р',
    amountPattern: 'зачисление ({money})р',
    namePattern: '',
    paymentMethodTypes: ['otpbankCard'],
    paymentMethodProvider: 'otpbank',
  },
  // VTB
  {
    senderExample: '+79170119038',
    senderPattern: '^\\+79170119038$',
    textExample: 'Поступление 1234,56р Счет*6444 от СБО М. Баланс 50р 22:14',
    checkPattern: 'Поступление {money}р',
    amountPattern: 'Поступление ({money})р',
    namePattern: 'от (.+?) Баланс',
    paymentMethodTypes: ['vtbCard'],
    paymentMethodProvider: 'vtb',
  },
  // UralSib
  {
    senderExample: 'URALSIB',
    senderPattern: '^URALSIB$',
    textExample: 'Karta *6942. 30.12.24 13:51. Postuplenie 1234.56 RUB. CARD2CARD AMOBILE. Balans 300.00 RUB',
    checkPattern: 'Postuplenie {money} RUB',
    amountPattern: 'Postuplenie ({money}) RUB',
    namePattern: '',
    paymentMethodTypes: ['uralsibbankCard'],
    paymentMethodProvider: 'uralsibbank',
  },
  {
    senderExample: '+79170119039',
    senderPattern: '^\\+79170119039$',
    textExample: 'POSTUPLENIE SREDSTV NA SCHET: 1234.56 RUR. 14.10.24 20:00. Ostatok 8011.88 RUR.',
    checkPattern: 'POSTUPLENIE SREDSTV NA SCHET: {money} RUR',
    amountPattern: 'POSTUPLENIE SREDSTV NA SCHET: ({money}) RUR',
    namePattern: '',
    paymentMethodTypes: ['uralsibbankCard'],
    paymentMethodProvider: 'uralsibbank',
  },
  {
    senderExample: '+79170119039',
    senderPattern: '^(\\+79170119039|URALSIB)$',
    textExample: 'Perevod SBP ot СБ О М. iz T-Bank. Summa 1234.56 RUR na schet *5344. Ispolnen 19.10.2024 18:24',
    checkPattern: 'Perevod SBP.*?Summa {money} RUR',
    amountPattern: 'Summa ({money}) RUR',
    namePattern: 'ot (.+?) iz',
    paymentMethodTypes: ['uralsibbankSbp'],
    paymentMethodProvider: 'uralsibbank',
  },
  // BCSBank
  {
    senderExample: '+79170119040',
    senderPattern: '^\\+79170119040$',
    textExample: 'Зачисление 1234.56 RUB Карта ****3577. Баланс 20.00 RUB',
    checkPattern: 'Зачисление {money} RUB',
    amountPattern: 'Зачисление ({money}) RUB',
    namePattern: '',
    paymentMethodTypes: ['bcsbankCard'],
    paymentMethodProvider: 'bcsbank',
  },
  {
    senderExample: '+79170119040',
    senderPattern: '^\\+79170119040$',
    textExample: 'Зачислено через СБП 1234.56 RUB на счет *8340. Отправитель: СБ О М, Банк Т-Банк. Доступно: 20 RUB',
    checkPattern: 'Зачислено через СБП {money} RUB',
    amountPattern: 'Зачислено через СБП ({money}) RUB',
    namePattern: 'Отправитель: (.+?), Банк',
    paymentMethodTypes: ['bcsbankSbp'],
    paymentMethodProvider: 'bcsbank',
  },
  // Raiffeisen
  {
    senderExample: '+79170119041',
    senderPattern: '^\\+79170119041$',
    textExample: 'Перевели 1234.56 ₽ в Перевели по номеру карты с карты *2785',
    checkPattern: 'Перевели {money} ₽',
    amountPattern: 'Перевели ({money}) ₽',
    namePattern: '',
    paymentMethodTypes: ['raiffaizenbankCard'],
    paymentMethodProvider: 'raiffaizenbank',
  },
  {
    senderExample: '+79170119041',
    senderPattern: '^\\+79170119041$',
    textExample: 'Пополнили на 1234.56 RUB карту *2785',
    checkPattern: 'Пополнили на {money} RUB',
    amountPattern: 'Пополнили на ({money}) RUB',
    namePattern: '',
    paymentMethodTypes: ['raiffaizenbankCard'],
    paymentMethodProvider: 'raiffaizenbank',
  },
  {
    senderExample: '+79170119041',
    senderPattern: '^\\+79170119041$',
    textExample: 'Пришел перевод 1234.56 RUB от 79171040930 на счет *6721',
    checkPattern: 'Пришел перевод {money} RUB',
    amountPattern: 'Пришел перевод ({money}) RUB',
    namePattern: '',
    paymentMethodTypes: ['raiffaizenbankCard'],
    paymentMethodProvider: 'raiffaizenbank',
  },
  // Rosselkhozbank, RSHB
  {
    senderExample: '+79170119042',
    senderPattern: '^\\+79170119042$',
    textExample: 'Успешный перевод СБП. СБ О М из Сбербанк Зачислено 1234.56 RUR',
    checkPattern: 'Успешный перевод СБП.*?Зачислено {money} RUR',
    amountPattern: 'Зачислено ({money}) RUR',
    namePattern: 'СБП\\. (.+?) из',
    paymentMethodTypes: ['rosselkhozbankSbp'],
    paymentMethodProvider: 'rosselkhozbank',
  },
  {
    senderExample: '+79170119042',
    senderPattern: '^\\+79170119042$',
    textExample: '19:41 перевод 1234.56р на карту *5795 Баланс 30.83 TRANSFER, Tinkoff Card',
    checkPattern: '\\d{2}:\\d{2} перевод {money}р на карту \\*\\d+',
    amountPattern: 'перевод ({money})р',
    namePattern: '',
    paymentMethodTypes: ['rosselkhozbankCard'],
    paymentMethodProvider: 'rosselkhozbank',
  },
  // Rosbank
  {
    senderExample: '+79170119043',
    senderPattern: '^\\+79170119043$',
    textExample: 'Счет *0649. Перевод СБП из Т-Банк от СБ О М. успешный. Зачислено 1234.56 р. Баланс 41.03 р.',
    checkPattern: 'Перевод СБП.*?успешный.*?Зачислено {money} р',
    amountPattern: 'Зачислено ({money}) р',
    namePattern: 'от (.+?)\\. успешный',
    paymentMethodTypes: ['rosbankSbp'],
    paymentMethodProvider: 'rosbank',
  },
  // MTS Bank
  {
    senderExample: '+79170119044',
    senderPattern: '^\\+79170119044$',
    textExample: 'Поступление 1234.56р. СБ О М через СБП.',
    checkPattern: 'Поступление {money}р',
    amountPattern: 'Поступление ({money})р',
    namePattern: 'Поступление {money}р\\. (.+?) через',
    paymentMethodTypes: ['mtsbankSbp'],
    paymentMethodProvider: 'mtsbank',
  },
] as const

export const traderAutomatorSmsRules = toWritableDeep(
  smsSettings.map((settings) => ({
    ...settings,
    type: 'sms' as const,
  }))
)
