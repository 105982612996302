import type { TraderInsuranceDepositStatus as PrismaTraderInsuranceDepositStatus } from '@/backend/src/services/other/prisma.js'
import type { IsEqual } from 'type-fest'
import { z } from 'zod'

export const traderInsuranceDepositStatuses = ['completed'] as const
export const zTraderInsuranceDepositStatus = z.enum(traderInsuranceDepositStatuses)
export type TraderInsuranceDepositStatus = (typeof traderInsuranceDepositStatuses)[number]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const check1: IsEqual<TraderInsuranceDepositStatus, PrismaTraderInsuranceDepositStatus> = true

export const toHumanTraderInsuranceDepositStatus = (status: TraderInsuranceDepositStatus) => {
  return {
    completed: 'Завершён',
  }[status]
}
