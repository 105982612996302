import { pageData } from './pageData.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import {
  adminMerchantMemberEditRoute,
  adminMerchantMemberListRoute,
  adminMerchantMemberNewRoute,
} from '@/webapp/src/lib/routes.js'
import * as routes from '@/webapp/src/lib/routes.js'
import { SectionWrapper } from '@/webapp/src/lib/sectionWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, Segment, Table } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'

export const AdminMerchantMemberListPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(adminMerchantMemberListRoute)
    return trpc.getMerchantForAdmin.useQuery({ merchantSerialNumber: +routeParams.merchantSerialNumber })
  },
  setProps: ({ queryResult }) => ({
    merchant: queryResult.data.merchant,
  }),
})(({ merchant }) => {
  const breadcrumbs = [
    { title: 'Мерчанты', href: routes.adminMerchantListRoute.get() },
    { title: `Сотрудники мерчанта #${merchant.serialNumber}` },
  ]

  return (
    <Block fcnw>
      <Breadcrumbs items={breadcrumbs} />
      <Segment
        title={`Сотрудники мерчанта #${merchant.serialNumber} "${merchant.name}"`}
        size="m"
        desc={
          <Buttons>
            <Button
              as="a"
              className="buttonA"
              href={adminMerchantMemberNewRoute.get({ merchantSerialNumber: merchant.serialNumber })}
            >
              Добавить сотруника мерчанта
            </Button>
          </Buttons>
        }
      >
        <SectionWrapper
          useQuery={() =>
            trpc.getMerchantMembersForAdmin.useQuery({
              merchantSerialNumber: merchant.serialNumber,
            })
          }
          setProps={({ queryResult }) => ({
            merchantMembers: queryResult.data.merchantMembers,
          })}
        >
          {({ merchantMembers }) => {
            return (
              <Table
                className="unintyTable"
                records={merchantMembers}
                href={(record) =>
                  adminMerchantMemberEditRoute.get({
                    merchantMemberSerialNumber: record.serialNumber,
                    merchantSerialNumber: merchant.serialNumber,
                  })
                }
                columns={[
                  { heading: 'SN', body: (record) => record.serialNumber, width: 100 },
                  { heading: 'Имя', body: (record) => record.name, width: 200 },
                  { heading: 'Почта', body: (record) => record.email, width: 400 },
                  { heading: 'Телефон', body: (record) => record.phone, width: 200 },
                ]}
              />
            )
          }}
        </SectionWrapper>
      </Segment>
    </Block>
  )
})
