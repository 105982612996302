import { pageData } from './pageData.js'
import { toMoney } from '@/general/src/other/money.js'
import { toHumanTraderInsuranceWithdrawStatus } from '@/general/src/traderInsuranceWithdraw/utils.shared.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminTraderInsuranceWithdrawViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Segment, Table } from '@/webapp/src/lib/uninty.components.js'
import { formatDate } from 'date-fns/format'

const breadcrumbs = [{ title: 'Трейдерские страховые виздравы' }]

export const AdminTraderInsuranceWithdrawListPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
})(() => {
  const queryResult = trpc.getTraderInsuranceWithdrawsForAdmin.useInfiniteQuery(
    {},
    {
      getNextPageParam: (lastPage) => {
        return lastPage.nextCursor
      },
      refetchOnMount: true,
    }
  )
  const traderInsuranceWithdraws = queryResult.data?.pages.flatMap((page) => page.traderInsuranceWithdraws) || []
  return (
    <Block fcnw>
      <Breadcrumbs items={breadcrumbs} />
      <Segment
        title="Все трейдерские страховые виздравы"
        size="m"
        desc={
          <>
            Трейдерский страховой виздра — это способ вывода денег со страхового баланса трейдера на его свободный
            баланс
          </>
        }
      >
        {queryResult.isFetching ? (
          <p>Загрузка...</p>
        ) : !traderInsuranceWithdraws.length ? (
          <p>Здесь пусто</p>
        ) : (
          <Table
            className="unintyTable"
            records={traderInsuranceWithdraws}
            href={(record) =>
              adminTraderInsuranceWithdrawViewRoute.get({ traderInsuranceWithdrawSerialNumber: record.serialNumber })
            }
            columns={[
              { heading: 'SN', body: (record) => record.serialNumber, width: 100 },
              { heading: 'Статус', body: (record) => toHumanTraderInsuranceWithdrawStatus(record.status), width: 150 },
              {
                heading: 'Дата',
                body: (record) => formatDate(record.createdAt, 'dd.MM.yyyy HH:mm'),
                width: 150,
              },
              {
                heading: 'Сумма',
                body: (record) => toMoney({ amount: record.amount, currency: record.currency }),
                width: 100,
              },
              {
                heading: 'Сумма, ₮',
                body: (record) => toMoney({ amount: record.amountUsdt, currency: 'usdt' }),
                width: 100,
              },
            ]}
            loadMore={() => {
              if (!queryResult.isFetchingNextPage && queryResult.hasNextPage) {
                void queryResult.fetchNextPage()
              }
            }}
            hasMore={queryResult.hasNextPage}
          />
        )}
      </Segment>
    </Block>
  )
})
