import { pageData } from './pageData.js'
import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { zUpdateWebhookConfigForMerchantMemberEndpointInput } from '@/general/src/webhook/routes/updateWebhookConfigForMerchantMember/input.js'
import { toFullHumanWebhookEventName, webhookEventNameOptions } from '@/general/src/webhook/utils.shared.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { Checkboxesy, Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import {
  Block,
  Button,
  Buttons,
  FormItems,
  LabeledValues,
  Segment,
  Segments,
  Table,
} from '@/webapp/src/lib/uninty.components.js'
import { formatDate } from 'date-fns/format'

const breadcrumbs = [{ title: 'Настройки вебхуков' }]

const WebhookConfigEditor = ({
  webhookConfig,
}: {
  webhookConfig: TrpcRouterOutput['getMyWebhookConfig']['webhookConfig']
}) => {
  const updateWebhookConfigForMerchantMember = trpc.updateWebhookConfigForMerchantMember.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      url: webhookConfig.url,
      eventNames: webhookConfig.eventNames,
    },
    validationSchema: zUpdateWebhookConfigForMerchantMemberEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { webhookConfig } = await updateWebhookConfigForMerchantMember.mutateAsync(valuesInput)
      trpcUtils.getMyWebhookConfig.setData(undefined, { webhookConfig })
    },
    successMessage: 'Настройки вебхука обновлены',
    resetOnSuccess: false,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Textfieldy {...formy.getFieldProps('url')} label="URL" />
      <Checkboxesy {...formy.getFieldProps('eventNames')} label="События" options={webhookEventNameOptions} />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Сохранить изменения
        </Button>
      </Buttons>
    </FormItems>
  )
}

const WebhookEventsList = () => {
  const queryResult = trpc.getWebhookEventsForMerchantMember.useInfiniteQuery(
    {},
    {
      getNextPageParam: (lastPage) => {
        return lastPage.nextCursor
      },
      refetchOnMount: true,
    }
  )
  const webhookEvents = queryResult.data?.pages.flatMap((page) => page.webhookEvents) || []
  return (
    <>
      {queryResult.isLoading ? (
        <p>Загрузка...</p>
      ) : !webhookEvents.length ? (
        <p>Здесь пусто</p>
      ) : (
        <Table
          className="unintyTable"
          records={webhookEvents}
          columns={[
            {
              heading: 'Дата',
              body: (record) => formatDate(record.createdAt, 'dd.MM.yyyy HH:mm'),
              width: 150,
            },
            { heading: 'Событие', body: (record) => toFullHumanWebhookEventName(record.eventName), width: 350 },
            { heading: 'Попыток', body: (record) => record.attemptsCount, width: 100 },
            {
              heading: 'Статус',
              body: (record) => (record.succeededAt ? 'Успех' : record.cancelledAt ? 'Провал' : 'Продолжаем попытки'),
              width: 150,
            },
          ]}
          loadMore={() => {
            if (!queryResult.isFetchingNextPage && queryResult.hasNextPage) {
              void queryResult.fetchNextPage()
            }
          }}
          hasMore={queryResult.hasNextPage}
        />
      )}
    </>
  )
}

export const MerchantMerchantWebhooksPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedMerchantMembersOnly: true,
  useQueries: () => {
    return trpc.useQueries((t) => [t.getMe(), t.getMyMerchant(), t.getMyWebhookConfig()])
  },
  setProps: ({ getAuthorizedMerchantMemberMe, queriesResults }) => ({
    me: getAuthorizedMerchantMemberMe(),
    merchant: queriesResults[1].data.merchant,
    webhookConfig: queriesResults[2].data.webhookConfig,
  }),
})(({ webhookConfig }) => {
  return (
    <Block fcnw>
      <Breadcrumbs items={breadcrumbs} rootRole="merchant" />
      <Segment title="Настройки вебхуков" size="m">
        <Segments>
          <Segment title="Данные доступа" size="s">
            <LabeledValues
              $style={{
                labelColor: 'var(--mantine-color-text)',
                hintColor: 'var(--mantine-color-text)',
              }}
              items={[['Вебхук секрет', webhookConfig.webhookSecret]]}
            />
          </Segment>
          <Segment title="Управление вебхуком" size="s">
            <WebhookConfigEditor webhookConfig={webhookConfig} />
          </Segment>
          <Segment title="Отправленные эвенты" size="s">
            <WebhookEventsList />
          </Segment>
        </Segments>
      </Segment>
    </Block>
  )
})
