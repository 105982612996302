export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
export type HashParamType = 'body' | 'search'
export type HashParam = `${HashParamType}:${string}`

interface GetApiHashOptions {
  sha256: (str: string) => string | Promise<string>
  apiSecret: string
  method: HTTPMethod
  path: string
  hashParams: HashParam[]
  bodyInput?: any
  searchInput?: any
}

export const getApiHash = async (opts: GetApiHashOptions): Promise<string> => {
  const hashValues = opts.hashParams.map((param) => {
    const [paramType, key] = param.split(':')
    const input = {
      body: opts.bodyInput,
      search: opts.searchInput,
    }[paramType as HashParamType]
    return input?.[key] || ''
  })
  const initialString = [opts.apiSecret, opts.method, opts.path, ...hashValues].join('|')
  const desiredApiHash = await opts.sha256(initialString)
  return desiredApiHash
}
