import css from './index.module.scss'
import { ApiDocsCreateCustomerDeposit } from '@/general/src/api/docs/ApiDocsCreateCustomerDeposit.js'
import { ApiDocsCreateCustomerWithdraw } from '@/general/src/api/docs/ApiDocsCreateCustomerWithdraw.js'
import { ApiDocsGetPaymentMethods } from '@/general/src/api/docs/ApiDocsGetPaymentMethods.js'
import { ApiDocsGetStats } from '@/general/src/api/docs/ApiDocsGetStats.js'
import { ApiDocsH2HGetCustomerDeposit } from '@/general/src/api/docs/h2h/ApiDocsH2HGetCustomerDeposit.js'
import { ApiDocsH2HPatchCancelCustomerDeposit } from '@/general/src/api/docs/h2h/ApiDocsH2HPatchCancelCustomerDeposit.js'
import { ApiDocsH2HPostCustomerDeposit } from '@/general/src/api/docs/h2h/ApiDocsH2HPostCustomerDeposit.js'
import { ApiDocsH2HStartDisputeForCustomerDeposit } from '@/general/src/api/docs/h2h/ApiDocsH2HStartDisputeForCustomerDeposit.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { useAppContext } from '@/webapp/src/lib/ctx.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Segment } from '@/webapp/src/lib/uninty.components.js'

export const ApiDocsPage = withPageWrapper({
  title: 'Документация по API',
  useQuery: () => {
    const { me } = useAppContext()
    return trpc.getMyMerchant.useQuery(undefined, {
      enabled: !!me.merchantMember,
    })
  },
  Layout: GeneralLayout,
  setProps: ({ queryResult }) => {
    return {
      merchant: queryResult.data?.merchant,
    }
  },
})(({ merchant }) => {
  return (
    <Segment title="Документация по API" size="m">
      <Block fcnw g={10}>
        <p>
          В системе есть два вида API - <strong className={css.strong}>Мerchant</strong> и{' '}
          <strong className={css.strong}>H2H</strong>.
        </p>
        <p>
          <strong className={css.strong}>Мerchant API</strong> - нужен для интеграции магазинов, и разных сервисов на
          которых вы захотите подключить оплату.
        </p>

        <p>
          <strong className={css.strong}>H2H API</strong> - больше подходит для интеграции с платежными сервисами. С
          помощью данного API, владельцы платежных сервисов, могут добавить новый платежный шлюз p2p платежей.
        </p>

        <p>
          Основное различие в том, что H2H API предоставляет более широкий и гибкий функционал для интеграции чем
          Merchant API, а также дает больше методов для управления сделками и спорами. В свою очередь Merchant API более
          простой, вы можете создать сделку, а ее управлением и обработкой займется наш сервис. Важное замечание - для
          сделок созданных через H2H API не предоставляется платежная ссылка.
        </p>
        <p>
          Если вы авторизованы в мерчантской, то значения API-ключей и секретов подставятся в форме ниже автоматически.
          При отправке запроса (клик на кнопку "Отправить запрос") – будут отправлены реальные запросы на сервер прямо
          из вашего браузера.
        </p>
        <p>
          В запросах на создание клиенсткого депозита или создание клиентского виздрава – обязательно поле{' '}
          <strong className={css.strong}>idempotencyKey</strong>. Это поле, которое вы должны генерировать и хранить на
          своей стороне.
        </p>
        <Segment title="H2H API" h={2}>
          <ApiDocsH2HPostCustomerDeposit merchant={merchant} />
          <ApiDocsH2HGetCustomerDeposit merchant={merchant} />
          <ApiDocsH2HPatchCancelCustomerDeposit merchant={merchant} />
          <ApiDocsH2HStartDisputeForCustomerDeposit merchant={merchant} />
        </Segment>
        <Segment title="Merchant API" h={2}>
          <ApiDocsGetPaymentMethods merchant={merchant} />
          <ApiDocsGetStats merchant={merchant} />
          <ApiDocsCreateCustomerDeposit merchant={merchant} />
          <ApiDocsCreateCustomerWithdraw merchant={merchant} />
        </Segment>
      </Block>
    </Segment>
  )
})
