import { pageData } from './pageData.js'
import { PaymentMethodsEditor } from '@/general/src/paymentMethod/PaymentMethod.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Segment, Segments } from '@/webapp/src/lib/uninty.components.js'

export const MerchantMerchantSettingsPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedMerchantMembersOnly: true,
  useQuery: () => {
    return trpc.getMyMerchant.useQuery()
  },
  setProps: ({ queryResult }) => ({
    merchant: queryResult.data.merchant,
  }),
})(({ merchant }) => {
  const breadcrumbs = [{ title: 'Настройки мерчанта' }]

  return (
    <Block fcnw>
      <Breadcrumbs items={breadcrumbs} rootRole="merchant" />
      <Segment title="Настройки мерчанта" size="m">
        <Segments>
          <PaymentMethodsEditor
            updateMyPaymentMethodsTrpcRouteName="updateMerchantPaymentMethodsForMerchantMember"
            paymentMethods={merchant.paymentMethods}
          />
        </Segments>
      </Segment>
    </Block>
  )
})
