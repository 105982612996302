import { z } from 'zod'

export const merchantStatuses = ['new', 'processing', 'completed', 'cancelled'] as const
export const zMerchantStatus = z.enum(merchantStatuses)
export type MerchantStatus = (typeof merchantStatuses)[number]

export const toHumanMerchantStatus = (status: MerchantStatus) => {
  return {
    new: 'New',
    processing: 'Processing',
    completed: 'Completed',
    cancelled: 'Cancelled',
  }[status]
}

export const exchangeSourceLabels = {
  garantex: 'Garantex',
  bybit: 'Bybit',
} as const

export type ExchangeSourceValues = keyof typeof exchangeSourceLabels
