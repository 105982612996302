import { pageData } from './pageData.js'
import { zCreateAdminForAdminEndpointInput } from '@/general/src/admin/routes/createAdminForAdmin/input.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminAdminEditRoute } from '@/webapp/src/lib/routes.js'
import * as routes from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useState } from 'react'

const breadcrumbs = [{ title: 'Админы', href: routes.adminAdminListRoute.get() }, { title: 'Новый админ' }]

export const AdminAdminNewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
})(() => {
  const [newPassword, setNewPassword] = useState('')
  const [newAdminSerialNumber, setNewAdminSerialNumber] = useState<number>()

  const createAdminForAdmin = trpc.createAdminForAdmin.useMutation()
  const formy = useFormy({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      telegram: '',
    },
    validationSchema: zCreateAdminForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const res = await createAdminForAdmin.mutateAsync({
        ...valuesInput,
      })

      setNewPassword(res.admin.password)
      setNewAdminSerialNumber(res.admin.serialNumber)
    },
    successMessage: 'Админ создан',
    resetOnSuccess: true,
  })

  return (
    <Block fcnw>
      <Breadcrumbs items={breadcrumbs} />
      <Segment title="Новый админ" size="m">
        <FormItems as="form" {...formy.formProps}>
          <Textfieldy label="Имя" {...formy.getFieldProps('name')} />
          <Textfieldy label="Почта" {...formy.getFieldProps('email')} />
          <Textfieldy label="Телефон" {...formy.getFieldProps('phone')} />
          <Textfieldy label="Телеграм" {...formy.getFieldProps('telegram')} />
          <Buttons>
            <Button {...formy.buttonProps} type="submit">
              Создать
            </Button>
          </Buttons>

          {newPassword && newAdminSerialNumber && (
            <>
              <p>
                Пароль нового админа: <input type="text" value={newPassword} contentEditable={false} />
              </p>
              <p>
                Передайте пароль админу, которого вы добавили. Для безопасности, нужно, чтобы он его сменил на свой.
              </p>

              <p>
                <a href={adminAdminEditRoute.get({ adminSerialNumber: newAdminSerialNumber })}>
                  Перейти на страницу настроек нового админа
                </a>
              </p>
            </>
          )}
        </FormItems>
      </Segment>
    </Block>
  )
})
