import type { Falsey } from 'svag-types'
import type { Simplify, WritableDeep } from 'type-fest'

export class UnreachableCodeError extends Error {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public constructor(_: never) {
    super('Unreachable code reached')
  }
}

export const toWritableDeep = <T>(obj: T): Simplify<WritableDeep<T>> => {
  return obj as WritableDeep<T>
}

export const isTruthy = <T>(data: T): data is Exclude<T, Falsey> => {
  return !!data
}
