import { pageData } from './pageData.js'
import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { ActionLogs } from '@/general/src/actionLog/components/ActionLogs/index.js'
import { Chat } from '@/general/src/chat/components/Chat/index.js'
import { zCancelProcessingMerchantWithdrawForAdminEndpointInput } from '@/general/src/merchantWithdraw/routes/cancelProcessingMerchantWithdrawForAdmin/input.js'
import { zCompleteMerchantWithdrawForAdminEndpointInput } from '@/general/src/merchantWithdraw/routes/completeMerchantWithdrawForAdmin/input.js'
import { zDisputeCancelMerchantWithdrawForAdminEndpointInput } from '@/general/src/merchantWithdraw/routes/disputeCancelMerchantWithdrawForAdmin/input.js'
import { zDisputeCompleteMerchantWithdrawForAdminEndpointInput } from '@/general/src/merchantWithdraw/routes/disputeCompleteMerchantWithdrawForAdmin/input.js'
import {
  toHumanMerchantWithdrawCancellationReason,
  toHumanMerchantWithdrawStatus,
} from '@/general/src/merchantWithdraw/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import { PaymentMethodCard } from '@/general/src/paymentMethod/PaymentMethod.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { Txs } from '@/general/src/tx/components/Txs/index.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminMerchantWithdrawViewRoute } from '@/webapp/src/lib/routes.js'
import * as routes from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, LabeledValues, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { differenceInMinutes, formatDate } from 'date-fns'
import { useEffect, useState } from 'react'

const DisputeDecision = ({
  merchantWithdraw,
}: {
  merchantWithdraw: TrpcRouterOutput['getMerchantWithdrawForAdmin']['merchantWithdraw']
}) => {
  const trpcUtils = trpc.useUtils()

  const disputeCompleteMerchantWithdrawForAdmin = trpc.disputeCompleteMerchantWithdrawForAdmin.useMutation()
  const formyComplete = useFormy({
    initialValues: {
      merchantWithdrawId: merchantWithdraw.id,
    },
    validationSchema: zDisputeCompleteMerchantWithdrawForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { merchantWithdraw } = await disputeCompleteMerchantWithdrawForAdmin.mutateAsync(valuesInput)
      trpcUtils.getMerchantWithdrawForAdmin.setData(
        { merchantWithdrawSerialNumber: merchantWithdraw.serialNumber },
        { merchantWithdraw }
      )
    },
    successMessage: 'Спор успешно исполнен',
    resetOnSuccess: false,
  })

  const disputeCancelMerchantWithdrawForAdmin = trpc.disputeCancelMerchantWithdrawForAdmin.useMutation()
  const formyCancel = useFormy({
    initialValues: {
      merchantWithdrawId: merchantWithdraw.id,
    },
    validationSchema: zDisputeCancelMerchantWithdrawForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { merchantWithdraw } = await disputeCancelMerchantWithdrawForAdmin.mutateAsync(valuesInput)
      trpcUtils.getMerchantWithdrawForAdmin.setData(
        { merchantWithdrawSerialNumber: merchantWithdraw.serialNumber },
        { merchantWithdraw }
      )
    },
    successMessage: 'Спор успешно отменён',
    resetOnSuccess: false,
  })
  return (
    <FormItems>
      <FormItems as="form" {...formyComplete.formProps}>
        <Buttons>
          <Button {...formyComplete.buttonProps} type="submit">
            Исполнить спор, админ не прав, мерчант прав, все деньги будут возвращены назад
          </Button>
        </Buttons>
      </FormItems>
      <FormItems as="form" {...formyCancel.formProps}>
        <Buttons>
          <Button {...formyCancel.buttonProps} type="submit">
            Отменить спор, админ прав, мерчант не прав, сделка вернётся в статус завершённой
          </Button>
        </Buttons>
      </FormItems>
    </FormItems>
  )
}

const Complete = ({
  merchantWithdraw,
}: {
  merchantWithdraw: TrpcRouterOutput['getMerchantWithdrawForAdmin']['merchantWithdraw']
}) => {
  const completeMerchantWithdrawForAdmin = trpc.completeMerchantWithdrawForAdmin.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      merchantWithdrawId: merchantWithdraw.id,
    },
    validationSchema: zCompleteMerchantWithdrawForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { merchantWithdraw } = await completeMerchantWithdrawForAdmin.mutateAsync(valuesInput)
      trpcUtils.getMerchantWithdrawForAdmin.setData(
        { merchantWithdrawSerialNumber: merchantWithdraw.serialNumber },
        { merchantWithdraw }
      )
    },
    successMessage: 'Мерчантский депозит успешно завершён',
    resetOnSuccess: false,
  })
  if (!merchantWithdraw.merchantPaymentMethodData) {
    return <p>Нет платёжных методов, обратитесь в администрацию</p>
  }
  return (
    <FormItems as="form" {...formy.formProps}>
      <PaymentMethodCard paymentMethod={merchantWithdraw.merchantPaymentMethodData} />
      {merchantWithdraw.paymentMethodType === 'garantex' && (
        <Textfieldy label="Код гарантекс" {...formy.getFieldProps('garantexCode')} />
      )}
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Я оплатил
        </Button>
      </Buttons>
    </FormItems>
  )
}

const CancelProcessing = ({
  merchantWithdraw,
}: {
  merchantWithdraw: TrpcRouterOutput['getMerchantWithdrawForAdmin']['merchantWithdraw']
}) => {
  const cancelProcessingMerchantWithdrawForAdmin = trpc.cancelProcessingMerchantWithdrawForAdmin.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      merchantWithdrawId: merchantWithdraw.id,
      message: '',
    },
    validationSchema: zCancelProcessingMerchantWithdrawForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { merchantWithdraw } = await cancelProcessingMerchantWithdrawForAdmin.mutateAsync(valuesInput)
      trpcUtils.getMerchantWithdrawForAdmin.setData(
        { merchantWithdrawSerialNumber: merchantWithdraw.serialNumber },
        { merchantWithdraw }
      )
    },
    successMessage: 'Мерчантский виздрав успешно отменён',
    resetOnSuccess: false,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Textfieldy {...formy.getFieldProps('message')} label="Причина отмены" />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Отменить
        </Button>
      </Buttons>
    </FormItems>
  )
}

export const AdminMerchantWithdrawViewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(adminMerchantWithdrawViewRoute)
    const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
    const queryResult = trpc.getMerchantWithdrawForAdmin.useQuery(
      {
        merchantWithdrawSerialNumber: +routeParams.merchantWithdrawSerialNumber,
      },
      {
        refetchInterval,
      }
    )
    const isActive = queryResult.data?.merchantWithdraw.isActive
    useEffect(() => {
      if (isActive) {
        setRefetchInterval(3_000)
      } else {
        setRefetchInterval(false)
      }
    }, [isActive])
    return queryResult
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    merchantWithdraw: queryResult.data.merchantWithdraw,
  }),
})(({ merchantWithdraw }) => {
  const breadcrumbs = [
    { title: 'Мерчантские виздравы', href: routes.adminMerchantWithdrawListRoute.get() },
    { title: `Виздрав #${merchantWithdraw.serialNumber}` },
  ]

  return (
    <Block>
      <Breadcrumbs items={breadcrumbs} />
      <Segment title={`Мерчантский виздрав #${merchantWithdraw.serialNumber}`} size="m">
        <Block fcnw g={30}>
          <LabeledValues
            $style={{
              labelColor: 'var(--mantine-color-text)',
              hintColor: 'var(--mantine-color-text)',
            }}
            valuesEmptyPolicy="hide"
            items={[
              ['Статус', toHumanMerchantWithdrawStatus(merchantWithdraw.status)],
              ['Сумма в USDT', toMoney({ amount: merchantWithdraw.amountUsdt, currency: 'usdt' })],
              [
                'Платёжный метод',
                merchantWithdraw.paymentMethodType && toHumanPaymentMethodType(merchantWithdraw.paymentMethodType),
              ],
              [
                'Номер кошелька',
                merchantWithdraw.merchantPaymentMethodData?.group === 'crypto'
                  ? merchantWithdraw.merchantPaymentMethodData.wallet
                  : null,
              ],
              ['Код гарантекс', merchantWithdraw.garantexCode],
              ['Админ', merchantWithdraw.admin?.serialNumber && `#${merchantWithdraw.admin.serialNumber}`],
              ['Мерчант', `#${merchantWithdraw.merchant.serialNumber}`],
              ['Мерчант мембер', `#${merchantWithdraw.merchantMember.serialNumber}`],
              ['Создание', formatDate(merchantWithdraw.createdAt, 'dd.MM.yyyy HH:mm')],
              ['Взят в работу', merchantWithdraw.pickedAt && formatDate(merchantWithdraw.pickedAt, 'dd.MM.yyyy HH:mm')],
              [
                'Оплачено',
                merchantWithdraw.completedAt && formatDate(merchantWithdraw.completedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Отменено',
                merchantWithdraw.cancelledAt && formatDate(merchantWithdraw.cancelledAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Начало спора',
                merchantWithdraw.disputeStartedAt && formatDate(merchantWithdraw.disputeStartedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Завершение спора',
                merchantWithdraw.disputeCompletedAt &&
                  formatDate(merchantWithdraw.disputeCompletedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Отмена спора',
                merchantWithdraw.disputeCancelledAt &&
                  formatDate(merchantWithdraw.disputeCancelledAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Длительность в минутах',
                merchantWithdraw.finishedAt &&
                  differenceInMinutes(merchantWithdraw.finishedAt, merchantWithdraw.createdAt),
              ],
            ]}
          />
          {merchantWithdraw.status === 'picking' ? (
            <Segment
              title="Ваша задача"
              size="m"
              desc="Перейти на админский дашборд и взяться за этот мерчантский виздрав"
            />
          ) : merchantWithdraw.status === 'processing' ? (
            <>
              <Segment
                title="Ваша задача"
                size="m"
                desc="Сделать перевод по указанным реквизитам и нажать на кнопку «Я оплатил»"
              >
                <Complete merchantWithdraw={merchantWithdraw} />
              </Segment>
              <Segment title="Отмена сделки" size="m" desc="Вы можете отменить сделку на этом этапе">
                <CancelProcessing merchantWithdraw={merchantWithdraw} />
              </Segment>
            </>
          ) : merchantWithdraw.status === 'completed' ? (
            <Segment title="Сделка успешно завершена" size="m" />
          ) : merchantWithdraw.status === 'cancelled' ? (
            <Segment
              title="Сделка была отменена"
              size="m"
              desc={toHumanMerchantWithdrawCancellationReason(
                merchantWithdraw.cancellationCode,
                merchantWithdraw.cancellationMessage
              )}
            />
          ) : merchantWithdraw.status === 'disputing' ? (
            <Segment title="Разрешите спор" size="m" desc={merchantWithdraw.disputeStartMessage}>
              <DisputeDecision merchantWithdraw={merchantWithdraw} />
            </Segment>
          ) : merchantWithdraw.status === 'refunded' ? (
            <Segment title="Средства по сделке были возвращены" size="m" />
          ) : null}
          <Chat chatId={merchantWithdraw.chatId} viewerType="admin" />
          <Segment title="Связанные транзакции" size="m">
            <Txs viewerType="admin" dealId={merchantWithdraw.id} />
          </Segment>
          <Segment title="Связанные логи" size="m">
            <ActionLogs dealId={merchantWithdraw.id} />
          </Segment>
        </Block>
      </Segment>
    </Block>
  )
})
