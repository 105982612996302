import { pageData } from './pageData.js'
import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { ActionLogs } from '@/general/src/actionLog/components/ActionLogs/index.js'
import { Chat } from '@/general/src/chat/components/Chat/index.js'
import { zDisputeCancelCustomerWithdrawForAdminEndpointInput } from '@/general/src/customerWithdraw/routes/disputeCancelCustomerWithdrawForAdmin/input.js'
import { zDisputeCompleteCustomerWithdrawForAdminEndpointInput } from '@/general/src/customerWithdraw/routes/disputeCompleteCustomerWithdrawForAdmin/input.js'
import {
  toHumanCustomerWithdrawCancellationReason,
  toHumanCustomerWithdrawStatus,
} from '@/general/src/customerWithdraw/utils.shared.js'
import { integerWithDecimalsToAmountString, toMoney } from '@/general/src/other/money.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { Txs } from '@/general/src/tx/components/Txs/index.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminCustomerWithdrawViewRoute } from '@/webapp/src/lib/routes.js'
import * as routes from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, LabeledValues, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { differenceInMinutes, formatDate } from 'date-fns'
import { useEffect, useState } from 'react'

const DisputeDecision = ({
  customerWithdraw,
}: {
  customerWithdraw: TrpcRouterOutput['getCustomerWithdrawForAdmin']['customerWithdraw']
}) => {
  const trpcUtils = trpc.useUtils()

  const disputeCompleteCustomerWithdrawForAdmin = trpc.disputeCompleteCustomerWithdrawForAdmin.useMutation()
  const formyComplete = useFormy({
    initialValues: {
      customerWithdrawId: customerWithdraw.id,
    },
    validationSchema: zDisputeCompleteCustomerWithdrawForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { customerWithdraw } = await disputeCompleteCustomerWithdrawForAdmin.mutateAsync(valuesInput)
      trpcUtils.getCustomerWithdrawForAdmin.setData(
        { customerWithdrawSerialNumber: customerWithdraw.serialNumber },
        { customerWithdraw }
      )
    },
    successMessage: 'Спор успешно исполнен',
    resetOnSuccess: false,
  })

  const disputeCancelCustomerWithdrawForAdmin = trpc.disputeCancelCustomerWithdrawForAdmin.useMutation()
  const formyCancel = useFormy({
    initialValues: {
      customerWithdrawId: customerWithdraw.id,
    },
    validationSchema: zDisputeCancelCustomerWithdrawForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { customerWithdraw } = await disputeCancelCustomerWithdrawForAdmin.mutateAsync(valuesInput)
      trpcUtils.getCustomerWithdrawForAdmin.setData(
        { customerWithdrawSerialNumber: customerWithdraw.serialNumber },
        { customerWithdraw }
      )
    },
    successMessage: 'Спор успешно отменён',
    resetOnSuccess: false,
  })
  return (
    <FormItems>
      <FormItems as="form" {...formyComplete.formProps}>
        <Buttons>
          <Button {...formyComplete.buttonProps} type="submit">
            Исполнить спор, трейдер не прав, клиент прав, все деньги будут возвращены назад
          </Button>
        </Buttons>
      </FormItems>
      <FormItems as="form" {...formyCancel.formProps}>
        <Buttons>
          <Button {...formyCancel.buttonProps} type="submit">
            Отменить спор, трейдер прав, клиент не прав, сделка вернётся в статус завершённой
          </Button>
        </Buttons>
      </FormItems>
    </FormItems>
  )
}

export const AdminCustomerWithdrawViewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(adminCustomerWithdrawViewRoute)
    const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
    const queryResult = trpc.getCustomerWithdrawForAdmin.useQuery(
      {
        customerWithdrawSerialNumber: +routeParams.customerWithdrawSerialNumber,
      },
      {
        refetchInterval,
      }
    )
    const isActive = queryResult.data?.customerWithdraw.isActive
    useEffect(() => {
      if (isActive) {
        setRefetchInterval(3_000)
      } else {
        setRefetchInterval(false)
      }
    }, [isActive])
    return queryResult
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    customerWithdraw: queryResult.data.customerWithdraw,
  }),
})(({ customerWithdraw }) => {
  const breadcrumbs = [
    { title: 'Клиентские виздравы', href: routes.adminCustomerWithdrawListRoute.get() },
    { title: `Клиентский виздрав #${customerWithdraw.serialNumber}` },
  ]

  return (
    <Block>
      <Breadcrumbs items={breadcrumbs} rootRole="admin" />
      <Segment title={`Клиентский виздрав #${customerWithdraw.serialNumber}`} size="m">
        <Block fcnw g={30}>
          <LabeledValues
            valuesEmptyPolicy="hide"
            items={[
              ['Статус', toHumanCustomerWithdrawStatus(customerWithdraw.status)],
              ['Сумма', toMoney({ amount: customerWithdraw.dealAmount, currency: customerWithdraw.dealCurrency })],
              [
                'Платёжный метод',
                customerWithdraw.paymentMethodType && toHumanPaymentMethodType(customerWithdraw.paymentMethodType),
              ],
              ['Сумма в USDT', toMoney({ amount: customerWithdraw.amountUsdt, currency: 'usdt' })],
              [
                'Прибыль компании в USDT',
                !!customerWithdraw.companyProfitUsdt &&
                  toMoney({ amount: customerWithdraw.companyProfitUsdt, currency: 'usdt' }),
              ],
              [
                'Прибыль компании в %',
                !!customerWithdraw.companyProfitShare &&
                  integerWithDecimalsToAmountString({ amount: customerWithdraw.companyProfitShare }) + '%',
              ],
              ['Мерчант', customerWithdraw.merchant && `#${customerWithdraw.merchant.serialNumber}`],
              [
                'Комиссия мерчанта в USDT',
                !!customerWithdraw.merchantFeeUsdt &&
                  toMoney({ amount: customerWithdraw.merchantFeeUsdt, currency: 'usdt' }),
              ],
              [
                'Комиссия мерчанта в %',
                !!customerWithdraw.merchantFeeShare &&
                  integerWithDecimalsToAmountString({ amount: customerWithdraw.merchantFeeShare }) + '%',
              ],
              ['Трейдер', customerWithdraw.trader && `#${customerWithdraw.trader.serialNumber}`],
              [
                'Вознаграждение трейдера в USDT',
                !!customerWithdraw.traderInterestUsdt &&
                  toMoney({ amount: customerWithdraw.traderInterestUsdt, currency: 'usdt' }),
              ],
              [
                'Вознаграждение трейдера в %',
                !!customerWithdraw.traderInterestShare &&
                  integerWithDecimalsToAmountString({ amount: customerWithdraw.traderInterestShare }) + '%',
              ],
              ['Создание', formatDate(customerWithdraw.createdAt, 'dd.MM.yyyy HH:mm')],
              ['Взят в работу', customerWithdraw.pickedAt && formatDate(customerWithdraw.pickedAt, 'dd.MM.yyyy HH:mm')],
              [
                'Проверено',
                customerWithdraw.completedAt && formatDate(customerWithdraw.completedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Отменено',
                customerWithdraw.cancelledAt && formatDate(customerWithdraw.cancelledAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Начало спора',
                customerWithdraw.disputeStartedAt && formatDate(customerWithdraw.disputeStartedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Завершение спора',
                customerWithdraw.disputeCompletedAt &&
                  formatDate(customerWithdraw.disputeCompletedAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Отмена спора',
                customerWithdraw.disputeCancelledAt &&
                  formatDate(customerWithdraw.disputeCancelledAt, 'dd.MM.yyyy HH:mm'),
              ],
              [
                'Длительность в минутах',
                customerWithdraw.finishedAt &&
                  differenceInMinutes(customerWithdraw.finishedAt, customerWithdraw.createdAt),
              ],
            ]}
            $style={{
              labelColor: 'var(--mantine-color-text)',
              hintColor: 'var(--mantine-color-text)',
            }}
          />
          {customerWithdraw.status === 'completed' ? (
            <Segment title="Сделка успешно завершена" size="m" />
          ) : customerWithdraw.status === 'cancelled' ? (
            <Segment
              title="Сделка была отменена"
              size="m"
              desc={toHumanCustomerWithdrawCancellationReason(
                customerWithdraw.cancellationCode,
                customerWithdraw.cancellationMessage
              )}
            />
          ) : customerWithdraw.status === 'disputing' ? (
            <Segment title="Разрешите спор" size="m" desc={customerWithdraw.disputeStartMessage}>
              <DisputeDecision customerWithdraw={customerWithdraw} />
            </Segment>
          ) : customerWithdraw.status === 'refunded' ? (
            <Segment title="Средства по сделке были возвращены" size="m" />
          ) : null}
          <Chat chatId={customerWithdraw.chatId} viewerType="admin" />
          <Segment title="Связанные транзакции" size="m">
            <Txs viewerType="admin" dealId={customerWithdraw.id} />
          </Segment>
          <Segment title="Связанные логи" size="m">
            <ActionLogs dealId={customerWithdraw.id} />
          </Segment>
        </Block>
      </Segment>
    </Block>
  )
})
