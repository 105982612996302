import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { ApiDocsSection } from '@/general/src/api/docs/ApiDocsSection.js'

export const ApiDocsCreateCustomerDeposit = ({
  merchant,
}: {
  merchant?: TrpcRouterOutput['getMyMerchant']['merchant'] | null
}) => {
  return (
    <ApiDocsSection
      merchant={merchant}
      title="Создание клиентского депозита"
      desc="Клиентский депозит — способ для клиента мерчанта перевести деньги трейдеру платформы, чтобы эквивалентная сумма в USDT отобразилась на свободном балансе мерчанта. Отправьте запрос с вашего сервера. Из ответа вытащите dealUrl и перенаправьте туда своего пользователя. В случае успешного заверения сделки получите соответствующий вебхук."
      method="POST"
      path="/api/create-customer-deposit/v1"
      hashParams={[
        'body:email',
        'body:amount',
        'body:currency',
        'body:paymentMethodType',
        'body:successUrl',
        'body:failUrl',
      ]}
      bodyParams={[
        {
          key: 'idempotencyKey',
          title: 'idempotencyKey*',
          desc: 'Обязательное поле, если не задан extarnalId (для H2H) - ключ для дедубликации одинаковых запросов (если пользователь дважды нажмет Отправить). Как правило, ID платежа в вашей системе.',
          defaultValue: crypto.randomUUID(),
        },
        {
          key: 'email',
          title: 'email',
          desc: 'E-mail клиента мерчанта, ему туда будет отправлена ссылка на оплату, если вы передадите его',
        },
        {
          key: 'currency',
          title: 'currency',
          desc: 'Валюта, в которой мерчант хочет зачислить деньги в своей системе своему клиенту. Сейчас поддерживается только "rub"',
          defaultValue: 'rub',
        },
        {
          key: 'amount',
          title: 'amount',
          desc: 'Сумма в указанной валюте с указанием копеек (центов) без знака запятой. То есть 1000 рублей — 100000. 1000 рублей 50 копеек — 100050',
          defaultValue: '100000',
        },
        {
          key: 'paymentMethodType',
          title: 'paymentMethodType',
          desc: 'Способ оплаты, например "sberbankCard". См. метод /api/get-payment-methods/v1',
          defaultValue: 'sberbankCard',
        },
        {
          key: 'successUrl',
          title: 'successUrl',
          desc: 'URL, на который мы перенаправим клиента после успешной оплаты. В урле можно подставить строку "$customerDepositId", она будет заменена на id созданного клиенсткого депозита',
          defaultValue: 'https://yandex.ru?id=$customerDepositId',
        },
        {
          key: 'failUrl',
          title: 'failUrl',
          desc: 'URL, на который мы перенаправим клиента в случае провала или отмены. В урле можно подставить строку "$customerDepositId", она будет заменена на id созданного клиенсткого депозита',
          defaultValue: 'https://google.com?id=$customerDepositId',
        },
      ]}
      responseExample={{
        customerDeposit: {
          id: '01914b92-40fe-7990-956c-2dab326c7f6d',
          serialNumber: 5,
          createdAt: '2024-08-13T11:49:21.788Z',
          pickedAt: null,
          cancelledAt: null,
          declaredAt: null,
          completedAt: null,
          completedBy: null,
          disputeStartedAt: null,
          disputeCompletedAt: null,
          disputeCancelledAt: null,
          willBeCancelledBecauseNotPickedAt: '2024-08-13T12:09:21.790Z',
          willBeCancelledBecauseNotDeclaredAt: null,
          cancellationCode: null,
          cancellationMessage: null,
          disputeStartMessage: null,
          traderPaymentMethodProvider: null,
          paymentMethodGroup: 'card',
          paymentMethodType: 'sberbankCard',
          status: 'picking',
          successUrl: 'https://yandex.ru?id=01914b92-40fe-7990-956c-2dab326c7f6d',
          failUrl: 'https://google.com?id=01914b92-40fe-7990-956c-2dab326c7f6d',
          dealUrl:
            'https://example.com/customer/deposit/01914b92-40fe-7990-956c-2dab326c7f6d/bb6c0f85-d671-4e1d-bab6-467c4de6f3f8',
          amountUsdt: 1_079,
          dealCurrency: 'rub',
          dealCurrencyInOneUsdt: 9_262,
          dealAmount: 100_000,
          merchantCurrency: 'rub',
          merchantCurrencyInOneUsdt: 9_262,
          merchantAmount: 100_000,
          merchantFeeShare: 400,
          merchantFeeUsdt: 43,
          trader: null,
          isActive: true,
          finishedAt: null,
          chatId: '46b25e20-87a1-4c7f-881d-7c4e2e01da3b',
          demo: false,
        },
      }}
    />
  )
}
