import { zSignInAdminEndpointInput } from '@/general/src/auth/routes/signInAdmin/input.js'
import { zSignInAdminWithCodeEndpointInput } from '@/general/src/auth/routes/signInAdminWithCode/input.js'
import { zSignInMerchantMemberEndpointInput } from '@/general/src/auth/routes/signInMerchantMember/input.js'
import { zSignInMerchantMemberWithCodeEndpointInput } from '@/general/src/auth/routes/signInMerchantMemberWithCode/input.js'
import { zSignInTraderEndpointInput } from '@/general/src/auth/routes/signInTrader/input.js'
import { zSignInTraderWithCodeEndpointInput } from '@/general/src/auth/routes/signInTraderWithCode/input.js'
import { QRCodeImage } from '@/webapp/src/components/other/QRCode/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { adminDashboardRoute, merchantDashboardRoute, traderDashboardRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const SignInPageComponent = ({ viewerRole }: { viewerRole: 'admin' | 'trader' | 'merchantMember' }) => {
  const { signInTrpc, signInWithCodeTrpc, zSignInEndpointInput, zSignInWithCodeEndpointInput, dashboardRoute, title } =
    {
      admin: {
        signInTrpc: trpc.signInAdmin,
        signInWithCodeTrpc: trpc.signInAdminWithCode,
        zSignInEndpointInput: zSignInAdminEndpointInput,
        zSignInWithCodeEndpointInput: zSignInAdminWithCodeEndpointInput,
        dashboardRoute: adminDashboardRoute,
        title: 'Вход в админскую',
      },
      trader: {
        signInTrpc: trpc.signInTrader,
        signInWithCodeTrpc: trpc.signInTraderWithCode,
        zSignInEndpointInput: zSignInTraderEndpointInput,
        zSignInWithCodeEndpointInput: zSignInTraderWithCodeEndpointInput,
        dashboardRoute: traderDashboardRoute,
        title: 'Вход в трейдерскую',
      },
      merchantMember: {
        signInTrpc: trpc.signInMerchantMember,
        signInWithCodeTrpc: trpc.signInMerchantMemberWithCode,
        zSignInEndpointInput: zSignInMerchantMemberEndpointInput,
        zSignInWithCodeEndpointInput: zSignInMerchantMemberWithCodeEndpointInput,
        dashboardRoute: merchantDashboardRoute,
        title: 'Вход в мерчантскую',
      },
    }[viewerRole]
  const navigate = useNavigate()
  const signIn = signInTrpc.useMutation()
  const signInWithCode = signInWithCodeTrpc.useMutation()
  const trpcUtils = trpc.useUtils()
  const [step, setStep] = useState(0)
  const [otpUrl, setOtpUrl] = useState<string | null>(null)
  const formy0 = useFormy({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: zSignInEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const result0 = await signIn.mutateAsync(valuesInput)
      setOtpUrl(result0.otpUrl)
      setStep(1)
    },
  })
  const formy1 = useFormy({
    initialValues: {
      otpCode: '',
    },
    validationSchema: zSignInWithCodeEndpointInput.pick({
      otpCode: true,
    }),
    onSubmit: async ({ valuesInput }) => {
      await signInWithCode.mutateAsync({
        email: formy0.values.email,
        password: formy0.values.password,
        otpCode: valuesInput.otpCode,
      })
      await trpcUtils.invalidate()
      navigate(dashboardRoute.get())
    },
  })
  return (
    <Block fcnw>
      <Segment title={title} size="m">
        {step === 0 && (
          <FormItems as="form" {...formy0.formProps}>
            <Textfieldy label="Почта" {...formy0.getFieldProps('email')} />
            <Textfieldy label="Пароль" type="password" {...formy0.getFieldProps('password')} />
            <Buttons>
              <Button {...formy0.buttonProps} type="submit">
                Войти
              </Button>
            </Buttons>
          </FormItems>
        )}
        {step === 1 && (
          <FormItems as="form" {...formy1.formProps}>
            {otpUrl && (
              <>
                <QRCodeImage data={otpUrl} />
                <p>
                  Настройте Google Authenticator или аналогичное приложение, используя данный QR-код.
                  <br />
                  Затем введите полученный одноразовый пароль в поле ниже.
                </p>
              </>
            )}
            <Textfieldy label="Одноразовый пароль" {...formy1.getFieldProps('otpCode')} autoComplete="one-time-code" />
            <Buttons>
              <Button {...formy1.buttonProps} type="submit">
                Войти
              </Button>
            </Buttons>
          </FormItems>
        )}
      </Segment>
    </Block>
  )
}
