import { pageData } from './pageData.js'
import { zCreateMerchantDepositForMerchantMemberEndpointInput } from '@/general/src/merchantDeposit/routes/createMerchantDepositForMerchantMember/input.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { merchantMerchantDepositListRoute, merchantMerchantDepositViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useNavigate } from 'react-router-dom'

const breadcrumbs = [
  { title: 'Мерчантские депозиты', href: merchantMerchantDepositListRoute.get() },
  { title: 'Новый депозит' },
]

export const MerchantMerchantDepositNewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedMerchantMembersOnly: true,
})(() => {
  const navigate = useNavigate()
  const createMerchantDepositForMerchantMember = trpc.createMerchantDepositForMerchantMember.useMutation()
  const formy = useFormy({
    initialValues: {
      amountUsdt: '',
    },
    validationSchema: zCreateMerchantDepositForMerchantMemberEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { merchantDeposit } = await createMerchantDepositForMerchantMember.mutateAsync(valuesInput)
      navigate(merchantMerchantDepositViewRoute.get({ merchantDepositSerialNumber: merchantDeposit.serialNumber }))
    },
    successMessage: 'Мерчантский депозит создан',
    resetOnSuccess: false,
  })
  return (
    <Block>
      <Breadcrumbs items={breadcrumbs} rootRole="merchant" />
      <Segment title="Создание мерчантского депозита" size="m">
        <FormItems as="form" {...formy.formProps}>
          <Textfieldy label="Сумма в USDT" {...formy.getFieldProps('amountUsdt')} />
          <Buttons>
            <Button {...formy.buttonProps} type="submit">
              Создать мерчантский депозит
            </Button>
          </Buttons>
        </FormItems>
      </Segment>
    </Block>
  )
})
