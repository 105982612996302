import css from './index.module.css'
import { pageData } from './pageData.js'
import { toHumanTraderAutomatorLogStatus } from '@/general/src/automator/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { QRCodeImage } from '@/webapp/src/components/other/QRCode/index.js'
import { RichText } from '@/webapp/src/components/other/RichText/index.js'
import { useAppContext } from '@/webapp/src/lib/ctx.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import type { AutomatorAuthData } from '@/webapp/src/lib/rest/sendNotificationFromAutomator.js'
import { sendNotificationFromAutomator } from '@/webapp/src/lib/rest/sendNotificationFromAutomator.js'
import { traderCustomerDepositViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Link, Segment, Table } from '@/webapp/src/lib/uninty.components.js'
import { Button, Tooltip } from '@mantine/core'
import { formatDate } from 'date-fns/format'
import React, { useState } from 'react'
import { TbReload } from 'react-icons/tb'

const ResendNotificationButton = ({
  notificationData,
  authData,
  handleResend,
}: {
  notificationData: any
  authData: AutomatorAuthData
  handleResend: () => Promise<any>
}) => {
  const appCtx = useAppContext()
  const [loading, setLoading] = useState(false)

  const resendNotification = (record: any) => async () => {
    setLoading(true)

    try {
      await sendNotificationFromAutomator(appCtx, authData, record)
      await handleResend()
    } catch (error) {
      console.error('Notification resend failed:', error)
    }

    setLoading(false)
  }

  return (
    <Tooltip label="Отправить уведомление еще раз, без отправки перевода" position="right">
      <Button
        style={{ padding: 5 }}
        variant="filled"
        radius="xl"
        size="compact-md"
        onClick={resendNotification(notificationData)}
        disabled={loading}
      >
        <TbReload className={css.reloadIcon} size={20} stroke="2" />
      </Button>
    </Tooltip>
  )
}

const Auth = ({ automatorAuthData }: { automatorAuthData: any }) => {
  const { getS3UploadUrl } = useAppContext()

  if (automatorAuthData.error) {
    return <p>Ошибка: {automatorAuthData.error.message}</p>
  }
  if (!automatorAuthData.data) {
    return <p>Загрузка...</p>
  }
  const hostUrl = automatorAuthData.data.hostUrl
  const token = automatorAuthData.data.token
  const apkVersion = '1.0.8'
  const buildsNames = ['universal', 'x86', 'arm64-v8a', 'armeabi-v7a', 'x86_64']

  return (
    <RichText>
      <p>
        1. Скачайте наше приложение для автоматики на андроид по одной из ссылок в зависимости от процессора вашего
        устройства (или универсальный):
      </p>
      <ul>
        {buildsNames.map((buildName) => (
          <li key={buildName}>
            {buildName}:{' '}
            <a href={getS3UploadUrl(`phautomator.${buildName}.${apkVersion}.apk`)} target="_blank" rel="noreferrer">
              {getS3UploadUrl(`phautomator.${buildName}.${apkVersion}.apk`)}
            </a>
          </li>
        ))}
      </ul>
      <p>
        2.1. Авторизуйтесь по этому QR коду по кнопке внутри приложения:
        <br />
        <QRCodeImage
          data={JSON.stringify({
            hostUrl,
            token,
          })}
        />
      </p>
      <p>2.2. Или скопируйте вот этот код и вставьте в специальное поле в приложении:</p>
      <pre
        style={{
          wordWrap: 'break-word',
          width: '100%',
          wordBreak: 'break-all',
          display: 'block',
          overflowX: 'auto',
          padding: 10,
          background: '#f5f5f5',
          borderRadius: 5,
        }}
      >
        {JSON.stringify({ hostUrl, token }, null, 2)}
      </pre>
      <p>3. Внутри приложения выделите те платёжные методы, аккаунты которых установлены на этом телефоне.</p>
      <p>3. Используйте только проверенные приложения банков.</p>
    </RichText>
  )
}

const Logs = ({ automatorAuthData }: { automatorAuthData: any }) => {
  const automatorLogsResult = trpc.getTraderAutomatorLogsForTrader.useInfiniteQuery(
    {},
    {
      getNextPageParam: (lastPage) => {
        return lastPage.nextCursor
      },
      refetchOnMount: true,
    }
  )

  const traderAutomatorLogs = automatorLogsResult.data?.pages.flatMap((page) => page.traderAutomatorLogs) || []

  return (
    <>
      {automatorLogsResult.isLoading ? (
        <p>Загрузка...</p>
      ) : !traderAutomatorLogs.length ? (
        <p>Здесь пусто</p>
      ) : (
        <Table // TODO: use <TraderAutomatorLogsTable />
          className="unintyTable"
          records={traderAutomatorLogs}
          columns={[
            automatorAuthData.data && {
              heading: '',
              body: (record) => (
                <ResendNotificationButton
                  notificationData={record}
                  authData={automatorAuthData.data}
                  handleResend={automatorLogsResult.refetch}
                />
              ),
              width: 50,
            },
            {
              heading: 'SN',
              body: (record) => record.serialNumber,
              width: 100,
            },
            {
              heading: 'Дата',
              body: (record) => formatDate(record.createdAt, 'dd.MM.yyyy HH:mm'),
              width: 160,
            },
            {
              heading: 'Автоматор',
              body: (record) => (
                <span
                  style={{
                    wordWrap: 'break-word',
                  }}
                >
                  {record.appVersion} ({record.debug ? 'debug' : 'nondebug'})
                </span>
              ),
              width: 160,
            },
            {
              heading: 'Тип',
              body: (record) => (
                <span
                  style={{
                    wordWrap: 'break-word',
                  }}
                >
                  {record.sourceType}
                </span>
              ),
              width: 80,
            },
            { heading: 'Статус', body: (record) => toHumanTraderAutomatorLogStatus(record.status), width: 200 },
            {
              heading: 'Отправитель',
              body: (record) => (
                <span
                  style={{
                    wordWrap: 'break-word',
                  }}
                >
                  {record.sourceType === 'push' ? record.packageName : record.sender}
                </span>
              ),
              width: 200,
            },
            {
              heading: 'Заголовок',
              body: (record) => record.title || '—',
              width: 200,
            },
            {
              heading: 'Текст',
              body: (record) => record.text || '—',
              width: 300,
            },
            {
              heading: 'Сумма',
              body: (record) => toMoney({ amount: record.amount, currency: 'rub' }),
              width: 200,
            },
            {
              heading: 'Имя',
              body: (record) => record.name || '—',
              width: 200,
            },
            {
              heading: 'Клиентские депозиты',
              body: (record) => (
                <span
                  style={{
                    wordWrap: 'break-word',
                  }}
                >
                  {record.customerDeposits.length
                    ? record.customerDeposits.map((cd) => (
                        <React.Fragment key={cd.id}>
                          <Link
                            href={traderCustomerDepositViewRoute.get({ customerDepositSerialNumber: cd.serialNumber })}
                          >
                            {cd.serialNumber}
                          </Link>
                          <br />
                        </React.Fragment>
                      ))
                    : '—'}
                </span>
              ),
              width: 120,
            },
            {
              heading: 'Ошибка',
              body: (record) => record.error || '—',
              width: 200,
            },
          ]}
          loadMore={() => {
            if (!automatorLogsResult.isFetchingNextPage && automatorLogsResult.hasNextPage) {
              void automatorLogsResult.fetchNextPage()
            }
          }}
          hasMore={automatorLogsResult.hasNextPage}
        />
      )}
    </>
  )
}

export const TraderAutomatorPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedTradersOnly: true,
})(() => {
  const automatorAuthData = trpc.getAutomatorAuthDataForTrader.useQuery({})
  const breadcrumbs = [{ title: 'Автоматика' }]

  return (
    <Block>
      <Breadcrumbs items={breadcrumbs} rootRole="trader" />
      <Block fcnw g={40}>
        <Segment title="Подключение автоматики" size="m">
          <Auth automatorAuthData={automatorAuthData} />
        </Segment>
        <Segment title="Логи автоматики" size="m">
          <Logs automatorAuthData={automatorAuthData} />
        </Segment>
      </Block>
    </Block>
  )
})
