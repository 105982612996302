import css from './index.module.css'
import { pageData } from './pageData.js'
import type { AutomatorRule } from '@/backend/src/services/other/prisma.js'
import type { ClientTraderAutomatorLogForPublic } from '@/general/src/automator/utils.server.js'
import {
  defaultAutomatorRules,
  findAutomatorMatchingRules,
  getFullMatchingResult,
  type SettingMatchResult,
} from '@/general/src/automator/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import { UnreachableCodeError } from '@/general/src/other/type-helpers.js'
import type { PaymentMethodTypeExternalFiat, PaymentMethodTypeSbp } from '@/general/src/paymentMethod/utils.shared.js'
import { paymentMethodsProvidersSbp, toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Breadcrumbs } from '@/webapp/src/components/other/Breadcrumbs/index.js'
import { Selecty, Switchy, Textfieldy, useFormy, useFormyField } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { FormItems } from '@/webapp/src/lib/uninty.components.js'
import { Block, Segment } from '@/webapp/src/lib/uninty.components.js'
import { ActionIcon, Badge, Button, Checkbox, Divider, Tabs, TextInput } from '@mantine/core'
import { useDebounce } from '@uidotdev/usehooks'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md'
import { isTruthy, mapToObj, omit } from 'remeda'
import { isErrorLike, serializeError } from 'serialize-error'
import type { Formy } from 'svag-formy'
import type { SetOptional } from 'type-fest'
import type { z } from 'zod'

const breadcrumbs = [{ title: 'Мои настройки' }]

const banks = paymentMethodsProvidersSbp.map((provider) => ({
  value: provider,
  label: toHumanPaymentMethodType(`${provider}Sbp` as PaymentMethodTypeSbp).replace(/ СБП$/, ''),
}))

const rulesToFormRules = (rules: Array<SetOptional<AutomatorRule, 'paymentMethodProvider'>>) => {
  return mapToObj(rules, (rule) => [
    rule.id,
    {
      id: rule.id,
      type: rule.type,
      packageNames: rule.packageNames.join(', '),
      senderPattern: rule.senderPattern ?? null,
      checkPattern: rule.checkPattern ?? null,
      amountPattern: rule.amountPattern ?? '',
      isForSbp: rule.paymentMethodTypes.some((type) => type.endsWith('Sbp')),
      isForCard: rule.paymentMethodTypes.some((type) => type.endsWith('Card')),
      paymentMethodProvider: rule.paymentMethodProvider,
    },
  ])
}

type FormRules = ReturnType<typeof rulesToFormRules>

const formRulesToRules = (formRules: FormRules): Array<SetOptional<AutomatorRule, 'paymentMethodProvider'>> => {
  return Object.values(formRules).map((rule) => ({
    id: rule.id,
    type: rule.type,
    packageNames: rule.packageNames.split(',').map((name) => name.trim()),
    senderPattern: rule.senderPattern,
    checkPattern: rule.checkPattern,
    amountPattern: rule.amountPattern,
    paymentMethodTypes: [
      rule.isForSbp && (`${rule.paymentMethodProvider}Sbp` as PaymentMethodTypeExternalFiat),
      rule.isForCard && (`${rule.paymentMethodProvider}Card` as PaymentMethodTypeExternalFiat),
    ].filter(isTruthy),
    paymentMethodProvider: rule.paymentMethodProvider,
  }))
}

const ParsingResult = (props: { log: ClientTraderAutomatorLogForPublic; result: SettingMatchResult }) => {
  switch (props.result.status) {
    case 'matched': {
      const { result } = props

      return (
        <span>
          <Badge color="green">Найдено совпадение</Badge> с ровно одним правилом. Сумма:{' '}
          {toMoney({ amount: props.result.amount, currency: 'rub' })}, Банк:{' '}
          {banks.find((bank) => bank.value === result.rule.paymentMethodProvider)?.label ?? 'Неизвестно'}
        </span>
      )
    }

    case 'multipleMatches':
      return (
        <span>
          <Badge color="red">Найдено совпадение с несколькими правилами</Badge>, скорей всего это ошибка
        </span>
      )

    case 'sourceNotMatched': {
      const text = {
        push: `правил для приложения ${props.log.packageName}`,
        sms: `правил для отправителя смс ${props.log.sender}`,
      }[props.log.sourceType]

      return (
        <span>
          <Badge color="orange">Не найдено</Badge> {text}
        </span>
      )
    }

    case 'inputNotMatched':
      return (
        <span>
          <Badge color="orange">Не найдено</Badge> правил с совпадением по тексту
        </span>
      )

    case 'amountNotMatched':
      return (
        <span>
          <Badge color="yellow">Не удалось</Badge> спарсить сумму
        </span>
      )

    case 'invalidConfiguration': {
      const { error } = props.result
      return (
        <span>
          <Badge color="red">Невалидная конфигурация</Badge> {isErrorLike(error) ? serializeError(error).message : null}
        </span>
      )
    }

    case 'unknown':
      return <Badge color="red">Неизвестная ошибка парсинга</Badge>

    default:
      throw new UnreachableCodeError(props.result)
  }
}

const LogList = (props: {
  type: 'push' | 'sms'
  search: string
  searchOnlyMatched: boolean
  rules: Array<SetOptional<AutomatorRule, 'paymentMethodProvider'>>
}) => {
  const createAutomatorTestForAdmin = trpc.createAutomatorTestForAdmin.useMutation()
  const deleteAutomatorTestForAdmin = trpc.deleteAutomatorTestForAdmin.useMutation()
  const {
    data,
    isError,
    isFetched,
    refetch: refetchAutomatorLogs,
  } = trpc.getAutomatorLogsForAdmin.useQuery(
    {
      sourceType: props.type,
      limit: 30,
      search: props.search,
      ...(props.searchOnlyMatched && { statuses: ['success' as const] }),
    },
    {}
  )

  if (isError) {
    return <span>Ошибка</span>
  }

  if (!isFetched) {
    return <span>Загрузка...</span>
  }

  if (!data) {
    return null
  }

  const validatedLogs = data.traderAutomatorLogs.map((log) => {
    const result = getFullMatchingResult(
      findAutomatorMatchingRules({
        rules: props.rules
          .filter((rule): rule is AutomatorRule => !!rule.paymentMethodProvider)
          .filter((rule) => rule.type === props.type),
        type: props.type,
        ...log,
      })
    )

    return { log, result }
  })

  return (
    <div className={css.logs}>
      {validatedLogs.flatMap(({ log, result }) => {
        const onAddTest = async () => {
          try {
            await createAutomatorTestForAdmin.mutateAsync({ logId: log.id })
            await refetchAutomatorLogs()
          } catch (error) {
            toast.error((error as Error).message)
          }
        }

        const onRemoveTest = async () => {
          if (log.test) {
            try {
              await deleteAutomatorTestForAdmin.mutateAsync({ testId: log.test.id })
              await refetchAutomatorLogs()
            } catch (error) {
              toast.error((error as Error).message)
            }
          }
        }

        return [
          <div key={log.id} className={css.log}>
            {log.test ? (
              <ActionIcon variant="filled" color="red" className={css.testButton} onClick={onRemoveTest}>
                <FaMinus />
              </ActionIcon>
            ) : (
              <ActionIcon variant="filled" color="green" className={css.testButton} onClick={onAddTest}>
                <FaPlus />
              </ActionIcon>
            )}
            {log.sourceType === 'push' && <span>Код приложения: «{log.packageName}»</span>}
            {log.sourceType === 'sms' && <span>Отправитель: «{log.sender}»</span>}
            {log.sourceType === 'push' && <span>Заголовок: «{log.title}»</span>}
            <span>Текст: «{log.text}»</span>
            <span>
              Результат: <ParsingResult log={log} result={result} />
            </span>
          </div>,
          <Divider my="md" key={`devider-${log.id}`} />,
        ]
      })}
    </div>
  )
}

const Rules = <T extends z.ZodTypeAny | Record<string, any>>(props: {
  formy: Formy<T>
  rules: Array<SetOptional<AutomatorRule, 'paymentMethodProvider'>>
}) => {
  return (
    <>
      {props.rules.flatMap((rule) => {
        const removePushSetting = async () => {
          // eslint-disable-next-line no-alert
          if (window.confirm('Вы уверены?')) {
            await props.formy.setFieldValue('rules', omit(rulesToFormRules(props.rules), [rule.id]))
          }
        }

        return [
          <div className={css.formSection} key={rule.id}>
            <ActionIcon variant="filled" color="red" className={css.removeButton} onClick={removePushSetting}>
              <MdDeleteForever />
            </ActionIcon>
            {rule.type === 'push' && (
              <Textfieldy
                label="ID приложений"
                hint="Через запятую"
                formy={props.formy}
                name={`rules.${rule.id}.packageNames`}
              />
            )}
            {rule.type === 'sms' && (
              <Textfieldy
                label="Паттерн проверки отправителя"
                formy={props.formy}
                name={`rules.${rule.id}.senderPattern`}
              />
            )}
            <Textfieldy label="Паттерн поиска суммы" formy={props.formy} name={`rules.${rule.id}.amountPattern`} />
            <Textfieldy
              label="Паттерн проверки"
              hint="Если оставить пустым, то будет совпадать с паттерном поиска суммы"
              formy={props.formy}
              name={`rules.${rule.id}.checkPattern`}
            />
            <Selecty label="Банк" formy={props.formy} name={`rules.${rule.id}.paymentMethodProvider`} options={banks} />
            <Switchy
              formy={props.formy}
              name={`rules.${rule.id}.isForCard`}
              label="Для карт"
              optionLabel="Да"
              disabled={!rule.paymentMethodProvider}
            />
            <Switchy
              formy={props.formy}
              name={`rules.${rule.id}.isForSbp`}
              label="Для СБП"
              optionLabel="Да"
              disabled={!rule.paymentMethodProvider}
            />
          </div>,
          <Divider my="md" key={`devider-${rule.id}`} />,
        ]
      })}
    </>
  )
}

export const AdminAutomatorSettingsPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
  useQuery: () => {
    return trpc.getAutomatorRulesForAdmin.useQuery({})
  },
  setProps: ({ queryResult }) => ({
    rules: queryResult.data.rules,
  }),
})((props) => {
  const updateAutomatorRulesForAdmin = trpc.updateAutomatorRulesForAdmin.useMutation()
  const formy = useFormy({
    initialValues: {
      rules: rulesToFormRules(props.rules.length > 0 ? props.rules : defaultAutomatorRules),
    },
    onSubmit: async ({ valuesOutput }) => {
      await updateAutomatorRulesForAdmin.mutateAsync({
        rules: formRulesToRules(valuesOutput.rules).map((rule) => {
          if (!rule.paymentMethodProvider) {
            throw new Error('Необходимо указать банк')
          }
          return rule as AutomatorRule
        }),
      })
    },
    successMessage: 'Сохранено',
  })

  const { value: formRules } = useFormyField<FormRules>({ formy, name: 'rules' })
  const rules = formRulesToRules(formRules)

  const [searchText, setSearch] = useState('')
  const [searchOnlyMatched, setSearchOnlyMatched] = useState(false)
  const debouncedSearchText = useDebounce(searchText, 300)
  const logListProps = { rules, searchOnlyMatched, search: debouncedSearchText }

  const searchElement = (
    <div className={css.searchPanel}>
      <TextInput
        placeholder="Поиск…"
        className={css.search}
        value={searchText}
        onChange={(event) => setSearch(event.currentTarget.value)}
      />
      <Checkbox
        label="Только успешные"
        checked={searchOnlyMatched}
        onChange={(event) => setSearchOnlyMatched(event.currentTarget.checked)}
      />
    </div>
  )

  const addRule = (type: 'push' | 'sms') => {
    const id = crypto.randomUUID()
    const rule: FormRules[string] = {
      id,
      type,
      packageNames: '',
      checkPattern: '',
      amountPattern: '',
      senderPattern: '',
      isForCard: false,
      isForSbp: false,
      paymentMethodProvider: undefined,
    }

    void formy.setFieldValue('rules', { ...formRules, [id]: rule })
  }

  return (
    <Block fcnw>
      <Breadcrumbs items={breadcrumbs} />
      <Segment title="Настройки автоматики" size="m">
        <FormItems className={css.form} as="form" {...formy.formProps}>
          <Tabs defaultValue="push" color="blue">
            <Tabs.List>
              <Tabs.Tab value="push">Push</Tabs.Tab>
              <Tabs.Tab value="sms">Sms</Tabs.Tab>
              <Button type="submit" className={css.saveButton} disabled={formy.isSubmitting}>
                {formy.isSubmitting ? 'Сохраняем…' : 'Сохранить'}
              </Button>
            </Tabs.List>

            <Tabs.Panel value="push" className={css.container}>
              <div className={css.settings}>
                <Rules formy={formy} rules={rules.filter((rule) => rule.type === 'push')} />
                <div className={css.formSection}>
                  <Button className={css.addSettingButton} onClick={() => addRule('push')}>
                    Добавить настройку
                  </Button>
                </div>
              </div>
              <div className={css.preview}>
                {searchElement}
                <LogList {...logListProps} type="push" />
              </div>
            </Tabs.Panel>

            <Tabs.Panel value="sms" className={css.container}>
              <div className={css.settings}>
                <Rules formy={formy} rules={rules.filter((rule) => rule.type === 'sms')} />
                <div className={css.formSection}>
                  <Button className={css.addSettingButton} onClick={() => addRule('sms')}>
                    Добавить настройку
                  </Button>
                </div>
              </div>
              <div className={css.preview}>
                {searchElement}
                <LogList {...logListProps} type="sms" />
              </div>
            </Tabs.Panel>
          </Tabs>
        </FormItems>
      </Segment>
    </Block>
  )
})
